import types from './types';

const INITIAL_STATE = {
  showSpinner: false,
  showInviteSpinner: false,
  showEditDisableSpinner: false,
  showLogsSpinner: false,
  logs: [],
  logsMessage: "",
  snackbar: {
    variant: "",
    message: ""
  },
  userList: [],
}
const managementReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case types.REQUEST_CREATE_NEW_USER: {
      return {
        ...state,
        showSpinner: true,
        snackbar: {
          variant: "",
          message: ""
        }
      }
    }

    case types.RECEIVE_NEW_USER: {
      const { snackbar } = action;
      return {
        ...state,
        showSpinner: false,
        snackbar
      }
    }

    case types.REQUEST_USER_LIST: {
      return {
        ...state,
        showSpinner: true
      }
    }

    case types.RECEIVE_USER_LIST: {
      const { userList } = action;
      return {
        ...state,
        showSpinner: false,
        userList
      }
    }

    case types.REQUEST_SEND_INVITE: {
      return {
        ...state,
        showInviteSpinner: true,
        snackbar: {
          variant: "",
          message: ""
        }
      }
    }

    case types.INVITE_SENT: {
      const { snackbar } = action;
      return {
        ...state,
        showInviteSpinner: false,
        snackbar,
      }
    }

    case types.REQUEST_EDIT_DISABLE: {
      return {
        ...state,
        showEditDisableSpinner: true,
        snackbar: {
          variant: "",
          message: ""
        }
      }
    }

    case types.DISABLE_EDITED: {
      const { snackbar } = action;

      return {
        ...state,
        showEditDisableSpinner: false,
        snackbar
      }
    }

    case types.REQUEST_USER_LOGS: {
      return {
        ...state,
        showLogsSpinner: true,
        logs: [],
        logsMessage: ""
      }
    }

    case types.RECEIVE_USER_LOGS: {
      const { logs, message } = action;

      return {
        ...state,
        showLogsSpinner: false,
        logs,
        logsMessage: message
      }
    }

    default: return state;
  }
}

export default managementReducer;
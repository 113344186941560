import React, { useState, forwardRef, useRef } from 'react';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';

import {
  DialogTitle,
  DialogContent,
  Button,
  Typography,
  Checkbox,
  Link
} from '@material-ui/core';
import { ArrowDownward, Delete } from '@material-ui/icons';
import Dialog from '../../common/dialog/dialog';
import DeleteDialog from '../../common/dialog/deleteDialog';
import firebase from '../../../config/firebase';

const styles = theme => ({
  title: {
    color: theme.palette.secondary.main,
    fontSize: 20
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20
  }
});

const tableIcons = {
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />)
};
function Invites({
  classes,
  close,
  invites,
  invitesCount,
  getInvitedPatients,
  partnerList,
  role
}) {
  const tableRef = useRef(null);
  const [checked, setChecked] = useState([]);
  const [deleteInvite, setDelete] = useState('');
  const [loading, setLoading] = useState(false);

  const handleDelete = phone => {
    console.log('deleting invite ', phone);
    setDelete('');
    setLoading(true);
    const deletePhone = firebase
      .functions()
      .httpsCallable('dashboardFunctions-deleteInvite');
    deletePhone({
      phone
    })
      .then(result => {
        setTimeout(() => {
          console.log(result);
          setLoading(false);
          getInvitedPatients();
        }, 5000);
      })
      .catch(e => {
        console.log(e);
        setLoading(false);
        // TODO: run error snackbar to make it better
      });
  };

  const addPatients = () => {
    setLoading(true);
    const patients = invites.filter(i => checked.includes(i.phone));
    const invitePatient = firebase
      .functions()
      .httpsCallable('dashboardFunctions-invitePatient');
    invitePatient({
      partnerId: partnerList[0],
      patients
    })
      .then(() => {
        setTimeout(() => {
          setLoading(false);
          getInvitedPatients();
        }, 10000);
      })
      .catch(e => {
        setLoading(false);
        // TODO: run error snackbar to make it better
      });
  };

  const handleChecked = (event, phone) => {
    // Add or remove item from checked array
    if (checked.indexOf(phone) > -1) {
      setChecked(checked.filter(p => p !== phone));
    } else {
      setChecked([...checked, phone]);
    }
  };

  const checkAll = () => {
    setChecked(invites.map(u => u.phone));
  };

  const openDeleteDialog = () => {
    return (
      <Dialog close={() => setDelete('')}>
        <DeleteDialog
          close={() => setDelete('')}
          text={`Are you sure you want to remove the invite for ${deleteInvite}?`}
          deleteAction={() => handleDelete(deleteInvite)}
        />
      </Dialog>
    );
  };

  const createCSV = () => {
    const data = tableRef.current?.dataManager?.sortedData ?? invites;
    const csvRows = [];
    const csvRowsHeadersName = [];
    const dateObject = ['lastInviteDate']; // dates object. the date is in the value field
    /* Create headers object not affected by reference */
    const headers = ['mrn', 'phone', 'lastInviteDate', 'inviteCount'];
    const headersName = {
      mrn: 'Patient ID / MRN',
      phone: 'Mobile Phone #',
      lastInviteDate: 'Last Invite Date',
      inviteCount: '# invites'
    };
    // get the temp headers (not user friendly, these are the columns name of the db)
    csvRows.push(headers.join(','));

    /**
     * HEADERS
     * - Remove the phone column if role of the user is viewer
     */
    if (role === 'viewer' && data.indexOf('phone') > -1) {
      headers.splice(data.indexOf('phone'), 1);
    }

    //

    // loop over the rows
    data.forEach(row => {
      const values = headers.map(header => {
        let escaped;
        // We don't want to see null or undefined values in the csv
        if (row[header] === null || row[header] === undefined) {
          escaped = ''.replace(/"/g, '\\"');
          return `"${escaped}"`;
        }

        // (''+row[header]) converts everything into a string and allows us to use replace() also with no string values
        // Moreover, with this escaped solution all commas inside values are going to be safe and not create problems.
        escaped = `${row[header]}`.replace(/"/g, '\\"');
        if (dateObject.indexOf(header) > -1) {
          try {
            escaped = `${moment(row[header].value).format(
              'MMM DD, YYYY'
            )}`.replace(/"/g, '\\"');
          } catch (err) {
            console.error(
              `The value "${header}" of the user ${row.userId} has an error: ${err}`
            );
            escaped = ' '.replace(/"/g, '\\"');
          }
        }

        return `"${escaped}"`;
      });

      // form escaped comma separated values
      csvRows.push(values.join(','));
    });

    // Change the columns name with user friendly ones
    headers.forEach(header => {
      csvRowsHeadersName.push(headersName[header]);
    });
    csvRows[0] = csvRowsHeadersName.join(',');
    //-----------------------------------------------
    // Get the current timestamp that will be used as filename
    const date = new Date();

    const filename = `Ellipsis_NotRegistered_${moment(date).format(
      'YYYY-MM-DD'
    )}_.csv`;

    // Create a blob that is needed to download data
    const blob = new Blob([csvRows.join('\n')], { type: 'text/csv' }); // csvRows.join('\n') go to next line with every row
    // Send the blob to the browser
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', filename);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <div>
      <DialogTitle className={classes.title}>Re-Invite Patients</DialogTitle>
      <DialogContent>
        <Typography style={{ fontSize: 20 }} variant="body1">
          These are the
          {invitesCount === -1 ? (
            <span style={{ fontWeight: 700, fontSize: 20 }}>
              &nbsp;&nbsp;loading...&nbsp;&nbsp;
            </span>
          ) : (
            <span style={{ fontWeight: 700, fontSize: 20 }}>
              &nbsp;{`${invitesCount} patients`}&nbsp;
            </span>
          )}
          who have been previously invited to use the Rising Higher app, but who
          have not yet registered themselves. Select the patients whom you'd
          like to re-invite. The invitation will be a text message.
        </Typography>
        <div style={{ height: 10 }} />
        <div className={classes.row}>
          <div style={{ flex: 1 }} />
          <Button
            disabled={invitesCount === -1 || invitesCount === 0 || loading}
            onClick={checkAll}
            variant="contained"
            style={{ background: '#6D63A1', color: 'white' }}
          >
            Select All
          </Button>
        </div>
        <MaterialTable
          tableRef={tableRef}
          icons={tableIcons}
          isLoading={invitesCount === -1 || loading}
          columns={[
            { title: 'Patient ID/MRN', field: 'mrn' },
            { title: 'Mobile Phone #', field: 'phone' },
            {
              title: 'Last Invite Date',
              field: 'lastInviteDate.value',
              defaultSort: 'desc',
              render: rowData => (
                <Typography>
                  {rowData.lastInviteDate
                    ? moment(rowData.lastInviteDate.value).format(
                        'MMM DD, YYYY'
                      )
                    : 'Error'}
                </Typography>
              )
            },
            {
              title: '# Invites',
              field: 'inviteCount'
            },
            {
              title: 'Action',
              render: rowData => (
                <Delete
                  style={{ cursor: 'pointer' }}
                  onClick={() => setDelete(rowData.phone)}
                />
              )
            },
            {
              title: 'Select',
              render: rowData => (
                <Checkbox
                  onClick={event => handleChecked(event, rowData.phone)}
                  checked={checked.indexOf(rowData.phone) !== -1}
                  color="primary"
                />
              )
            }
          ]}
          data={invites}
          options={{
            sorting: true,
            search: false,
            showTitle: false,
            pageSize: 5,
            toolbar: false,
            maxBodyHeight: 350,
            tableLayout: 'auto',
            actionsColumnIndex: 4,
            headerStyle: { backgroundColor: '#d4d4d4' },
            cellStyle: { paddingTop: 2, paddingBottom: 2 }
          }}
        />

        <div style={{ height: 20 }} />

        <div className={classes.row}>
          <Link
            color="secondary"
            /* disabled={loading === true} */ onClick={close}
            style={{ cursor: 'pointer' }}
          >
            <Typography
              style={{ cursor: 'pointer', fontWeight: 700, fontSize: 20 }}
              variant="body1"
              color="secondary"
            >
              Close
            </Typography>
          </Link>
          <div style={{ flex: 1 }} />
          <Button
            disabled={invitesCount === -1 || invitesCount === 0}
            onClick={createCSV}
            variant="contained"
            style={{ background: '#6D63A1', color: 'white' }}
          >
            Export CSV
          </Button>
          <div style={{ width: 10 }} />
          <Button
            disabled={
              invitesCount === -1 || invitesCount === 0 || checked.length < 1
            }
            onClick={addPatients}
            variant="contained"
            style={{ background: '#AF46AE', color: 'white' }}
          >
            Re-Invite Selected Patient
          </Button>
        </div>
      </DialogContent>
      {deleteInvite ? openDeleteDialog() : ''}
    </div>
  );
}

Invites.propTypes = {
  classes: PropTypes.object,
  close: PropTypes.func,
  invites: PropTypes.array,
  invitesCount: PropTypes.number,
  getInvitedPatients: PropTypes.func,
  partnerList: PropTypes.array,
  role: PropTypes.string
};

export default withStyles(styles)(Invites);

import React, { useState, useEffect } from 'react';
import moment from 'moment';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import {
  Snackbar,
  SnackbarContent,
  withStyles,
  Typography
} from '@material-ui/core';
import TopMainPage from './components/TopMainPage';
import MiddleMainPage from './components/MiddleMainPage';

import FollowedBySelect from '../../common/form/FollowedBySelect';

const styles = theme => ({
  root: {
    padding: '10px 20px 20px 20px',
    [theme.breakpoints.down(1000)]: {
      padding: '5px 10px 10px 10px'
    }
  },
  control: {
    width: '100%',
    height: '40px',
    backgroundColor: '#D0D1C9',
    display: 'flex'
  },
  cont: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down(1000)]: {
      alignItems: 'center'
    }
  }
});

function PatientsComponent({
  classes,
  homeState,
  onboardState,
  portalConfig,
  loginState,
  history,
  getPatientsData,
  getSessionData,
  setFollowedby,
  setTotalPatients
}) {
  const { userPartnerList } = portalConfig;
  const [snackbar, setSnackbar] = useState(false);
  const [snackbarText, setSnackbarText] = useState('');
  const [partnerInfo, setPartner] = useState(null);

  const onloadPatients = data => {
    const { filters } = homeState;

    const partnerList = portalConfig.userPartnerList;

    getPatientsData(
      partnerList,
      data.sort,
      data.sortOrder,
      data.rowsPerPage,
      data.offset,
      [...filters, ...data.filters]
    );
  };

  useEffect(() => {
    if (queryString.parse(document.location.search).load === 'false') {
      window.history.replaceState(null, null, '?page=3');

      return;
    }
    const data = {
      sort: onboardState.sort,
      sortOrder: onboardState.sortOrder,
      rowsPerPage: onboardState.rowsPerPage,
      offset: onboardState.offset,
      filters: onboardState.filters
    };

    onloadPatients(data);
  }, []);

  const changeFollowedby = value => {
    /**
     * CHANGE OF THE FOLLOWEDBY CONTROL
     * When the followedBy field is changed, the followed action are performed here:
     * 1- SetFollowedBy method called to set the new email
     * 2- Load again data based on the new filter
     */
    const partnerList = portalConfig.userPartnerList;
    const ll = portalConfig.lastLogin || new Date().getTime();
    const lastLogin = `${moment.utc(ll, 'x').format('YYYY-MM-DDTHH:mm:ss')}`;
    let filters = [];
    if (value) {
      filters = [
        {
          field: 'assignedAdminId',
          operator: '=',
          value: `${value}`,
          type: 'String'
        }
      ];
    }
    setFollowedby(value);
    setTotalPatients();

    getPatientsData(
      partnerList,
      onboardState.sort,
      onboardState.sortOrder,
      onboardState.rowsPerPage,
      onboardState.offset,
      filters
    );
    getSessionData(
      lastLogin,
      partnerList,
      homeState.sort,
      homeState.sortOrder,
      homeState.rowsPerPage,
      homeState.offset,
      filters
    );
  };

  return (
    <>
      <div className={classes.control}>
        <Typography
          style={{
            margin: 'auto',
            marginLeft: 8,
            display: 'flex',
            color: '#000000'
          }}
        >
          Show patients followed by: &nbsp;
          <FollowedBySelect
            partner={portalConfig.userPartnerList[0]}
            user={loginState}
            followedby={
              homeState.filters.length > 0 ? homeState.filters[0].value : null
            }
            onChange={value => changeFollowedby(value)}
            loadingData={onboardState.showSpinner}
          />
        </Typography>
      </div>
      <div className={classes.root}>
        <div className={classes.cont}>
          <TopMainPage
            patientsCount={onboardState.totalPatients}
            showSpinner={onboardState.showSpinner}
            partnerList={userPartnerList}
            setPartnerInfo={setPartner}
            handleOpenSnackbar={text => {
              setSnackbar(true);
              setSnackbarText(text);
            }}
          />
          <MiddleMainPage
            onboardState={onboardState}
            portalConfig={portalConfig}
            loginState={loginState}
            onloadPatients={onloadPatients}
            partnerInfo={partnerInfo}
            handleOpenSnackbar={text => {
              setSnackbar(true);
              setSnackbarText(text);
            }}
            history={history}
          />
        </div>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={snackbar}
          autoHideDuration={4000}
          onClose={() => setSnackbar(false)}
        >
          <SnackbarContent
            style={{ backgroundColor: '#4287f5' }}
            message={snackbarText}
          />
        </Snackbar>
      </div>
    </>
  );
}

PatientsComponent.propTypes = {
  classes: PropTypes.object,
  homeState: PropTypes.object,
  loginState: PropTypes.object,
  onboardState: PropTypes.object,
  portalConfig: PropTypes.object,
  history: PropTypes.object,
  getPatientsData: PropTypes.func,
  getSessionData: PropTypes.func,
  setFollowedby: PropTypes.func,
  setTotalPatients: PropTypes.func
};

export default withStyles(styles)(PatientsComponent);

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Dialog } from '@material-ui/core';

const styles = theme => ({
  dialog: {
    overflow: 'hidden'
  }
});

class MaxWidthDialog extends React.Component {
  state = {
    open: true,
    fullWidth: true
    //maxWidth: 'sm',
  };

  handleClose = () => {
    this.props.close();
  };

  handleMaxWidthChange = event => {
    this.setState({ maxWidth: event.target.value });
  };

  handleFullWidthChange = event => {
    this.setState({ fullWidth: event.target.checked });
  };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Dialog
          className={classes.dialog}
          fullWidth={this.state.fullWidth}
          maxWidth={this.props.maxWidth} //this.state.maxWidth
          open={this.state.open}
          onClose={this.handleClose}
          scroll={'paper'}
        >
          <div>{this.props.children}</div>
        </Dialog>
      </React.Fragment>
    );
  }
}

MaxWidthDialog.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(MaxWidthDialog);

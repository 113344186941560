import React from 'react';
import { Redirect, Route } from 'react-router-dom';

const PrivateRoute = ({ component: Component, ...rest}, isLoggedIn) => (
    <Route
    {...rest}
    render={props =>
      rest.isLoggedIn ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/" }} />
      )
    }
  />
);

export default PrivateRoute;
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, Typography, Card, CardContent, CardHeader } from '@material-ui/core';

const styles = theme => ({
  cardContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    height: 40
  },
  cardTitle: {
    width: '40%',
    backgroundColor: theme.palette.grey[200],
    padding: 8
  },
  cardValue: {
    padding: 8,
    overflow: 'hidden',
    wordWrap: 'break-word'
  }
});

function DataCard({ classes, title, value }) {
  return (
    <Card classes={{ root: classes.cardContainer }}>
      <CardHeader
        title={title}
        titleTypographyProps={{ align: 'center', variant: 'body2' }}
        className={classes.cardTitle}
      />
      <CardContent className={classes.cardValue}>
        <Typography variant="subtitle1" align="center">
          {value}
        </Typography>
      </CardContent>
    </Card>
  );
}

DataCard.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.string,
  value: PropTypes.node
};

export default withStyles(styles)(DataCard);

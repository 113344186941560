import types from './types';

const INITIAL_STATE = {
  sort: { startTimeStamp: 'desc' },
  sortOrder: ['startTimeStamp'],
  rowsPerPage: 10,
  offset: 0,
  showSpinner: false,
  download: false,
  lastRefresh: new Date(),
  sessionData: [],
  filters: [],
  patientsCount: -1,
  showPSpinner: false,
  newSessionsNumber: -1,
  lastSession: -1,
  totalSessions: -1,
  sessionCount: 0
};
const homeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.REQUEST_SESSION_JSON: {
      return {
        ...state,
        showSpinner: true,
        sessionData: []
      };
    }

    case types.RECEIVE_SESSION_JSON: {
      const {
        sessionData,
        sessionCount,
        newSessionsNumber,
        sort,
        sortOrder,
        rowsPerPage,
        offset
      } = action;

      return {
        ...state,
        sort,
        sortOrder,
        rowsPerPage,
        offset,
        lastRefresh: new Date(),
        sessionData,
        /**
         * lastSession & totalSessions & newSessionsNumber
         * - Set values only after first login.
         * - If value is != -1, it has already been set.
         */
        lastSession:
          state.lastSession === -1 || sessionCount > state.totalSessions
            ? sessionData[0]
            : state.lastSession,
        totalSessions:
          state.totalSessions === -1 || sessionCount > state.totalSessions
            ? sessionCount
            : state.totalSessions,
        newSessionsNumber:
          state.newSessionsNumber === -1 ||
          newSessionsNumber > state.newSessionsNumber
            ? newSessionsNumber
            : state.newSessionsNumber,
        sessionCount,
        showSpinner: false
      };
    }

    case types.REQUEST_PATIENT_JSON: {
      return {
        ...state,
        showPSpinner: true
      };
    }

    case types.RECEIVE_PATIENT_JSON: {
      const { patientsCount } = action;

      return {
        ...state,
        patientsCount,
        showPSpinner: false
      };
    }

    case types.REQUEST_DOWNLOAD_CSV: {
      return {
        ...state,
        download: true
      };
    }

    case types.DOWNLOAD_CSV_COMPLETED: {
      return {
        ...state,
        download: false
      };
    }

    case types.SET_FOLLOWEDBY: {
      /**
       * Initial followedBy admin email set after a succesfull login.
       *
       * Every time this action gets called, the filter gets changed.
       */
      const { followedby } = action;
      let filters = [];
      if (followedby) {
        filters = [
          {
            field: 'assignedAdminId',
            operator: '=',
            value: `${followedby}`,
            type: 'String'
          }
        ];
      }

      return {
        ...state,
        newSessionsNumber: -1,
        lastSession: -1,
        totalSessions: -1,
        filters
      };
    }

    default:
      return state;
  }
};

export default homeReducer;

import React from 'react';

import PropTypes from 'prop-types';
import { TableCell, TableHead, TableRow, TableSortLabel, Tooltip, Checkbox } from '@material-ui/core';

class TableSorting extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { sort, rows, sortingDisabled, showSpinner } = this.props;

    /**
     * If the user has a viewer role, remove the restricted columns
     */
    const { configurations, role } = this.props.config;
    const { restricted_fields_viewer } = configurations.user_restriction;
    const filteredRows = [].concat(rows);

    if (role === 'viewer') {
      for (var i = filteredRows.length - 1; i >= 0; i--) {
        if (restricted_fields_viewer.indexOf(filteredRows[i].id) > -1) {
          filteredRows.splice(i, 1);
        }
      }
    }

    return (
      <TableHead style={{ backgroundColor: '#d4d4d4' }}>
        <TableRow>
          {this.props.checkbox ? (
            <TableCell padding="checkbox">
              <Checkbox
                indeterminate={
                  this.props.numSelected > 0 &&
                  this.props.numSelected < this.props.rowCount
                }
                checked={this.props.numSelected === this.props.rowCount}
                onChange={this.props.onSelectAllClick}
              />
            </TableCell>
          ) : (
            ''
          )}

          {filteredRows.map(
            row => (
              <TableCell
                key={row.id}
                align="left"
                padding={row.disablePadding ? 'none' : 'default'}
                sortDirection={
                  sort.hasOwnProperty(row.id) !== null ? sort[row.id] : false
                } // orderBy === row.id ? order : false
              >
                {sortingDisabled.indexOf(row.id) === -1 ? (
                  <Tooltip
                    title="Sort"
                    placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                    enterDelay={100}
                  >
                    <TableSortLabel
                      active={sort.hasOwnProperty(row.id)}
                      direction={sort[row.id]}
                      disabled={showSpinner}
                      onClick={this.createSortHandler(row.id)}
                    >
                      {/* {sortOrder.indexOf(row.id)} 
                       <CloseIcon style={{width: 10, height: 10}} /> */}
                      {row.label}
                    </TableSortLabel>
                  </Tooltip>
                ) : (
                  row.label
                )}
              </TableCell>
            ),
            this
          )}
        </TableRow>
      </TableHead>
    );
  }
}

TableSorting.propTypes = {
  onRequestSort: PropTypes.func.isRequired
};

export default TableSorting;

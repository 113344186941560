import React, { useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import {
  Typography,
  CircularProgress,
  TextField,
  Button
} from '@material-ui/core';
import firebase from '../../config/firebase';

import logo from '../../assets/logo.svg';

const styles = () => ({
  // theme
  root: {
    padding: '40px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
});

function NewPassword({ classes, props }) {
  const location = useLocation();
  const history = useHistory();
  const queryValues = queryString.parse(location.search);
  const [actionCode] = useState(
    queryValues.oobCode ? queryValues.oobCode : null
  );
  /**
   * The query params contains info used to redirect to different pages.
   * - option === alert --> the user is creating passowrd for the alert dashboard
   * - inClinicApp_Exit === true --> the user is creating passowrd for the in clinic app
   */
  const [option] = useState(queryValues.option ? queryValues.option : null);
  const [inClinicApp_Exit] = useState(
    queryValues.inclinicapp ? queryValues.inclinicapp : null
  );
  const [password, setPassword] = useState('');
  const [passwordCheck, setPasswordCheck] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const changePassword = () => {
    setLoading(true);
    firebase
      .auth()
      .confirmPasswordReset(actionCode, password)
      .then(() => {
        setLoading(false);

        if (option) {
          history.push('/__/auth/reset/setpasswordresponse?response=2');
        } else if (inClinicApp_Exit) {
          history.push('/__/auth/reset/setpasswordresponse?response=1');
        } else {
          history.push('/');
        }
      })
      .catch(e => {
        // Invalid or expired action code. Ask user to try to reset the password
        // again.
        console.log(
          'Invalid password or expired reset token. Ask user to try to reset the password again.',
          e
        );
        setLoading(false);

        if (option) {
          history.push('/__/auth/reset/setpasswordresponse?response=0');
        } else {
          setError(
            'Sorry, an error occured. Please, try again later or contact your Administrator.'
          );
        }
      });
  };

  const handleChangePassOne = event => {
    setPassword(event.target.value);
  };
  const handleChangePassTwo = event => {
    setPasswordCheck(event.target.value);
  };
  const handleSubmit = () => {
    const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\W]{8,}$/;
    if (re.test(password)) {
      setError('');
    } else {
      setError(
        'The password must be minimum eight characters, at least one uppercase letter, one lowercase letter and one number'
      );
      return;
    }

    if (password !== passwordCheck) {
      setError('Passwords do not match.');
      return;
    }

    changePassword();
  };

  if (actionCode === null) {
    return (
      <div className={classes.root}>
        <img alt="" src={logo} width="100" height="100" />
        <Typography
          style={{ marginTop: 10, fontSize: '20px' }}
          component="h2"
          variant="h2"
        >
          You are missing important parameters.
        </Typography>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <img alt="" src={logo} width="80" height="80" />
      <Typography
        style={{ marginTop: 5, fontSize: '15px' }}
        component="h2"
        variant="h2"
      >
        Set a new password for your account
      </Typography>

      <TextField
        style={{ marginTop: 30 }}
        label="New Password"
        value={password}
        onChange={handleChangePassOne}
        variant="outlined"
        type="password"
      />
      <TextField
        style={{ marginTop: 20 }}
        label="Repeat New Password"
        value={passwordCheck}
        onChange={handleChangePassTwo}
        variant="outlined"
        type="password"
      />

      {error ? (
        <Typography
          style={{ marginTop: 20, color: '#ff0000' }}
          component="body1"
          variant="body1"
        >
          {error}
        </Typography>
      ) : (
        ''
      )}

      {loading ? (
        ''
      ) : (
        <Button
          type="submit"
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          style={{ width: 200, marginTop: 20 }}
        >
          Set password
        </Button>
      )}

      {loading ? (
        <CircularProgress style={{ marginTop: 20 }} color="secondary" />
      ) : (
        ''
      )}
    </div>
  );
}

NewPassword.propTypes = {
  classes: PropTypes.object,
  props: PropTypes.object
};

export default withStyles(styles)(NewPassword);

import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import {
  DialogTitle,
  Button,
  Checkbox,
  DialogContent,
  CircularProgress,
  Typography,
  TextField,
  Link,
  FormControlLabel,
  Radio
} from '@material-ui/core';
import firebase from '../../../../config/firebase';

const styles = theme => ({
  title: {
    color: theme.palette.secondary.main
  },
  actions: {
    backgroundColor: '#ffffff',
    height: 50,
    position: 'sticky',
    width: '100%',
    bottom: 0,
    overflow: 'hidden'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  button: {
    textTransform: 'none'
  }
});

function InvitePatient({ classes, partners, close, handleOpenSnackbar }) {
  const [mrn, setmrn] = useState('');
  const [repeatmrn, setrepeatmrn] = useState('');
  const [phone, setphone] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [repeatphone, setrepeatphone] = useState('');
  const [partner, setPartner] = useState(partners[0]);

  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = React.useState(false);

  const handleChangePhone = event => {
    const re = /^\d{10}$/;
    if (re.test(String(event.target.value).toLowerCase())) {
      setphone(event.target.value);
      setPhoneError('');
      setSuccess('');
      setError('');
    } else {
      setphone(event.target.value);
      setPhoneError('The phone number must contain 10 digits. E.g, 9876543210');
    }

    if (event.target.value.length < 1) {
      setPhoneError('');
    }
  };

  const resetSuccess = () => {
    setSuccess('New patient invited correctly!');
    setLoading(false);
    setmrn('');
    setrepeatmrn('');
    setphone('');
    setPhoneError('');
    setrepeatphone('');
  };

  const addNewPatient = () => {
    setLoading(true);
    if (phone !== repeatphone || mrn !== repeatmrn) {
      setLoading(false);
      setError('The phone numbers or the medical record numbers do not match.');
      return;
    }

    const patients = [];
    patients.push({ phone: `+1${phone}`, mrn }); // Add the +1 in front of the phone number
    const invitePatient = firebase
      .functions()
      .httpsCallable('dashboardFunctions-invitePatient');
    invitePatient({
      partnerId: partner,
      patients
    })
      .then(
        /* result */ () => {
          // console.log(result);
          if (checked === true) {
            resetSuccess();
          } else {
            handleOpenSnackbar('The new patient has been succesfully invited!');
            close();
          }
        }
      )
      .catch(e => {
        console.log('An error occurred while adding a new patient: ', e);
        setLoading(false);
        setError(
          'Sorry, something went wrong. Please try again later or contact support@ellipsishealth.com'
        );
      });
  };

  return (
    <div>
      <DialogTitle className={classes.title}>Register New Patient</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          To register a new patient, please enter the following information
          about them and we will send them an invitation. After the patient
          registers and conducts a session, their assessment will be viewable in
          your Dashboard. All fields are required.
        </Typography>

        <div style={{ marginTop: 20 }} className={classes.row}>
          <Typography style={{ width: 170 }} color="inherit" variant="body1">
            Patient ID/MRN:
          </Typography>
          <div style={{ width: 5 }} />
          <TextField
            disabled={loading === true}
            value={mrn}
            onChange={event => {
              setmrn(event.target.value);
              setSuccess('');
              setError('');
            }}
            inputProps={{
              style: {
                padding: 8,
                width: 180
              }
            }}
            variant="outlined"
          />
        </div>
        <div style={{ marginTop: 5 }} className={classes.row}>
          <Typography style={{ width: 170 }} color="inherit" variant="body1">
            Re-Enter Patient ID/MRN:
          </Typography>
          <div style={{ width: 5 }} />
          <TextField
            disabled={loading === true}
            value={repeatmrn}
            onChange={event => setrepeatmrn(event.target.value)}
            inputProps={{
              style: {
                padding: 8,
                width: 180
              }
            }}
            variant="outlined"
          />
        </div>
        <div style={{ marginTop: 5 }} className={classes.row}>
          <Typography style={{ width: 170 }} color="inherit" variant="body1">
            Mobile #:
          </Typography>
          <div style={{ width: 5 }} />
          <TextField
            value="+1"
            disabled
            inputProps={{
              style: {
                padding: 8,
                width: 20
              }
            }}
            variant="outlined"
          />
          <div style={{ width: 2 }} />
          <TextField
            placeholder="E.g, 9876543210"
            disabled={loading === true}
            value={phone}
            onChange={handleChangePhone}
            error={phoneError.length > 0 || false}
            inputProps={{
              style: {
                padding: 8,
                width: 142
              }
            }}
            variant="outlined"
          />
        </div>
        <div style={{ marginTop: 5 }} className={classes.row}>
          <Typography style={{ width: 170 }} color="inherit" variant="body1">
            Re-Enter Mobile #:
          </Typography>
          <div style={{ width: 5 }} />
          <TextField
            value="+1"
            disabled
            inputProps={{
              style: {
                padding: 8,
                width: 20
              }
            }}
            variant="outlined"
          />
          <div style={{ width: 2 }} />
          <TextField
            placeholder="E.g, 9876543210"
            disabled={loading === true}
            value={repeatphone}
            onChange={event => setrepeatphone(event.target.value)}
            inputProps={{
              style: {
                padding: 8,
                width: 142
              }
            }}
            variant="outlined"
          />
        </div>
        <Typography style={{ color: '#ff0000', marginTop: 20 }} variant="body1">
          {phoneError}
        </Typography>
        <Typography style={{ color: '#ff0000', marginTop: 20 }} variant="body1">
          {error}
        </Typography>
        <Typography style={{ color: '#148316', marginTop: 20 }} variant="body1">
          {success}
        </Typography>

        {partners.length > 1
          ? partners.map(p => (
              // eslint-disable-next-line react/jsx-indent
              <FormControlLabel
                value={p}
                control={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Radio
                    checked={partner === p}
                    onChange={() => setPartner(p)}
                    label
                    value={p}
                    inputProps={{ 'aria-label': `${p}` }}
                  />
                }
                label={p}
              />
            ))
          : ''}

        <div style={{ height: 50 }} />
        <div className={classes.row}>
          <Link color="secondary" disabled={loading === true} onClick={close}>
            <Typography variant="body1" color="secondary">
              Cancel
            </Typography>
          </Link>
          <div style={{ flex: 1 }} />
          <FormControlLabel
            control={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <Checkbox
                disabled={loading === true}
                checked={checked}
                onChange={() => setChecked(!checked)}
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            }
            label="Add another"
          />
          {loading === false ? (
            <Button
              disabled={
                phoneError.length > 0 || mrn.length === 0 || phone.length === 0
              }
              variant="contained"
              color="primary"
              onClick={addNewPatient}
              className={classes.button}
            >
              Invite Patient
            </Button>
          ) : (
            <CircularProgress />
          )}
        </div>
      </DialogContent>
    </div>
  );
}

InvitePatient.propTypes = {
  classes: PropTypes.object,
  close: PropTypes.func,
  partners: PropTypes.array,
  handleOpenSnackbar: PropTypes.func
};

export default withStyles(styles)(InvitePatient);

import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  TextField,
  Paper,
  Collapse,
  MenuItem,
  Typography,
  FormLabel,
  FormControl,
  FormGroup,
  FormControlLabel,
  FormHelperText,
  Checkbox,
  LinearProgress
} from '@material-ui/core';

const styles = theme => ({
  newUserForm: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(3)
  },
  collapse: {
    width: '60%',
    [theme.breakpoints.down(600)]: {
      width: '90%'
    }
  },
  button: {
    marginTop: theme.spacing(3)
  },
  textField: {
    width: '60%'
  },
  phoneTextField: {
    width: '100%'
  },
  formControl: {
    margin: theme.spacing(3),
    alignItems: 'center',
    justifyContent: 'center'
  },
  progress: {
    margin: theme.spacing(2),
    bottom: 10,
    width: '90%'
  },
  paper: {
    marginTop: theme.spacing(3)
  }
});

class ClinicianForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formOpen: false,
      email: this.props.email,
      phone: this.props.phone,
      firstName: this.props.firstName,
      lastName: this.props.lastName,
      displayName: this.props.displayName,
      emailError: false,
      emailErrorMessage: '',
      phoneError: false,
      phoneErrorMessage: '',
      partnersErrorText: '',
      partnersError: false,
      role: this.props.role,
      partners: this.props.userPartnerList,
      partnersCount: Object.keys(this.props.userPartnerList).length,
      showSnackbar: false,
      edited: false,
      editedPartner: false
    };

    this.buttonClick = this.buttonClick.bind(this);
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
      edited: true
    });
  };

  handleChangeEmail = event => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(String(event.target.value).toLowerCase())) {
      this.setState({
        email: event.target.value,
        emailError: false,
        emailErrorMessage: '',
        edited: true
      });
    } else {
      this.setState({
        email: event.target.value,
        emailError: true,
        emailErrorMessage: 'Check the email format'
      });
    }
  };

  handleChangePhone = event => {
    const re = /^([0-9]{3})?([0-9]{3})?([0-9]{4})$/;
    if (re.test(String(event.target.value).toLowerCase())) {
      this.setState({
        phone: event.target.value,
        phoneError: false,
        phoneErrorMessage: '',
        edited: true
      });
    } else {
      this.setState({
        phone: event.target.value,
        phoneError: true,
        phoneErrorMessage: 'Check the phone format'
      });
    }

    if (event.target.value.length < 1) {
      this.setState({
        phoneError: false,
        phoneErrorMessage: ''
      });
    }
  };

  componentWillReceiveProps(newProps) {
    if (
      this.props.snackbar.variant === '' &&
      newProps.snackbar.variant !== ''
    ) {
      if (newProps.snackbar.variant === 'success') {
        this.setState(
          {
            email: ''
          },
          () => {
            // Call the method of the parent to close the create/edit form
            this.props.closeForm();
          }
        );
      }
    }

    if (this.props.open !== newProps.open) {
      /**
       * The first mount of the component has empty values. In order to re render the component,
       * the state has to change. The code updates the state when the open props changes.
       */
      this.setState({
        formOpen: !this.state.formOpen,
        email: newProps.email,
        phone: newProps.phone,
        firstName: newProps.firstName,
        lastName: newProps.lastName,
        displayName: newProps.displayName,
        role: newProps.role,
        partners: newProps.userPartnerList,
        partnersCount: Object.keys(newProps.userPartnerList).length,
        edited: false
      });
    } else if (this.props.email !== newProps.email) {
      /**
       * - The create/edit dialog is still open but the user decides to click on edit another user
       * - If the previous user has not been edited, refresh the component
       */
      this.setState({
        email: newProps.email,
        phone: newProps.phone,
        firstName: newProps.firstName,
        lastName: newProps.lastName,
        displayName: newProps.displayName,
        role: newProps.role,
        partners: newProps.userPartnerList,
        partnersCount: Object.keys(newProps.userPartnerList).length,
        edited: false,
        partnersError: false,
        phoneError: false,
        emailError: false
      });
    }
  }

  handleChangePartner = name => event => {
    var partners = this.state.partners;

    //Need the count of the positive partners into the object
    //The first value that was set initially in the state counts the total number of partners in the object.
    //The new count gets only the positive values from the partners object
    var partnersCount = Object.values(partners).filter(partner => partner)
      .length;

    if (!partners[name] === false) {
      partnersCount = partnersCount - 1;
      if (partnersCount < 1) {
        this.setState({
          partnersErrorText: 'Select at least one partner!',
          partnersError: true
        });
      } else {
        this.setState({
          partnersErrorText: '',
          partnersError: false,
          edited: true
        });
      }
    } else {
      this.setState({
        partnersErrorText: '',
        partnersError: false,
        edited: true
      });
      partnersCount = partnersCount + 1;
    }

    partners[name] = !partners[name];

    this.setState({
      partners,
      partnersCount,
      editedPartner: true
    });
  };

  buttonClick() {
    this.props.buttonClick(this.state);
  }

  render() {
    const { classes, userPartnerList, showSpinner, buttonTitle } = this.props;
    const {
      partners,
      partnersError,
      emailError,
      emailErrorMessage,
      phoneError,
      phoneErrorMessage,
      role,
      firstName,
      lastName,
      email,
      displayName,
      phone,
      edited,
      partnersErrorText
    } = this.state;

    return (
      <Collapse className={classes.collapse} in={this.state.formOpen}>
        <Paper className={classes.newUserForm}>
          <TextField
            label="First Name"
            value={firstName}
            onChange={this.handleChange('firstName')}
            className={classes.textField}
            margin="normal"
            variant="outlined"
          />
          <TextField
            label="Last Name"
            value={lastName}
            onChange={this.handleChange('lastName')}
            className={classes.textField}
            margin="normal"
            variant="outlined"
          />
          <TextField
            label="email"
            value={email}
            onChange={this.handleChangeEmail}
            className={classes.textField}
            margin="normal"
            variant="outlined"
            disabled={this.props.emailDisabled}
            error={emailError}
            helperText={emailErrorMessage}
          />
          <TextField
            label="Display Name"
            placeholder="display name (including credentials)"
            value={displayName}
            onChange={this.handleChange('displayName')}
            className={classes.textField}
            margin="normal"
            variant="outlined"
            // disabled={this.props.emailDisabled}
            // error={emailError}
            // helperText={emailErrorMessage}
          />

          <div style={{ width: '60%', display: 'flex', flexDirection: 'row' }}>
            <TextField
              style={{ width: 50 }}
              disabled
              label="+1"
              margin="normal"
              variant="outlined"
            />
            <TextField
              label="phone"
              value={phone}
              style={{ marginLeft: 5 }}
              onChange={this.handleChangePhone}
              className={classes.phoneTextField}
              margin="normal"
              variant="outlined"
              error={phoneError}
              helperText={phoneErrorMessage}
            />
          </div>

          <Typography
            style={{ marginTop: 5, fontSize: '12px', width: '60%' }}
            align="center"
            variant="body1"
          >
            A mobile phone number is required to authenticate the user during
            account creation.
          </Typography>

          <TextField
            select
            label="Select Role"
            className={classes.textField}
            value={role}
            onChange={this.handleChange('role')}
            SelectProps={{
              MenuProps: {
                className: classes.menu
              }
            }}
            helperText="Please select the new user's role"
            margin="normal"
            variant="outlined"
          >
            {role === 'super' ? (
              <MenuItem key="super" value="super">
                Super
              </MenuItem>
            ) : (
              ''
            )}
            <MenuItem key="admin" value="admin">
              Practice Manager
            </MenuItem>
            <MenuItem key="clinician" value="clinician">
              Clinician
            </MenuItem>
            <MenuItem key="viewer" value="viewer">
              Viewer
            </MenuItem>
          </TextField>

          <Typography
            style={{ marginTop: 5, fontSize: '12px', width: '60%' }}
            align="center"
            variant="body1"
          >
            Clinician is the most common role, and allows for monitoring, adding
            and removing of patients. The Practice Manager role includes all
            Clinician features plus adding and removing Clinicians, while Viewer
            role shows sessions without PHI.
          </Typography>

          <FormControl className={classes.formControl}>
            <FormLabel component="legend">Select partners</FormLabel>
            <FormGroup row>
              {Object.keys(userPartnerList).map(partner => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={partners[partner]}
                      onChange={this.handleChangePartner(partner)}
                      value={partner}
                    />
                  }
                  label={partner}
                />
              ))}
            </FormGroup>
            {!partnersError ? (
              <FormHelperText>Select partners for the user</FormHelperText>
            ) : (
              <FormHelperText style={{ color: '#FF0000' }}>
                {partnersErrorText}
              </FormHelperText>
            )}
          </FormControl>

          {showSpinner ? <LinearProgress className={classes.progress} /> : null}

          {!emailError &&
          !partnersError &&
          !showSpinner &&
          !phoneError &&
          email !== '' &&
          firstName !== '' &&
          lastName !== '' &&
          phone !== '' &&
          displayName !== '' &&
          edited === true ? (
            <Button
              style={{ margin: 10 }}
              variant="contained"
              color="secondary"
              onClick={this.buttonClick}
            >
              {buttonTitle}
            </Button>
          ) : (
            ''
          )}
        </Paper>
      </Collapse>
    );
  }
}

export default withStyles(styles)(ClinicianForm);

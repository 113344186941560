import React, { Component } from 'react';
import moment from 'moment';
import { LoadingContainer } from 'components';
import { callFunc } from 'utils/firebase';
import Snackbar from 'app/common/snackbar/snackbar';
import Filters from './components/ReportFilter';
import Summary from './components/ReportSummary';
import Table from './components/ReportTable';

class Reports extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      result: null,
      error: null,
      filters: {
        partner: '',
        startDate: moment()
          .subtract(1, 'week')
          .startOf('week')
          .toDate(),
        endDate: moment()
          .subtract(1, 'week')
          .endOf('week')
          .toDate()
      }
    };
  }

  handleChangeFilter = newFilters => {
    this.setState({
      filters: {
        ...newFilters
      }
    });
  };

  handleError = e => {
    this.setState({ error: e.message });
  };

  handleSubmit = async value => {
    try {
      this.setState({ loading: true });
      const resp = await callFunc('dashboardFunctions-bq_basicReport', {
        startDate: moment(value.startDate).format(),
        endDate: moment(value.endDate).format(),
        partner: value.partner
      });

      const result = JSON.parse(resp.data);
      this.setState({
        result,
        loading: false
      });
    } catch (e) {
      this.setState({
        error: e.message,
        loading: false
      });
    }
  };

  render() {
    const { filters, loading, result, error } = this.state;

    return (
      <div>
        <Filters
          value={filters}
          loading={loading}
          onChange={this.handleChangeFilter}
          onSubmit={this.handleSubmit}
          onError={this.handleError}
          result={result}
        />

        {error && <Snackbar open={!!error} variant="error" message={error} />}

        <LoadingContainer loading={loading}>
          {() =>
            result && (
              <>
                <Summary result={result} />
                <Table items={result.users} />
              </>
            )
          }
        </LoadingContainer>
      </div>
    );
  }
}

export default Reports;

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Card,
  CardActions,
  CardContent,
  Button,
  Typography,
  IconButton,
  CircularProgress
} from '@material-ui/core';
import Input from '@material-ui/icons/Input';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '../common/dialog/dialog';
import InvitesDialog from './components/invitesDialog';
import firebase from '../../config/firebase';

import FollowedBySelect from '../common/form/FollowedBySelect';

const styles = theme => ({
  root: {
    width: '100%'
  },
  control: {
    width: '100%',
    height: '40px',
    backgroundColor: '#D0D1C9',
    display: 'flex'
  },
  secondRoot: {
    padding: '40px',
    display: 'flex'
    // justifyContent: 'center',
    // alignItems: 'center',
  },
  leftPanel: {
    display: 'flex',
    width: '65%',
    height: 250,
    padding: '10px'
  },
  rightPanel: {
    display: 'flex',
    flexDirection: 'column',
    width: '35%',
    height: 550,
    padding: '10px'
  },
  card: {
    width: '100%',
    margin: 10
  },
  cardTwo: {
    margin: 10,
    flex: 1
  },
  cardContent: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '70%'
  },
  cardActions: {
    padding: 20,
    marginBottom: 10,
    display: 'flex',
    justifyContent: 'center',
    height: '30%'
  }
});

class HomeComponent extends React.Component {
  state = {
    open: false,
    invitesCount: -1,
    invites: []
  };

  componentDidMount() {
    sessionStorage.setItem('page', 'dashboard');
    this.prepareData();
    this.getInvitedPatients();
  }

  openDialog = () => {
    const { invites, invitesCount } = this.state;
    const { portalConfig } = this.props;

    return (
      <Dialog maxWidth="md" close={() => this.setState({ open: false })}>
        <InvitesDialog
          close={() => this.setState({ open: false })}
          invites={invites}
          invitesCount={invitesCount}
          getInvitedPatients={this.getInvitedPatients}
          partnerList={portalConfig.userPartnerList}
          role={portalConfig.role}
        />
      </Dialog>
    );
  };

  getInvitedPatients = () => {
    const { portalConfig } = this.props;
    this.setState({
      invitesCount: -1
    });

    const currentUser = JSON.parse(sessionStorage.getItem('currentUser')).email;

    const invitesList = firebase
      .functions()
      .httpsCallable('dashboardFunctions-bq_getInvitedPatients');
    invitesList({
      currentUser,
      partnerList: portalConfig.userPartnerList
    })
      .then(result => {
        this.setState({
          invitesCount: JSON.parse(result.data).invitesCount.count,
          invites: JSON.parse(result.data).invitesList
        });
      })
      .catch(e => {
        console.log(e);
      });
  };

  prepareData = () => {
    const { homeState, portalConfig } = this.props;
    const { filters } = homeState;

    const partnerList = portalConfig.userPartnerList;
    const ll = portalConfig.lastLogin || new Date().getTime();
    const lastLogin = `${moment.utc(ll, 'x').format('YYYY-MM-DDTHH:mm:ss')}`;

    this.loadData(lastLogin, partnerList, filters);
  };

  loadData = (lastLogin, partnerList, filters) => {
    const {
      homeState,
      onboardState,
      getSessionData,
      getPatientsData
    } = this.props;
    getPatientsData(
      partnerList,
      onboardState.sort,
      onboardState.sortOrder,
      onboardState.rowsPerPage,
      onboardState.offset,
      filters
    );
    getSessionData(
      lastLogin,
      partnerList,
      homeState.sort,
      homeState.sortOrder,
      homeState.rowsPerPage,
      homeState.offset,
      filters
    );
  };

  changeFollowedby = value => {
    /**
     * CHANGE OF THE FOLLOWEDBY CONTROL
     * When the followedBy field is changed, the followed action are performed here:
     * 1- SetFollowedBy method called to set the new email
     * 2- Load again data based on the new filter
     */
    const {
      getPatientsData,
      getSessionData,
      onboardState,
      homeState,
      portalConfig,
      setFollowedby,
      setTotalPatients
    } = this.props;

    const partnerList = portalConfig.userPartnerList;
    const ll = portalConfig.lastLogin || new Date().getTime();
    const lastLogin = `${moment.utc(ll, 'x').format('YYYY-MM-DDTHH:mm:ss')}`;
    let filters = [];
    if (value) {
      filters = [
        {
          field: 'assignedAdminId',
          operator: '=',
          value: `${value}`,
          type: 'String'
        }
      ];
    }
    setFollowedby(value);
    setTotalPatients();

    getPatientsData(
      partnerList,
      onboardState.sort,
      onboardState.sortOrder,
      onboardState.rowsPerPage,
      onboardState.offset,
      filters
    );
    getSessionData(
      lastLogin,
      partnerList,
      homeState.sort,
      homeState.sortOrder,
      homeState.rowsPerPage,
      homeState.offset,
      filters
    );
  };

  render() {
    const {
      classes,
      loginState,
      homeState,
      history,
      onboardState,
      portalConfig
    } = this.props;
    const { invitesCount, open } = this.state;

    return (
      <div className={classes.root}>
        <div className={classes.control}>
          <Typography
            style={{
              margin: 'auto',
              marginLeft: 8,
              display: 'flex',
              color: '#000000'
            }}
          >
            Show patients followed by: &nbsp;
            <FollowedBySelect
              partner={portalConfig.userPartnerList[0]}
              user={loginState}
              followedby={
                homeState.filters.length > 0 ? homeState.filters[0].value : null
              }
              onChange={value => this.changeFollowedby(value)}
              loadingData={onboardState.showSpinner && homeState.showSpinner}
            />
          </Typography>
        </div>
        <div className={classes.secondRoot}>
          <div className={classes.leftPanel}>
            <Card raised="true" className={classes.card}>
              <CardContent className={classes.cardContent}>
                {onboardState.showSpinner &&
                onboardState.totalPatients === -1 ? (
                  <CircularProgress />
                ) : (
                  <Typography
                    style={{ fontWeight: 600, fontSize: 80 }}
                    variant="h2"
                  >
                    {onboardState.totalPatients}
                  </Typography>
                )}

                <Typography
                  style={{ fontWeight: 600, fontSize: 25 }}
                  variant="body1"
                >
                  Total patients registered
                </Typography>
              </CardContent>
              <CardActions className={classes.cardActions}>
                <IconButton onClick={() => history.push('/onboard?page=3')}>
                  <Input style={{ color: '#000000', width: 30, height: 30 }} />
                  <Typography
                    style={{ fontSize: 20, color: '#000000', marginLeft: 10 }}
                    variant="body1"
                  >
                    Patients
                  </Typography>
                </IconButton>
              </CardActions>
            </Card>
            <Card className={classes.card}>
              <CardContent className={classes.cardContent}>
                {invitesCount === -1 ? (
                  <CircularProgress />
                ) : (
                  <Typography
                    style={{ fontWeight: 600, fontSize: 80, color: '#AF46AE' }}
                    variant="h2"
                  >
                    {invitesCount}
                  </Typography>
                )}
                <Typography
                  style={{ fontWeight: 600, fontSize: 25 }}
                  variant="body1"
                >
                  Pts not yet registered
                </Typography>
              </CardContent>
              <CardActions className={classes.cardActions}>
                <Button
                  disabled={invitesCount === -1 || invitesCount === 0}
                  onClick={() => this.setState({ open: true })}
                  variant="contained"
                  style={{
                    width: '60%',
                    background: '#AF46AE',
                    color: 'white'
                  }}
                >
                  Re-Invite Pts
                </Button>
              </CardActions>
            </Card>
          </div>
          <div className={classes.rightPanel}>
            <Card raised="true" className={classes.cardTwo}>
              <CardContent className={classes.cardContent}>
                {homeState.showSpinner && homeState.totalSessions === -1 ? (
                  <CircularProgress />
                ) : (
                  <Typography
                    style={{ fontWeight: 600, fontSize: 80 }}
                    variant="h2"
                  >
                    {homeState.totalSessions}
                  </Typography>
                )}
                <Typography
                  style={{ fontWeight: 600, fontSize: 25 }}
                  variant="body1"
                >
                  Total patient sessions
                </Typography>
              </CardContent>
              <CardActions className={classes.cardActions}>
                <IconButton
                  onClick={() => {
                    history.push({
                      pathname: '/sessions',
                      search: '?page=1&v=all',
                      state: { value: '0' }
                    });
                  }}
                >
                  <Input style={{ color: '#000000', width: 30, height: 30 }} />
                  <Typography
                    style={{ fontSize: 20, color: '#000000', marginLeft: 10 }}
                    variant="body1"
                  >
                    Sessions
                  </Typography>
                </IconButton>
              </CardActions>
            </Card>
            <Card raised="true" className={classes.cardTwo}>
              <CardContent className={classes.cardContent}>
                {homeState.showSpinner && homeState.newSessionsNumber === -1 ? (
                  <CircularProgress />
                ) : (
                  <Typography
                    style={{ fontWeight: 600, fontSize: 80, color: '#AF46AE' }}
                    variant="h2"
                  >
                    {homeState.newSessionsNumber}
                  </Typography>
                )}
                <Typography
                  style={{ fontWeight: 600, fontSize: 25 }}
                  variant="body1"
                >
                  New sessions
                </Typography>
              </CardContent>
              <CardActions className={classes.cardActions}>
                <IconButton
                  onClick={() => {
                    history.push({
                      pathname: '/sessions',
                      search: '?page=1&v=new',
                      state: { value: '1' }
                    });
                  }}
                >
                  <Input style={{ color: '#000000', width: 30, height: 30 }} />
                  <Typography
                    style={{ fontSize: 20, color: '#000000', marginLeft: 10 }}
                    variant="body1"
                  >
                    Sessions
                  </Typography>
                </IconButton>
              </CardActions>
            </Card>
          </div>
          {open === true ? this.openDialog() : ''}
        </div>
      </div>
    );
  }
}

HomeComponent.propTypes = {
  classes: PropTypes.object,
  homeState: PropTypes.object,
  loginState: PropTypes.object,
  onboardState: PropTypes.object,
  portalConfig: PropTypes.object,
  getSessionData: PropTypes.func,
  getPatientsData: PropTypes.func,
  history: PropTypes.object,
  setFollowedby: PropTypes.func,
  setTotalPatients: PropTypes.func
};

export default withStyles(styles)(HomeComponent);

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper
} from '@material-ui/core';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto'
  },
  table: {
    minWidth: 700
  }
});

function ReportTable({ classes, items }) {
  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>User ID</TableCell>
            <TableCell>Contact Email</TableCell>
            <TableCell>Contact Phone</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Last Name</TableCell>
            <TableCell align="right">Avg PHQ</TableCell>
            <TableCell align="right">Sessions</TableCell>
            <TableCell align="right">Completed Sessions</TableCell>
            <TableCell align="right">Late Sessions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map(row => (
            <TableRow key={row.userId}>
              <TableCell component="th" scope="row">
                {row.userId}
              </TableCell>
              <TableCell>{row.contactEmail}</TableCell>
              <TableCell>{row.contactPhone}</TableCell>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.lastName}</TableCell>
              <TableCell align="right">{row.avgPhq}</TableCell>
              <TableCell align="right">{row.sessionCount}</TableCell>
              <TableCell align="right">{row.completedSessionCount}</TableCell>
              <TableCell align="right">{row.delayedSessionCount}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}

ReportTable.propTypes = {
  classes: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired
};

export default withStyles(styles)(ReportTable);

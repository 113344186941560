import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core/styles';
// import the Roboto Font used by the material-ui library
import 'typeface-roboto';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import theme from './theme';

import PrivateRoute from '../utils/privateRoute';
import AdminRoute from '../utils/adminRoute';
// Pages and components ------------------------------
import Layout from './common/Layout';
import Home from './home/HomeContainer';
import Sessions from './sessions/SessionsContainer';
import Login from './auth/login/LoginContainer';
import Reset from './auth/reset/PswResetContainer';
import Redirect from './auth/Redirect';
import NewPassword from './auth/NewPassword';
import SetPasswordResponse from './auth/alertDashboard/SetPasswordResponse';
import Management from './management/ManagementContainer';
import Reports from './reports/ReportsComponent';
import Patient from './patient/PatientContainer';
import PatientsOnboarding from './onboard/patients/PatientsContainer';
import Disclaimer from './disclaimer/DisclaimerComponent';
// ---------------------------------------------------

class App extends Component {
  render() {
    const { role, isLoggedIn } = this.props;

    return (
      <MuiThemeProvider theme={theme}>
        <Router>
          <div>
            <Switch>
              <Layout>
                {/* Add all your remaining routes here */}
                <AdminRoute
                  path="/clinicians"
                  component={Management}
                  isLoggedIn={isLoggedIn}
                  role={role}
                />
                <PrivateRoute
                  path="/disclaimer"
                  component={Disclaimer}
                  isLoggedIn={isLoggedIn}
                />
                <PrivateRoute
                  exact
                  path="/sessions"
                  component={Sessions}
                  isLoggedIn={isLoggedIn}
                />
                <PrivateRoute
                  exact
                  path="/home"
                  component={Home}
                  isLoggedIn={isLoggedIn}
                />
                <PrivateRoute
                  exact
                  path="/reports"
                  component={Reports}
                  isLoggedIn={isLoggedIn}
                />
                <PrivateRoute
                  exact
                  path="/patient/:userId"
                  component={Patient}
                  isLoggedIn={isLoggedIn}
                />
                <PrivateRoute
                  exact
                  path="/onboard"
                  component={PatientsOnboarding}
                  isLoggedIn={isLoggedIn}
                />

                <Route exact path="/" component={Login} />

                <Route
                  path="/__/auth/reset/setpasswordresponse"
                  component={SetPasswordResponse}
                />

                <Route
                  exact
                  path="/__/auth/reset/setpassword"
                  component={NewPassword}
                />

                <Route
                  exact
                  path="/__/auth/reset/2factauth"
                  component={Reset}
                />

                <Route
                  exact
                  path="/__/auth/reset/redirect"
                  component={Redirect}
                />
              </Layout>
            </Switch>
          </div>
        </Router>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = state => {
  const { isLoggedIn } = state.login;
  const { role } = state.login.portalConfig;

  return {
    isLoggedIn,
    role
  };
};

export default connect(mapStateToProps, null)(App);

/* 
    This file contains string literals for your action types. 
    This provides an easy reference for the actions available. 
    These strings are exported as an object literal which can then be 
    imported into your reducers and action creators instead of hard-coding them. 
    Although maintaining a separate file containing just your action types is 
    optional, it is highly recommended.
*/

const REQUEST_USER_DETAILS = 'REQUEST_USER_DETAILS';
const RECEIVE_USER_DETAILS = 'RECEIVE_USER_DETAILS';

const REQUEST_SESSION_RECORDINGS = 'REQUEST_SESSION_RECORDINGS';
const RECEIVE_SESSION_RECORDINGS = 'RECEIVE_SESSION_RECORDINGS';

const REQUEST_SAVE_SESSION_INFO = 'REQUEST_SAVE_SESSION_INFO';
const SAVED_SESSION_INFO = 'SAVED_SESSION_INFO';
const UPDATE_USER_DETAILS = 'UPDATE_USER_DETAILS';

export default {
  REQUEST_USER_DETAILS,
  RECEIVE_USER_DETAILS,
  REQUEST_SESSION_RECORDINGS,
  RECEIVE_SESSION_RECORDINGS,
  REQUEST_SAVE_SESSION_INFO,
  SAVED_SESSION_INFO,
  UPDATE_USER_DETAILS
};

/*

This is where you define your action creators. 
All action creators must be functions that return an object 
with at least the type property. 
We do not define any async logic in this file.

*/

import types from './types.js';

const requestLogin = () => ({
  type: types.REQUEST_LOGIN
});

const receiveUserInfo = (user, portalConfig) => ({
  type: types.RECEIVE_USER_INFO,
  portalConfig,
  user
});

const requestRegistration = () => ({
  type: types.REQUEST_REGISTRATION
});

const registrationResult = snackbar => ({
  type: types.RECEIVE_REGISTRATION_RESULT,
  snackbar
});

const requestSaveConfiguration = () => ({
  type: types.REQUEST_SAVE_CONFIG
});

const saveConfigurationCompleted = (data, snackbar) => ({
  type: types.SAVE_CONFIG_COMPLETED,
  data,
  snackbar
});

const requestSaveFilters = () => ({
  type: types.REQUEST_SAVE_FILTERS
});

const saveFiltersCompleted = data => ({
  type: types.SAVE_FILTERS_COMPLETED,
  data
});

const setError = snackbar => ({
  type: types.ERROR,
  snackbar
});

const loginError = snackbar => ({
  type: types.ERROR,
  snackbar
});

const setLoginState = () => ({
  type: types.SET_LOGIN_STATE
});

const logout = () => ({
  type: types.LOGOUT
});

export {
  requestLogin,
  receiveUserInfo,
  requestSaveConfiguration,
  saveConfigurationCompleted,
  requestSaveFilters,
  saveFiltersCompleted,
  requestRegistration,
  registrationResult,
  setError,
  loginError,
  setLoginState,
  logout
};

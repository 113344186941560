import React from 'react';
import { Redirect, Route } from 'react-router-dom';

const AdminRoute = ({ component: Component, ...rest }, isLoggedIn, role) => (
  <Route
    {...rest}
    render={props =>
      rest.isLoggedIn && (rest.role === 'super' || rest.role === 'admin') ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: '/' }} />
      )
    }
  />
);

export default AdminRoute;

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Checkbox, FormControlLabel } from '@material-ui/core';

const styles = theme => ({
  captions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  ellipsisCaption: {
    color: theme.palette.text.main,
    marginRight: 40
  },
  expectedCaption: {
    color: theme.palette.text.main,
    marginRight: 20
  },
  checkbox: {
    marginRight: 20
  }
});

function Captions({ classes, expectedCaption, onShow, show }) {
  const checkbox = (
    <Checkbox
      color="primary"
      checked={show}
      onChange={e => onShow(e.target.checked)}
      value="show"
    />
  );

  return (
    <div className={classes.captions}>
      <img
        src={`${process.env.PUBLIC_URL}/score1.png`}
        width={30}
        height={30}
        alt="score1"
      />
      <Typography className={classes.ellipsisCaption} variant="subtitle1">
        Ellipsis Score & Confidence Interval
      </Typography>
      {expectedCaption && (
        <>
          <img
            src={`${process.env.PUBLIC_URL}/score2.png`}
            width={30}
            height={30}
            alt="score2"
          />
          <Typography className={classes.expectedCaption} variant="subtitle1">
            {expectedCaption}
          </Typography>
          <FormControlLabel
            className={classes.checkbox}
            control={checkbox}
            label="Show"
          />
        </>
      )}
    </div>
  );
}

Captions.propTypes = {
  classes: PropTypes.object,
  expectedCaption: PropTypes.string,
  show: PropTypes.bool,
  onShow: PropTypes.func
};

const enhance = withStyles(styles);

export default enhance(Captions);

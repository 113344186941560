import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  DialogTitle,
  Button,
  DialogActions,
  DialogContent
} from '@material-ui/core';
import Audio from '../../../common/audioComponent/audio';

const styles = theme => ({
  root: {
    /* width: '100%',
        position: 'relative',
        height: 700,
        overflowY: 'hidden' */
  },
  title: {
    textAlign: 'center'
  }
});

function PatientRecordings({ classes, close, recordings, session }) {
  const sessionRecordings = recordings.filter(recording => {
    return recording.sessionId === session;
  });

  return (
    <div className={classes.root}>
      <DialogTitle className={classes.title}>Recordings</DialogTitle>
      <DialogContent>
        <div style={{ textAlign: 'center' }}>
          {/*
           * Reverse order the recordings:
           * shallow copy of the array first, and then use map on the copy. Props in React should not be mutated.
           * Another solution would be to query them based on date. I went for the quick solution atm
           */}
          {sessionRecordings.map(recording =>
            recording.audioFile ? (
              <Audio
                key={`${recording.uid}`}
                style={{ width: '50' }}
                recording={recording}
              />
            ) : (
              ''
            )
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </div>
  );
}

PatientRecordings.propTypes = {
  classes: PropTypes.object,
  close: PropTypes.func,
  recordings: PropTypes.array,
  session: PropTypes.string
};

const enhance = withStyles(styles);

export default enhance(PatientRecordings);

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import PatientsTable from '../../../common/table/table';
import Dialog from '../../../common/dialog/dialog';
import DeleteDialog from '../../../common/dialog/deleteDialog';
import EditDialog from './editPatientDialog';
import firebase from '../../../../config/firebase';

import Search from '../../../common/search/Search';

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'left',
    flexDirection: 'column',
    paddingTop: 8,
    width: '100%',
    position: 'relative',
    height: 60,
    [theme.breakpoints.down(1000)]: {
      width: '100%',
      alignItems: 'center'
    }
  },
  cont_one: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  }
});

function MiddleMainPage(props) {
  const {
    classes,
    loginState,
    onboardState,
    portalConfig,
    onloadPatients,
    partnerInfo,
    handleOpenSnackbar,
    history
  } = props;
  const { configurations, role } = portalConfig;
  const config = { configurations, role };
  const [deleteP, setDeletePatient] = useState('');
  const [editP, setEditPatient] = useState('');
  const [loading, setLoading] = useState(false);

  const deletePatient = () => {
    setLoading(true);
    const deleteAction = firebase
      .functions()
      .httpsCallable('dashboardFunctions-deletePatient');
    deleteAction({
      id: deleteP.uid
    })
      .then(result => {
        console.log(result);
        // Remove patient from props array
        /* onboardState.patientsData.splice(
          onboardState.patientsData.findIndex(item => item.uid === deleteP.uid),
          1
        ); */
        setTimeout(() => {
          handleOpenSnackbar('Patient deleted correctly.');
          setLoading(false);
          setDeletePatient('');

          const data = {
            sort: onboardState.sort,
            sortOrder: onboardState.sortOrder,
            rowsPerPage: onboardState.rowsPerPage,
            offset: onboardState.offset,
            filters: onboardState.filters
          };
          onloadPatients(data);
        }, 10000);
      })
      .catch(e => {
        console.log(e);
        setDeletePatient('');
        setLoading(false);
        handleOpenSnackbar(
          'Sorry, an error occurred while deleting the patient. Try again later.'
        );
      });
  };

  const editPatient = async data => {
    setLoading(true);
    const updatePatient = firebase
      .functions()
      .httpsCallable('dashboardFunctions-updatePatient');
    setLoading(true);
    try {
      await updatePatient({
        id: editP.uid,
        data
      })
        .then(result => {
          // find index
          const objIndex = onboardState.patientsData.findIndex(
            obj => obj.uid === editP.uid
          );

          // Remove patient from props array
          onboardState.patientsData[objIndex].name = data.name;
          onboardState.patientsData[objIndex].lastName = data.lastName;
          onboardState.patientsData[objIndex].mrn = data.mrn;
          onboardState.patientsData[objIndex].assignedAdminId =
            data.assignedAdminId;

          setEditPatient('');
          handleOpenSnackbar('Patient edited correctly.');
          setLoading(false);
        })
        .catch(e => {
          console.log(e);
          setEditPatient('');
          setLoading(false);
          handleOpenSnackbar(
            'Sorry, an error occurred while editing the patient. Try again later.'
          );
        });
    } catch (e) {
      console.error(e);
      handleOpenSnackbar(
        'Sorry, an error occurred while editing the patient. Try again later.'
      );
    }

    setLoading(false);
  };

  const openDeleteDialog = () => {
    return (
      <Dialog close={() => setDeletePatient('')}>
        <DeleteDialog
          close={() => setDeletePatient('')}
          text={`Are you sure you want to delete this patient? ${deleteP.mrn}`}
          deleteAction={() => deletePatient()}
          loading={loading}
        />
      </Dialog>
    );
  };

  const openEditDialog = () => {
    return (
      <Dialog close={() => setEditPatient('')}>
        <EditDialog
          close={() => setEditPatient('')}
          info={editP}
          partnerInfo={partnerInfo}
          editPatient={editPatient}
          loading={loading}
        />
      </Dialog>
    );
  };

  const saveConfiguration = value => {
    const data = {
      sort: onboardState.sort,
      sortOrder: onboardState.sortOrder,
      rowsPerPage: onboardState.rowsPerPage,
      offset: onboardState.offset,
      filters: value.filters
    };

    onloadPatients(data);
  };

  return (
    <div className={classes.root}>
      <div style={{ width: '50%', border: '1px solid #D0D0D0', marginTop: 40 }}>
        <Search
          activeFilters={onboardState.filters}
          saveConfiguration={saveConfiguration}
          spinner={onboardState.showSpinner}
          setFilter={() => {}}
        />
      </div>
      <div className={classes.cont_one}>
        <PatientsTable
          config={config}
          lastLogin={new Date().getTime()}
          loadData={onloadPatients}
          sort={onboardState.sort}
          sortOrder={onboardState.sortOrder}
          rowsPerPage={onboardState.rowsPerPage}
          offset={onboardState.offset}
          filters={onboardState.filters}
          settings={{}} // settings
          data={onboardState.patientsData}
          dataCount={onboardState.patientsCount}
          showSpinner={onboardState.showSpinner}
          showColumns={[
            'name',
            'lastName',
            'mrn',
            'followedBy',
            'createdAt',
            'totalSessions',
            'patientsActions'
          ]}
          hideColumns={[]}
          columnsName={{
            name: 'First Name',
            lastName: 'Last Name',
            mrn: 'MRN',
            followedBy: 'Followed By',
            createdAt: 'Registration Date',
            totalSessions: 'Total Sessions',
            patientsActions: 'Actions'
          }}
          saveConfiguration={() => {}} // this.saveConfiguration
          saveConfigCompleted={loginState.saveConfigCompleted}
          deletePatient={value => setDeletePatient(value)} // deletePatient
          editPatient={value => setEditPatient(value)} // editPatient
          saveLogs={() => {}}
          downloadCsv={() => {}}
          download={false}
          history={history}
        />
      </div>
      {deleteP ? openDeleteDialog() : ''}
      {editP ? openEditDialog() : ''}
    </div>
  );
}

MiddleMainPage.propTypes = {
  classes: PropTypes.object,
  loginState: PropTypes.object,
  onboardState: PropTypes.object,
  portalConfig: PropTypes.object,
  history: PropTypes.object,
  onloadPatients: PropTypes.func,
  handleOpenSnackbar: PropTypes.func
};

export default withStyles(styles)(MiddleMainPage);

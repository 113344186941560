import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  TextField,
  Typography,
  Link,
  CircularProgress
} from '@material-ui/core';
import { saveAs } from 'file-saver';
import { pdf } from '@react-pdf/renderer';
import ExportIcon from '@material-ui/icons/UnarchiveOutlined';
import PrintIcon from '@material-ui/icons/Print';
import Dialog from '../../../common/dialog/dialog';
import AssessmentChart from '../../../common/chart/AssessmentChart';
import PatientRecordings from './patientRecordings';
import AssessmentDoc from '../../../common/reports/pdf/d&a_assessment';

const styles = theme => ({
  root: {
    // height: '300px',
    margin: 4,
    padding: 20,
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: '#b8b8b8',
    backgroundColor: '#f4f4f4'
  },
  header: {
    width: '100%',
    display: 'flex',
    flexOrientation: 'row'
  },
  actionDivCont: {
    display: 'flex',
    flexOrientation: 'row',
    flex: 1,
    justifyContent: 'flex-end'
  },
  actionDiv: {
    width: 35,
    height: 35,
    backgroundColor: theme.palette.button.main,
    margin: 2,
    borderRadius: '20%',
    textAlign: 'center'
  },
  actionPdfReadyDiv: {
    width: 35,
    height: 35,
    margin: 2,
    borderRadius: '20%',
    textAlign: 'center',
    backgroundColor: '#008000',
    '&:hover': {
      backgroundColor: '#008000',
      transition: 'none'
    }
  },
  rowDivs: {
    width: '100%',
    display: 'flex',
    flexOrientation: 'row',
    padding: 1,
    margin: 5
  },
  bold: {
    fontWeight: 'bold'
  }
});

function SessionBox({
  classes,
  patient,
  session,
  role,
  index,
  update,
  saving,
  loadRecordings,
  surveyNames,
  createCSV,
  recordings
}) {
  const [comment, setComment] = React.useState(session.comment);
  const [showDialog, setDialog] = React.useState(false);

  const exportFile = async file => {
    const surveyMap = [];
    const surveyListBetweenSessions = [];
    const dataXSession = [];
    const headersCSV1 = [
      'Patient Name',
      'MRN',
      'Date & Time',
      'EH Depression',
      'EH Anxiety',
      'PHQ-8',
      'GAD-7'
    ];
    const dataToExport = [];

    let blob = null;

    switch (file) {
      case 'CSV':
        dataXSession[0] = {
          name: `${patient.name} ${patient.lastName}`,
          mrn: patient.mrn,
          date_time: session.startTimeStamp
            ? `${moment(session.startTimeStamp.value).format(
                'YYYY-MM-DD_HH:mm:ss'
              )}`
            : '',
          EH_Depression: session.phq_r
            ? session.phq_r.toFixed(0)
            : 'INCOMPLETE',
          EH_Anxiety: session.gad_r ? session.gad_r.toFixed(0) : 'INCOMPLETE',
          PHQ:
            session.phqStatus === 'COMPLETE'
              ? session.phqTotal
              : session.phqStatus,
          GAD:
            session.gadStatus === 'COMPLETE'
              ? session.gadTotal
              : session.gadStatus,
          surveysResults: []
        };

        session.custom.forEach(item => {
          if (!surveyMap[item.survey]) {
            surveyMap[item.survey] = true;
            surveyListBetweenSessions.push(item.survey);
          }

          dataXSession[0].surveysResults[item.survey] = item.score
            ? item.score
            : 'INCOMPLETE';
        });

        session.customStatus.forEach(value => {
          if (!surveyMap[value.survey]) {
            surveyMap[value.survey] = true;
            surveyListBetweenSessions.push(value.survey);
          }

          if (
            dataXSession[0].surveysResults[value.survey] &&
            value.status !== 'COMPLETE'
          ) {
            dataXSession[0].surveysResults[value.survey] = value.status;
          } else if (!dataXSession[0].surveysResults[value.survey]) {
            dataXSession[0].surveysResults[value.survey] = 'INCOMPLETE';
          }
        });

        dataXSession.forEach((d, i) => {
          dataToExport[0] = [
            `"${d.name}"`,
            `"${d.mrn}"`,
            `"${d.date_time}"`,
            `"${d.EH_Depression}"`,
            `"${d.EH_Anxiety}"`,
            `"${d.PHQ}"`,
            `"${d.GAD}"`
          ];

          surveyListBetweenSessions.forEach(s => {
            if (d.surveysResults[s]) {
              dataToExport[0].push(`"${d.surveysResults[s]}"`);
            } else {
              dataToExport[0].push(`""`);
            }
          });
        });

        surveyListBetweenSessions.forEach((s, i) => {
          if (surveyNames[s]) {
            surveyListBetweenSessions[i] = surveyNames[s];
          }
        });

        createCSV(
          dataToExport.reverse(),
          headersCSV1.concat(surveyListBetweenSessions),
          `Ellipsis_${patient.name}${patient.lastName}_${moment(
            new Date()
          ).format('YYYY-MM-DD')}.csv`
        );

        break;
      case 'PDF':
        blob = await pdf(
          <AssessmentDoc
            data={[session]}
            selected={[session].map(n => n.uid)}
            user={patient}
          />
        ).toBlob();
        saveAs(
          blob,
          `Ellipsis_${patient.name}${patient.lastName}_${moment(
            new Date()
          ).format('YYYY-MM-DD')}.pdf`
        );
        break;
      case '':
        break;
      default:
      // code block
    }
  };

  const handleChange = event => {
    const { value } = event.target;
    setComment(value);
    update({ comment: value, session });
  };

  const openDialog = () => {
    return (
      <Dialog close={() => setDialog(false)}>
        <PatientRecordings
          close={() => setDialog(false)}
          recordings={recordings}
          session={session.uid}
        />
      </Dialog>
    );
  };

  let hDuration = Math.floor(session.audioDuration / 60 / 60);
  let mDuration = Math.floor(session.audioDuration / 60) - hDuration;
  let sDuration = session.audioDuration % 60;
  if (hDuration < 10) {
    hDuration = `0${hDuration}`;
  }
  if (mDuration < 10) {
    mDuration = `0${mDuration}`;
  }
  if (sDuration < 10) {
    sDuration = `0${sDuration}`;
  }

  const duration = `${hDuration}:${mDuration}:${sDuration}`;

  return (
    <div id={`session-${session.uid}`} className={classes.root}>
      <div className={classes.header}>
        <div style={{ flex: 1 }}>
          <Typography
            style={{ fontWeight: 600 }}
            color="#262626"
            variant="subtitle1"
          >
            {`Session #${index}`}
          </Typography>
        </div>
        {role !== 'viewer' ? (
          <div className={classes.actionDivCont}>
            <div className={classes.actionDiv}>
              <div style={{ width: '100%', height: '100%', padding: 4 }}>
                <PrintIcon
                  style={{
                    color: '#f4f4f4',
                    width: '100%',
                    height: '100%'
                  }}
                  onClick={() => {
                    exportFile('PDF');
                  }}
                />
              </div>
            </div>

            <div className={classes.actionDiv}>
              <div style={{ width: '100%', height: '100%', padding: 4 }}>
                <ExportIcon
                  style={{
                    color: '#f4f4f4',
                    width: '100%',
                    height: '100%'
                  }}
                  onClick={() => {
                    exportFile('CSV');
                  }}
                />
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>

      <div className={classes.rowDivs}>
        <div style={{ marginRight: 40 }}>
          <Typography
            color="#262626"
            variant="subtitle1"
            className={classes.bold}
          >
            Depression Score:
          </Typography>
          <Typography color="#262626" variant="subtitle1">
            <span className={classes.bold}>
              {!session.phq_r && session.phq_r !== 0
                ? 'Incomplete'
                : session.phq_r.toFixed(0)}
              &nbsp;
            </span>
          </Typography>
          <AssessmentChart
            showRight={false}
            colWidth={20}
            height={250}
            data={[
              {},
              {
                id: session.uid,
                value: session.phq_r,
                expected:
                  session.phqTotal && session.phqStatus === 'COMPLETE'
                    ? session.phqTotal
                    : null,
                confidence: 4 // @TODO remove
              },
              {}
            ]}
            expand24={false}
            stops={[0, 9, 18, 24]}
          />
        </div>
        <div style={{ marginRight: 40 }}>
          <Typography
            color="#262626"
            variant="subtitle1"
            className={classes.bold}
          >
            Anxiety Score:
          </Typography>
          <Typography color="#262626" variant="subtitle1">
            <span className={classes.bold}>
              {!session.gad_r && session.gad_r !== 0
                ? 'Incomplete'
                : session.gad_r.toFixed(0)}
              &nbsp;
            </span>
          </Typography>
          <div style={{ paddingTop: 250 / 8 }}>
            <AssessmentChart
              showRight={false}
              colWidth={20}
              height={(250 * 7) / 8}
              data={[
                {},
                {
                  id: session.uid,
                  value: session.gad_r,
                  expected:
                    session.gadTotal && session.gadStatus === 'COMPLETE'
                      ? session.gadTotal
                      : null,
                  confidence: 4 // @TODO remove
                },
                {}
              ]}
              expand24={false}
              stops={[0, 9, 15, 21]}
            />
          </div>
        </div>
        <div style={{ flex: 1 }}>
          <div
            style={{
              width: '100%',
              border: '1px solid #b8b8b8',
              display: 'grid',
              gridTemplateColumns: 'repeat(2, 1fr)',
              gridTemplateRows: 'repeat(auto-fill, 1fr)'
            }}
          >
            {session.phqStatus !== 'OFF' ? (
              <div className={classes.rowDivs}>
                <div style={{ width: 150, marginRight: 20 }}>
                  <Typography color="#262626" variant="body1">
                    PHQ-8:
                  </Typography>
                </div>
                <div style={{ flex: 1, display: 'flex' }}>
                  <Typography
                    style={{ fontWeight: 500 }}
                    color="#262626"
                    variant="subtitle1"
                  >
                    {session.phqStatus === 'COMPLETE'
                      ? `${session.phqTotal}`
                      : 'Incomplete'}
                  </Typography>
                </div>
              </div>
            ) : (
              ''
            )}

            {session.gadStatus !== 'OFF' ? (
              <div className={classes.rowDivs}>
                <div style={{ width: 150, marginRight: 20 }}>
                  <Typography color="#262626" variant="body1">
                    GAD-7:
                  </Typography>
                </div>
                <div style={{ flex: 1, display: 'flex' }}>
                  <Typography
                    style={{ fontWeight: 500 }}
                    color="#262626"
                    variant="subtitle1"
                  >
                    {session.gadStatus === 'COMPLETE'
                      ? `${session.gadTotal}`
                      : 'Incomplete'}
                  </Typography>
                </div>
              </div>
            ) : (
              ''
            )}

            {session.customStatus.map(s => (
              <div className={classes.rowDivs}>
                <div style={{ width: 150, marginRight: 20 }}>
                  <Typography color="#262626" variant="body1">
                    {surveyNames[s.survey]
                      ? `${surveyNames[s.survey]}:`
                      : `${s.survey}:`}
                  </Typography>
                </div>
                <div style={{ flex: 1, display: 'flex' }}>
                  <Typography
                    style={{ fontWeight: 500 }}
                    color="#262626"
                    variant="subtitle1"
                  >
                    {s.status === 'INCOMPLETE'
                      ? 'Incomplete'
                      : session.custom
                          .filter(e => e.survey === s.survey)
                          .map(value => `${value.score}`)}
                  </Typography>
                </div>
              </div>
            ))}
          </div>

          <div style={{ height: 5 }} />

          <div className={classes.rowDivs}>
            <div style={{ width: 150, marginRight: 20 }}>
              <Typography color="#262626" variant="body1">
                Clinical Note:
              </Typography>
            </div>
            <div style={{ flex: 1, display: 'flex' }}>
              <TextField
                disabled={saving}
                style={{ width: '100%' }}
                multiline
                rows={5}
                variant="outlined"
                onChange={handleChange}
                value={comment && comment !== 'null' ? comment : ''}
              />
            </div>
          </div>

          <div style={{ height: 5 }} />

          <div className={classes.rowDivs}>
            <div style={{ width: 150, marginRight: 20 }}>
              <Typography color="#262626" variant="body1">
                Date & Time:
              </Typography>
            </div>
            <div style={{ flex: 1, display: 'flex' }}>
              <Typography
                style={{ fontWeight: 500 }}
                color="#262626"
                variant="subtitle1"
              >
                {session.startTimeStamp.value !== null &&
                typeof session.startTimeStamp.value !== 'undefined'
                  ? moment(session.startTimeStamp.value).format('L LT')
                  : ``}
              </Typography>
            </div>
          </div>
          <div className={classes.rowDivs}>
            <div style={{ width: 150, marginRight: 20 }}>
              <Typography color="#262626" variant="body1">
                Location:
              </Typography>
            </div>
            <div style={{ flex: 1, display: 'flex' }}>
              <Typography
                style={{ fontWeight: 500 }}
                color="#262626"
                variant="subtitle1"
              >
                {patient.userType && patient.userType === 'UserType.INCLINIC'
                  ? 'In Clinic'
                  : 'Personal'}
              </Typography>
            </div>
          </div>
          <div className={classes.rowDivs}>
            <div style={{ width: 150, marginRight: 20 }}>
              <Typography color="#262626" variant="body1">
                Recording Duration:
              </Typography>
            </div>
            <div style={{ flex: 1 }}>
              <Typography
                style={{
                  fontWeight: 500,
                  display: 'flex',
                  flexDirection: 'row'
                }}
                variant="subtitle1"
              >
                {duration}
                <div style={{ width: 10 }} />
                {loadRecordings ? (
                  <CircularProgress color="secondary" />
                ) : (
                  <Link
                    style={{ fontWeight: 500, color: '#715FA6' }}
                    onClick={() => setDialog(true)}
                  >
                    Play Recordings
                  </Link>
                )}
              </Typography>
            </div>
          </div>
        </div>
      </div>
      {showDialog ? openDialog() : null}
    </div>
  );
}

SessionBox.propTypes = {
  index: PropTypes.number,
  session: PropTypes.object,
  classes: PropTypes.object,
  update: PropTypes.func,
  saving: PropTypes.bool
};

const enhance = withStyles(styles);

export default enhance(SessionBox);

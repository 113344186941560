import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import {
  DialogTitle,
  DialogContent,
  Button,
  CircularProgress,
  TextField,
  Typography,
  Link,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';

import ClinicianSelect from './ClinicianSelect';
import { getCustomSurveys } from '../../../common/form/getCustomSurveys';

const styles = theme => ({
  title: {
    color: theme.palette.secondary.main,
    fontSize: 20
  },
  row: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20
  },
  bottomrow: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
    marginTop: 20
  }
});

function EditPatient({
  classes,
  close,
  info,
  partnerInfo,
  editPatient,
  loading
}) {
  const [edited, setEdit] = useState(false);
  const [name, setName] = useState(info.name);
  const [lastName, setLastName] = useState(info.lastName);
  const [mrn, setMrn] = useState(info.mrn);
  const [assignedAdminId, setAdmin] = useState(info.assignedAdminId);
  const [surveyBlacklist, setSurvey] = useState(info.surveyBlacklist || []);

  const [loadingCustomSurveysNames, setCSNames] = useState(false);
  const [surveyNames, setSurveyNames] = React.useState([]);

  useEffect(() => {
    const loadData = async () => {
      setCSNames(true);
      try {
        const resp = await getCustomSurveys();
        const surveys = [];
        resp.forEach(s => {
          surveys[s.id] = s.name;
        });
        setSurveyNames(surveys);
      } catch (e) {
        // silence error
        console.error(e);
      }
      setCSNames(false);
    };

    loadData();
  }, [info]);

  const savePatient = () => {
    info.surveyBlacklist = surveyBlacklist;
    editPatient({
      name,
      lastName,
      mrn,
      assignedAdminId,
      surveyBlacklist
    });
  };

  const handleChangeSurvey = event => {
    const array = [...surveyBlacklist];

    if (event.target.checked === true) {
      array.splice(event.target.value, 1);
    } else {
      array.push(event.target.value);
    }
    setEdit(true);
    setSurvey(array);
  };

  return (
    <div>
      <DialogTitle className={classes.title}>Edit Patient</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          To edit patient information, adjust values in the fields below. Use
          "Cancel" if you want to exit without saving any changes.
          {/* You
          can also disable/enable custom surveys shown in the app for this
          patient. */}
        </Typography>
        <div style={{ height: 20 }} />
        <div className={classes.row}>
          <Typography style={{ width: 170 }} color="inherit" variant="body1">
            First Name:
          </Typography>
          <div style={{ width: 5 }} />
          <TextField
            disabled={loading === true}
            value={name}
            onChange={event => {
              setName(event.target.value);
              setEdit(true);
            }}
            inputProps={{
              style: {
                padding: 8,
                width: 180
              }
            }}
            variant="outlined"
          />
        </div>

        <div style={{ marginTop: 10 }} className={classes.row}>
          <Typography style={{ width: 170 }} color="inherit" variant="body1">
            Last Name:
          </Typography>
          <div style={{ width: 5 }} />
          <TextField
            disabled={loading === true}
            value={lastName}
            onChange={event => {
              setLastName(event.target.value);
              setEdit(true);
            }}
            inputProps={{
              style: {
                padding: 8,
                width: 180
              }
            }}
            variant="outlined"
          />
        </div>

        <div style={{ marginTop: 10 }} className={classes.row}>
          <Typography style={{ width: 170 }} color="inherit" variant="body1">
            Patient ID/MRN:
          </Typography>
          <div style={{ width: 5 }} />
          <TextField
            disabled={loading === true}
            value={mrn}
            onChange={event => {
              setMrn(event.target.value);
              setEdit(true);
            }}
            inputProps={{
              style: {
                padding: 8,
                width: 180
              }
            }}
            variant="outlined"
          />
        </div>

        <div style={{ marginTop: 10 }} className={classes.row}>
          <Typography style={{ width: 170 }} color="inherit" variant="body1">
            Followed by:
          </Typography>
          <div style={{ width: 5 }} />
          <ClinicianSelect
            patient={info}
            onChange={d => {
              setAdmin(d.item.id);
              setEdit(true);
            }}
          />
        </div>

        {partnerInfo === null || loadingCustomSurveysNames ? (
          <div style={{ marginTop: 20 }} className={classes.row}>
            <Typography style={{ width: 170 }} color="inherit" variant="body1">
              Loading survey options
            </Typography>
            <CircularProgress />
          </div>
        ) : (
          <div style={{ marginTop: 10 }} className={classes.row}>
            <Typography style={{ width: 170 }} color="inherit" variant="body1">
              Surveys enabled:
            </Typography>
            <div style={{ width: 5 }} />
            <FormControl className={classes.formControl}>
              <FormGroup row>
                {partnerInfo.surveys.map(s => (
                  <FormControlLabel
                    control={
                      // eslint-disable-next-line react/jsx-wrap-multilines
                      <Checkbox
                        checked={surveyBlacklist.indexOf(s) < 0}
                        onChange={handleChangeSurvey}
                        value={s}
                      />
                    }
                    label={surveyNames[s] || s}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </div>
        )}

        <div className={classes.bottomrow}>
          <Link
            color="secondary"
            disabled={loading}
            onClick={close}
            style={{ cursor: 'pointer' }}
          >
            <Typography
              style={{ cursor: 'pointer', fontWeight: 700, fontSize: 15 }}
              variant="body1"
              color="secondary"
            >
              Cancel
            </Typography>
          </Link>
          <div style={{ flex: 1 }} />
          {edited && partnerInfo ? (
            loading || loadingCustomSurveysNames ? (
              <CircularProgress />
            ) : (
              <Button
                disabled={loading}
                onClick={savePatient}
                variant="contained"
                style={{ background: '#AF46AE', color: 'white' }}
              >
                Save Patient Info
              </Button>
            )
          ) : (
            ''
          )}
        </div>
      </DialogContent>
    </div>
  );
}

EditPatient.propTypes = {
  classes: PropTypes.object,
  close: PropTypes.func,
  info: PropTypes.object,
  partnerInfo: PropTypes.object,
  editPatient: PropTypes.func,
  loading: PropTypes.bool
};

export default withStyles(styles)(EditPatient);

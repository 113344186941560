import React from 'react';

import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';

import {
  Typography,
  Tooltip,
  FormControl,
  Select,
  IconButton,
  Button,
  Paper,
  MenuItem,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  CircularProgress,
  LinearProgress
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import LogsIcon from '@material-ui/icons/EventNote';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '../common/snackbar/snackbar';
import LogsDialog from './logsDialog';
import Dialog from '../common/dialog/dialog';
import EditUserForm from './clinicianForm';
import NewUserForm from './clinicianForm';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  button: {
    marginTop: theme.spacing(3)
  },
  inviteButton: {
    display: 'block',
    margin: 'auto'
  },
  closeIcon: {
    width: '100%',
    textAlign: 'right'
  },
  textField: {
    width: '60%'
  },
  formControl: {
    margin: theme.spacing(3),
    alignItems: 'center',
    justifyContent: 'center'
  },
  enabledFormControl: {
    padding: 0
  },
  progress: {
    margin: theme.spacing(2),
    bottom: 10,
    width: '90%'
  },
  paper: {
    marginTop: theme.spacing(3)
  },
  tableWrapper: {
    overflowX: 'auto'
  },
  table: {
    minWidth: 650
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
});

function desc(a, b, orderBy) {
  if (orderBy === 'creationTime' || orderBy === 'lastSignInTime') {
    const v1 = new Date(a[orderBy]);
    const v2 = new Date(b[orderBy]);

    if (v2 < v1) {
      return -1;
    }
    if (v2 > v1) {
      return 1;
    }
    return 0;
  }

  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc'
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const mapAccess = { true: 'disabled', false: 'enabled' };

class ManagementComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      newUserFormOpen: false,
      editUserFormOpen: false,
      edit: false,
      showSnackbar: false,
      showSnackbarSaveConfig: false,
      showLogsDialog: false,
      showDeleteDialog: false,

      // order of the values in the table
      order: 'desc',
      orderBy: 'creationTime',

      // State about the current user edited
      editPartnerList: {},
      editRole: '',
      editEmail: '',
      editPhone: '',
      editFirstName: '',
      editLastName: '',
      editDisplayName: ''
    };

    this.openLogsDialog = this.openLogsDialog.bind(this);
    this.createNewUser = this.createNewUser.bind(this);
    this.handleEditUser = this.handleEditUser.bind(this);
  }

  componentDidMount() {
    const { loginState, getUserList } = this.props;
    const { userPartnerList } = loginState.portalConfig;
    const data = {
      userPartnerList
    };

    getUserList(data);
  }

  handleClickNewUser = () => {
    this.setState({
      newUserFormOpen: !this.state.newUserFormOpen
    });
  };

  handleClose = () => {
    this.setState({
      newUserFormOpen: false,
      editUserFormOpen: false
    });
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  startEditingUser = user => event => {
    /**
     *  1)Create a js map where to store the partners with a boolean value.
     *  2)Check which of those partners were active for the edited user
     *  3)Set to true the partners active for the selected user.
     */
    // 1)
    const partners = {};
    const { userPartnerList } = this.props.loginState.portalConfig;
    for (let i = 0; i < userPartnerList.length; i++) {
      partners[userPartnerList[i]] = false;
    }
    // 2)
    const editUserPartners = user.partners;
    for (let j = 0; j < editUserPartners.length; j++) {
      if (partners.hasOwnProperty(editUserPartners[j])) {
        partners[editUserPartners[j]] = true;
      }
    }

    this.setState({
      editRole: user.role,
      editEmail: user.email,
      editPhone: user.phone || '',
      editPartnerList: partners,
      editFirstName: user.firstName,
      editLastName: user.lastName,
      editDisplayName: user.displayName || '',
      editUserFormOpen: true,
      newUserFormOpen: false
    });

    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  };

  handleEditUser(values) {
    const {
      partners,
      firstName,
      lastName,
      email,
      displayName,
      phone,
      role,
      editedPartner
    } = values;
    const { configurations } = this.props.loginState.portalConfig;

    const partnersArray = [];

    for (const partner in partners) {
      if (partners[partner] === true) {
        partnersArray.push(partner);
      }
    }

    /**
     * If the partner list has been edited, we need to change the fields as well.
     */
    let data = { empty: 'empty' };
    if (editedPartner === true) {
      /**
       * Assign the partner configurations
       * --> If the number of partners is 1, set the fields configurations for that partner.
       * --> If multiple partners, assign the complete field set (Only EH users get to see multiple partners)
       */
      if (partnersArray.length > 1) {
        data = configurations.COMPLETE_FIELDS;
      } else {
        data = configurations[partnersArray[0]];
      }
    }

    /*
     *  --> Add  important fields to the data object
     */
    data.displayName = displayName;
    data.email = email.toLowerCase();
    data.phone = phone;
    data.role = role;
    data.firstName = firstName;
    data.lastName = lastName;
    data.partners = partnersArray;

    this.props.saveConfiguration(data);
  }

  handleSendInvite(email) {
    this.props.sendInviteEmail(email);
  }

  handleRequestSort = property => event => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  convertPartnerToObj(userPartnerList) {
    // Create a js map where to store the partners with a boolean value.
    // The boolean value will change if the user selects or deselects partners
    const partners = {};

    for (let i = 0; i < userPartnerList.length; i++) {
      partners[userPartnerList[i]] = true;
    }

    return partners;
  }

  createNewUser(props) {
    const {
      email,
      displayName,
      phone,
      firstName,
      lastName,
      role,
      partners
    } = props;
    const { configurations } = this.props.loginState.portalConfig;
    const partnersArray = [];

    /**
     * PREPARE THE PARTNERS ARRAY
     * partners contains a map with "partner: bool" values.
     * The partners with value = true are the one that will be used for the new user.
     * They will be saved in the partnersArray.
     */
    for (const partner in partners) {
      if (partners[partner] === true) {
        partnersArray.push(partner);
      }
    }

    /**
     * Assign the partner configurations
     * --> If the number of partners is 1, set the fields configurations for that partner.
     * --> If multiple partners, assign the complete field set (Only EH users get to see multiple partners)
     */
    let data = { empty: 'empty' };
    if (partnersArray.length > 1) {
      data = configurations.COMPLETE_FIELDS;
    } else {
      data = configurations[partnersArray[0]];
    }

    /**
     *  --> Add other important fields to the data object
     * */
    data.email = email.toLowerCase();
    data.displayName = displayName;
    if (phone.length > 0) {
      data.phone = phone;
    }
    data.role = role;
    data.firstName = firstName;
    data.lastName = lastName;
    data.partners = partnersArray;

    this.props.createNewUser(data);
  }

  openLogsDialog = () => {
    const { showLogsSpinner, logs, logsMessage } = this.props.managementState;

    return (
      <Dialog maxWidth="lg" close={this.handleCloseDialog}>
        <LogsDialog
          // TODO: pass props
          close={this.handleCloseDialog}
          getUserLogs={this.props.getUserLogs}
          email={this.state.emailForDialog}
          spinner={showLogsSpinner}
          logs={logs}
          logsMessage={logsMessage}
        />
      </Dialog>
    );
  };

  handleOpenLogsDialog = email => event => {
    this.setState({ showLogsDialog: true, emailForDialog: email });
  };

  openDeleteDialog = () => {
    return (
      <Dialog close={this.handleCloseDialog}>
        <DialogTitle id="alert-dialog-title">
          {`Delete ${this.state.emailForDialog}`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this clinician? All the logs will be
            deleted as well.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button
            onClick={e => {
              this.props.deleteDashboardUser({
                email: this.state.emailForDialog,
                uid: this.state.uidToDelete,
                disabled: true
              });
              this.handleCloseDialog();
            }}
            color="primary"
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  handleOpenDeleteDialog = (email, uid) => event => {
    this.setState({
      showDeleteDialog: true,
      emailForDialog: email,
      uidToDelete: uid
    });
  };

  handleCloseDialog = () => {
    this.setState({ showLogsDialog: false, showDeleteDialog: false });
  };

  componentWillReceiveProps(newProps) {
    if (
      this.props.managementState.snackbar.variant === '' &&
      newProps.managementState.snackbar.variant !== ''
    ) {
      this.setState(
        {
          showSnackbar: true
        },
        () => {
          // Load user data when config saved
          const { userPartnerList } = this.props.loginState.portalConfig;
          const data = {
            userPartnerList
          };
          this.props.getUserList(data);
        }
      );
    } else if (
      this.props.managementState.snackbar.variant !== '' &&
      newProps.managementState.snackbar.variant === ''
    ) {
      this.setState({
        showSnackbar: false
      });
    }

    if (
      this.props.loginState.snackbar.variant === '' &&
      newProps.loginState.snackbar.variant !== ''
    ) {
      this.setState(
        {
          showSnackbarSaveConfig: true
        },
        () => {
          // Load user data when config saved
          const { userPartnerList } = this.props.loginState.portalConfig;
          const data = {
            userPartnerList
          };

          this.props.getUserList(data);
        }
      );
    }
  }

  render() {
    const { classes, loginState, managementState } = this.props;
    const { portalConfig } = loginState;
    const { userPartnerList, role } = portalConfig;
    const {
      showSpinner,
      userList,
      showInviteSpinner,
      showEditDisableSpinner
    } = managementState;
    const {
      showSnackbar,
      showSnackbarSaveConfig,
      order,
      orderBy,
      editPartnerList,
      editRole,
      editEmail,
      editPhone,
      editFirstName,
      editLastName,
      editDisplayName
    } = this.state;

    /**
     * FILTER THE USERLIST
     * 1)If the user is admin, super users cannot be seen.
     */
    let users = userList;
    if (portalConfig.role === 'admin') {
      users = userList.filter(a => a.role !== 'super');
    }
    //--------------------------------------------------

    // Create a js map where to store the partners with a boolean value.
    // The boolean value will change if the user selects or deselects partners
    const partners = this.convertPartnerToObj(userPartnerList);

    return (
      <div style={{ padding: '20px 20px 20px 20px' }}>
        {/* <BackButton history={history} path="/home" /> */}

        <div className={classes.root}>
          <Typography variant="h5">Manage Clinicians</Typography>

          {!this.state.newUserFormOpen && !this.state.editUserFormOpen ? (
            <Button
              onClick={this.handleClickNewUser}
              variant="contained"
              color="primary"
              className={classes.button}
            >
              Add New User
            </Button>
          ) : (
            <Button
              onClick={this.handleClose}
              variant="contained"
              color="primary"
              className={classes.button}
            >
              <CloseIcon style={{ marginRight: 10 }} />
              Close
            </Button>
          )}

          <NewUserForm
            open={this.state.newUserFormOpen}
            userPartnerList={partners}
            role={role}
            email=""
            phone=""
            firstName=""
            lastName=""
            displayName=""
            snackbar={this.props.managementState.snackbar}
            showSpinner={showSpinner}
            emailDisabled={false}
            buttonClick={this.createNewUser}
            closeForm={this.handleClose}
            buttonTitle="Create User"
          />

          <EditUserForm
            open={this.state.editUserFormOpen}
            userPartnerList={editPartnerList}
            role={editRole}
            email={editEmail}
            phone={editPhone}
            firstName={editFirstName}
            lastName={editLastName}
            displayName={editDisplayName}
            snackbar={this.props.loginState.snackbar}
            showSpinner={this.props.loginState.showLoginSpinner}
            emailDisabled
            buttonClick={this.handleEditUser}
            closeForm={this.handleClose}
            buttonTitle="Edit User"
          />
        </div>

        {/* Dashboard users' table. Show only if other users are available. During loading, show spinner. */}
        {showSpinner && users.length < 1 ? (
          <div className={classes.root}>
            {' '}
            <CircularProgress />{' '}
          </div>
        ) : users.length < 1 ? (
          <div className={classes.root}>
            {' '}
            <Typography variant="body1">
              {' '}
              No users currently available.
            </Typography>{' '}
          </div>
        ) : (
          <Paper className={classes.paper}>
            <div className={classes.tableWrapper}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ paddingRight: '0px' }}>
                      Actions
                    </TableCell>
                    <TableCell
                      key="email"
                      sortDirection={orderBy === 'email' ? order : false}
                    >
                      <Tooltip
                        title="Sort"
                        placement="bottom-end"
                        enterDelay={300}
                      >
                        <TableSortLabel
                          active={orderBy === 'email'}
                          direction={order}
                          onClick={this.handleRequestSort('email')}
                        >
                          E-Mail
                        </TableSortLabel>
                      </Tooltip>
                    </TableCell>

                    <TableCell
                      key="firstName"
                      sortDirection={orderBy === 'firstName' ? order : false}
                    >
                      <Tooltip
                        title="Sort"
                        placement="bottom-end"
                        enterDelay={300}
                      >
                        <TableSortLabel
                          active={orderBy === 'firstName'}
                          direction={order}
                          onClick={this.handleRequestSort('firstName')}
                        >
                          First Name
                        </TableSortLabel>
                      </Tooltip>
                    </TableCell>

                    <TableCell
                      key="lastName"
                      sortDirection={orderBy === 'lastName' ? order : false}
                    >
                      <Tooltip
                        title="Sort"
                        placement="bottom-end"
                        enterDelay={300}
                      >
                        <TableSortLabel
                          active={orderBy === 'lastName'}
                          direction={order}
                          onClick={this.handleRequestSort('lastName')}
                        >
                          Last Name
                        </TableSortLabel>
                      </Tooltip>
                    </TableCell>

                    <TableCell
                      key="role"
                      sortDirection={orderBy === 'role' ? order : false}
                    >
                      <Tooltip
                        title="Sort"
                        placement="bottom-end"
                        enterDelay={300}
                      >
                        <TableSortLabel
                          active={orderBy === 'role'}
                          direction={order}
                          onClick={this.handleRequestSort('role')}
                        >
                          Role
                        </TableSortLabel>
                      </Tooltip>
                    </TableCell>

                    <TableCell
                      key="disabled"
                      sortDirection={orderBy === 'disabled' ? order : false}
                    >
                      <Tooltip
                        title="Sort"
                        placement="bottom-end"
                        enterDelay={300}
                      >
                        <TableSortLabel
                          active={orderBy === 'disabled'}
                          direction={order}
                          onClick={this.handleRequestSort('disabled')}
                        >
                          Access
                        </TableSortLabel>
                      </Tooltip>
                    </TableCell>

                    <TableCell
                      key="creationTime"
                      sortDirection={orderBy === 'creationTime' ? order : false}
                    >
                      <Tooltip
                        title="Sort"
                        placement="bottom-end"
                        enterDelay={300}
                      >
                        <TableSortLabel
                          active={orderBy === 'creationTime'}
                          direction={order}
                          onClick={this.handleRequestSort('creationTime')}
                        >
                          Created
                        </TableSortLabel>
                      </Tooltip>
                    </TableCell>

                    <TableCell
                      key="lastSignInTime"
                      sortDirection={
                        orderBy === 'lastSignInTime' ? order : false
                      }
                    >
                      <Tooltip
                        title="Sort"
                        placement="bottom-end"
                        enterDelay={300}
                      >
                        <TableSortLabel
                          active={orderBy === 'lastSignInTime'}
                          direction={order}
                          onClick={this.handleRequestSort('lastSignInTime')}
                        >
                          Last Login
                        </TableSortLabel>
                      </Tooltip>
                    </TableCell>

                    <TableCell>Invite</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {stableSort(users, getSorting(order, orderBy)).map(user => (
                    <TableRow key={user.email}>
                      <TableCell style={{ paddingRight: '0px' }}>
                        <div className={classes.actions}>
                          <Tooltip title="Edit User">
                            <IconButton
                              style={{ padding: '0px', marginRight: '8px' }}
                              onClick={this.startEditingUser(user)}
                            >
                              <EditIcon style={{ color: '#000000' }} />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Check Logs">
                            <IconButton
                              style={{ padding: '0px', marginRight: '12px' }}
                              onClick={this.handleOpenLogsDialog(user.email)}
                            >
                              <LogsIcon style={{ color: '#000000' }} />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete User">
                            <IconButton
                              style={{ padding: '0px' }}
                              onClick={this.handleOpenDeleteDialog(
                                user.email,
                                user.uid
                              )}
                            >
                              <DeleteIcon style={{ color: '#000000' }} />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {user.email}
                      </TableCell>

                      <TableCell>{user.firstName}</TableCell>
                      <TableCell>{user.lastName}</TableCell>
                      <TableCell>
                        {user.role === 'admin' ? 'Practice Manager' : user.role}
                      </TableCell>
                      <TableCell>
                        {(portalConfig.role === 'admin' &&
                          user.role === 'admin') ||
                        showInviteSpinner ||
                        showEditDisableSpinner ||
                        showSpinner ? (
                          mapAccess[user.disabled]
                        ) : (
                          <FormControl variant="outlined">
                            <Select
                              value={mapAccess[user.disabled]}
                              onChange={e => {
                                this.props.editDisable({
                                  uid: user.uid,
                                  disabled:
                                    e.target.value === 'enabled' ? false : true
                                });
                              }}
                            >
                              <MenuItem value={mapAccess[user.disabled]}>
                                {mapAccess[user.disabled]}
                              </MenuItem>
                              <MenuItem value={mapAccess[!user.disabled]}>
                                {mapAccess[!user.disabled]}
                              </MenuItem>
                            </Select>
                          </FormControl>
                        )}
                      </TableCell>
                      <TableCell>
                        {user.creationTime
                          ? moment(user.creationTime).format('L LT')
                          : ''}
                      </TableCell>
                      <TableCell>
                        {user.lastSignInTime
                          ? moment(user.lastSignInTime).format('L LT')
                          : ''}
                      </TableCell>
                      <TableCell>
                        <Button
                          disabled={showInviteSpinner}
                          onClick={() => this.handleSendInvite(user.email)}
                          variant="outlined"
                          color="primary"
                          className={classes.inviteButton}
                        >
                          Re-Invite
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </Paper>
        )}

        {this.state.showLogsDialog ? this.openLogsDialog() : null}
        {this.state.showDeleteDialog ? this.openDeleteDialog() : null}

        {showEditDisableSpinner ||
        (showSpinner && users.length > 1) ||
        showInviteSpinner ? (
          <LinearProgress />
        ) : (
          ''
        )}

        {!showSnackbar ? (
          ''
        ) : (
          <Snackbar
            open
            variant={this.props.managementState.snackbar.variant}
            message={this.props.managementState.snackbar.message}
          />
        )}
        {!showSnackbarSaveConfig ? (
          ''
        ) : (
          <Snackbar
            open
            variant={this.props.loginState.snackbar.variant}
            message={this.props.loginState.snackbar.message}
          />
        )}
      </div>
    );
  }
}

export default withStyles(styles)(ManagementComponent);

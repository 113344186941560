// import fetch from 'cross-fetch';
import firebase from '../../../config/firebase';
import { requestPatientsJson, receivePatientsJson } from './actions';

require('firebase/functions');

const getPatientsJson = (
  partnerList,
  sort,
  sortOrder,
  limit,
  offset,
  filters
) => {
  return dispatch => {
    dispatch(requestPatientsJson());
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser')).email;

    const sessionList = firebase
      .functions()
      .httpsCallable('dashboardFunctions-bq_patientsList');
    sessionList({
      partnerList,
      sort,
      sortOrder,
      limit,
      offset,
      filters,
      currentUser
    }).then(result => {
      const patientsCount = JSON.parse(result.data).patientsCount.count;
      const patients = JSON.parse(result.data).patientsList;

      dispatch(
        receivePatientsJson(
          patientsCount,
          patients,
          sort,
          sortOrder,
          limit,
          offset,
          filters
        )
      );
    });
  };
};

export default { getPatientsJson };

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography, CircularProgress, Button } from '@material-ui/core';

import Dialog from '../../../common/dialog/dialog';
import InvitePatient from './invitePatientDialog';

import ClinicianSelectDefault from '../../../common/form/ClinicianSelectDefault';
import firebase from '../../../../config/firebase';

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    paddingTop: 8,
    width: '100%',
    position: 'relative',
    height: 60,
    [theme.breakpoints.down(1000)]: {
      width: '100%',
      alignItems: 'center'
    }
  },
  cont_one: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down(1000)]: {
      alignItems: 'center'
    }
  },
  cont_oneB: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  count: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.up(1000)]: {
      width: 600
    },
    [theme.breakpoints.down(700)]: {
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  button: {
    width: 300,
    padding: 15,
    textTransform: 'none',
    '&:active': {
      backgroundColor: theme.palette.third.main
    },
    '&:focus': {
      backgroundColor: theme.palette.third.main
    },
    '&:hover': {
      backgroundColor: theme.palette.third.main
    },
    backgroundColor: theme.palette.third.main,
    color: '#ffffff'
  }
});

function TopMainPage({
  classes,
  patientsCount,
  showSpinner,
  partnerList,
  setPartnerInfo,
  handleOpenSnackbar
}) {
  const [open, setOpen] = useState(false);
  const [partner, setPartner] = useState({});
  const [loading, setLoading] = useState(false);

  const openDialog = () => {
    return (
      <Dialog close={() => setOpen(false)}>
        <InvitePatient
          partners={partnerList}
          close={() => setOpen(false)}
          handleOpenSnackbar={text => handleOpenSnackbar(text)}
        />
      </Dialog>
    );
  };

  useEffect(() => {
    setLoading(true);
    const getPartner = firebase
      .functions()
      .httpsCallable('dashboardFunctions-getPartner');
    getPartner({
      id: partnerList[0]
    })
      .then(result => {
        if (result.status) {
          // error
          setLoading(false);
          return;
        }
        setPartner(result.data);
        setPartnerInfo(result.data);
        setLoading(false);
      })
      .catch(e => {
        console.log(e);
        setLoading(false);
      });
  }, [partnerList]);

  return (
    <div className={classes.root}>
      <div className={classes.cont_one}>
        <div className={classes.cont_oneB}>
          <Typography className={classes.count} color="inherit" variant="body1">
            Total Registered Patients:
            <div style={{ width: 5 }} />
            {showSpinner && patientsCount === -1 ? (
              <CircularProgress size={20} />
            ) : (
              <Typography
                style={{ fontWeight: 700 }}
                color="inherit"
                variant="body1"
              >
                {patientsCount}
              </Typography>
            )}
          </Typography>
          <Typography className={classes.count} color="inherit" variant="body1">
            By Default, new patients are followed by: &nbsp;&nbsp;
            {loading ? (
              <CircularProgress size={20} />
            ) : (
              <ClinicianSelectDefault
                data={partner}
                onChange={d => {
                  partner.defaultPatientClinician = d.defaultPatientClinician;
                  setPartner(partner);
                }}
              />
            )}
          </Typography>
        </div>

        <div style={{ flex: 1 }} />
        {/* <Button
          style={{ width: 300, padding: 15, textTransform: 'none' }}
          variant="outlined"
        >
          View Email Invitation Template
        </Button> */}
        <Button onClick={() => setOpen(true)} className={classes.button}>
          Register New Patient
        </Button>
        {open === true ? openDialog() : ''}
      </div>
    </div>
  );
}

TopMainPage.propTypes = {
  classes: PropTypes.object,
  patientsCount: PropTypes.number,
  showSpinner: PropTypes.bool,
  partnerList: PropTypes.array,
  handleOpenSnackbar: PropTypes.func
};

export default withStyles(styles)(TopMainPage);

import firebase from '../../../config/firebase';

const cache = [];
const func = firebase
  .functions()
  .httpsCallable('dashboardFunctions-getClinicians');

export async function getClinicians(partner) {
  if (cache[partner]) return Promise.resolve(cache[partner]);
  const { data } = await func(partner);

  cache[partner] = data;
  return data;
}

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  CircularProgress,
  Typography,
  Select,
  FormControl
} from '@material-ui/core';
import { saveAs } from 'file-saver';
import { pdf } from '@react-pdf/renderer';
import LableValue from './label';

import AssessmentDoc from '../../../common/reports/pdf/d&a_assessment';
import ClinicianSelect from '../../../common/form/ClinicianSelect';
import { getCustomSurveys } from '../../../common/form/getCustomSurveys';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 8,
    width: '100%',
    [theme.breakpoints.down(850)]: {
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  cont_one: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '25%',
    minWidth: 200
  },
  cont_two: {
    height: '50%', // best 75
    // width: '40%',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: '#b8b8b8',
    padding: 6,
    minWidth: 300,
    marginBottom: 10
  },
  cont_three: {
    height: '100%',
    // width: '30%',
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 15,
    minWidth: 200
  },
  cont_three_content: {
    // width: '60%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down(700)]: {
      flexDirection: 'column',
      margin: 20
    }
  },
  button: {
    height: 50,
    width: 150,
    marginTop: 8,
    backgroundColor: theme.palette.button.main,
    color: '#f4f4f4'
  },
  loading: {
    height: 50,
    marginTop: 8
  },
  buttonPdfReady: {
    height: 50,
    marginTop: 8,
    backgroundColor: '#008000',
    '&:hover': {
      backgroundColor: '#008000',
      transition: 'none'
    }
  },
  buttonCSV: {
    height: 30,
    width: 20,
    marginLeft: 10,
    backgroundColor: theme.palette.button.main,
    color: '#f4f4f4'
  },
  text: {
    color: theme.palette.text.main
  },
  link: {
    textDecoration: 'none'
  },
  followedBy: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center'
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120
  }
});

function ButtonComponent(props) {
  const { onClick, loading, disabled, style } = props;
  return (
    <Button
      className={style}
      color="secondary"
      variant="contained"
      onClick={onClick}
      disabled={disabled}
    >
      {loading && <CircularProgress size={14} />}
      {!loading && 'GO'}
    </Button>
  );
}

function Top({
  classes,
  info,
  sessions,
  updateUserDetails,
  clinician,
  displayName,
  role,
  downloadScreenshot,
  loadingFile,
  createCSV,
  loadRecordings
}) {
  const [exportfile, setExport] = React.useState('CSV1');
  const [loading, setLoading] = React.useState(false);
  const [surveyNames, setSurveyNames] = React.useState([]);

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      try {
        const resp = await getCustomSurveys();
        const surveys = [];
        resp.forEach(s => {
          surveys[s.id] = s.name;
        });
        setSurveyNames(surveys);
      } catch (e) {
        // silence error
        console.error(e);
      }
      setLoading(false);
    };

    loadData();
  }, [clinician]);

  const exportFile = async () => {
    const surveyMap = [];
    const surveyListBetweenSessions = [];
    const dataXSession = [];
    let surveysMaxLength = 9; // Default value to PHQ9
    const headersCSV1 = [
      'Patient Name',
      'MRN',
      'Date & Time',
      'EH Depression',
      'EH Anxiety',
      'PHQ-8',
      'GAD-7'
    ];
    const headersCSV2 = ['Date & Time', 'Instrument', 'Total Score'];
    const dataToExport = [];

    let blob = null;

    switch (exportfile) {
      case 'CSV1':
        setLoading(true);
        sessions.forEach((s, i) => {
          dataXSession[i] = {
            name: `${info.name} ${info.lastName}`,
            mrn: info.mrn,
            date_time: s.startTimeStamp
              ? `${moment(s.startTimeStamp.value).format(
                  'YYYY-MM-DD_HH:mm:ss'
                )}`
              : '',
            EH_Depression: s.phq_r ? s.phq_r.toFixed(0) : 'INCOMPLETE',
            EH_Anxiety: s.gad_r ? s.gad_r.toFixed(0) : 'INCOMPLETE',
            PHQ: s.phqStatus === 'COMPLETE' ? s.phqTotal : s.phqStatus,
            GAD: s.gadStatus === 'COMPLETE' ? s.gadTotal : s.gadStatus,
            surveysResults: []
          };

          s.custom.forEach(item => {
            if (!surveyMap[item.survey]) {
              surveyMap[item.survey] = true;
              surveyListBetweenSessions.push(item.survey);
            }

            dataXSession[i].surveysResults[item.survey] = item.score
              ? item.score
              : 'INCOMPLETE';
          });

          s.customStatus.forEach(value => {
            if (!surveyMap[value.survey]) {
              surveyMap[value.survey] = true;
              surveyListBetweenSessions.push(value.survey);
            }

            if (
              dataXSession[i].surveysResults[value.survey] &&
              value.status !== 'COMPLETE'
            ) {
              dataXSession[i].surveysResults[value.survey] = value.status;
            } else if (!dataXSession[i].surveysResults[value.survey]) {
              dataXSession[i].surveysResults[value.survey] = 'INCOMPLETE';
            }
          });
        });

        dataXSession.forEach((d, i) => {
          dataToExport[i] = [
            `"${d.name}"`,
            `"${d.mrn}"`,
            `"${d.date_time}"`,
            `"${d.EH_Depression}"`,
            `"${d.EH_Anxiety}"`,
            `"${d.PHQ}"`,
            `"${d.GAD}"`
          ];

          surveyListBetweenSessions.forEach((s, index) => {
            if (d.surveysResults[s]) {
              dataToExport[i].push(`"${d.surveysResults[s]}"`);
            } else {
              dataToExport[i].push(`""`);
            }
          });
        });

        surveyListBetweenSessions.forEach((s, index) => {
          if (surveyNames[s]) {
            surveyListBetweenSessions[index] = surveyNames[s];
          }
        });

        createCSV(
          dataToExport.reverse(),
          headersCSV1.concat(surveyListBetweenSessions),
          `Ellipsis_${info.name}${info.lastName}_${moment(new Date()).format(
            'YYYY-MM-DD'
          )}.csv`
        );

        setLoading(false);
        break;
      case 'CSV2':
        setLoading(true);
        sessions.forEach((s, i) => {
          const date = s.startTimeStamp
            ? `${moment(s.startTimeStamp.value).format('YYYY-MM-DD_HH:mm:ss')}`
            : '';
          dataToExport.push([
            `"${date}"`,
            `"EH Depression"`,
            `"${s.phq_r ? s.phq_r.toFixed(0) : 'INCOMPLETE'}"`
          ]);
          dataToExport.push([
            `"${date}"`,
            `"EH Anxiety"`,
            `"${s.gad_r ? s.gad_r.toFixed(0) : 'INCOMPLETE'}"`
          ]);
          if (s.phqStatus) {
            dataToExport.push(
              [
                `"${date}"`,
                `"PHQ-8"`,
                `"${s.phqStatus === 'COMPLETE' ? s.phqTotal : s.phqStatus}"`
              ].concat(s.phqScores)
            );
          }
          if (s.gadStatus) {
            dataToExport.push(
              [
                `"${date}"`,
                `"GAD-7"`,
                `"${s.gadStatus === 'COMPLETE' ? s.gadTotal : s.gadStatus}"`
              ].concat(s.gadScores)
            );
          }

          s.custom.forEach(item => {
            surveyMap[item.survey] = item;
          });

          s.customStatus.forEach(value => {
            if (
              surveyMap[value.survey] &&
              surveysMaxLength < surveyMap[value.survey].scores.length
            ) {
              surveysMaxLength = surveyMap[value.survey].scores.length;
            }

            if (value.status === 'COMPLETE') {
              dataToExport.push(
                [
                  `"${date}"`,
                  `"${
                    surveyNames[value.survey]
                      ? surveyNames[value.survey]
                      : value.survey
                  }"`,
                  `"${
                    surveyMap[value.survey] ? surveyMap[value.survey].score : ''
                  }"`
                ].concat(
                  surveyMap[value.survey] ? surveyMap[value.survey].scores : []
                )
              );
            } else {
              dataToExport.push([
                `"${date}"`,
                `"${
                  surveyNames[value.survey]
                    ? surveyNames[value.survey]
                    : value.survey
                }"`,
                `"${value.status}"`
              ]);
            }
          });
        });

        for (let i = 0; i < surveysMaxLength; i += 1) {
          headersCSV2.push(`Q${i + 1}`);
        }

        createCSV(
          dataToExport.reverse(),
          headersCSV2,
          `Ellipsis_${info.name}${info.lastName}_Details_${moment(
            new Date()
          ).format('YYYY-MM-DD')}.csv`
        );

        setLoading(false);

        break;
      case 'PDF1':
        setLoading(true);
        blob = await pdf(
          <AssessmentDoc
            data={sessions}
            selected={sessions.map(n => n.uid)}
            user={info}
            clinician={clinician}
            clinicianDisplayName={displayName}
          />
        ).toBlob();
        saveAs(
          blob,
          `Ellipsis_${info.name}${info.lastName}_${moment(new Date()).format(
            'YYYY-MM-DD'
          )}.pdf`
        );
        setLoading(false);
        break;
      case 'PDF2':
        downloadScreenshot();
        break;
      case '':
        break;
      default:
      // code block
    }
  };

  return (
    <div className={classes.root}>
      <div id="topContOne" className={classes.cont_one}>
        {role !== 'viewer' ? (
          <>
            <LableValue name="First Name" value={info.name} />

            <LableValue name="Last Name" value={info.lastName} />

            <LableValue name="MRN" value={info.mrn} />
            <LableValue name="Birth Year" value={info.birthyear} />
          </>
        ) : (
          ''
        )}
        <LableValue name="Total Sessions" value={sessions.length} />
      </div>
      <div id="topContTwo" className={classes.cont_two}>
        <Typography className={classes.text} variant="body1">
          {info.createdAt
            ? `Monitoring was set up on ${moment(info.createdAt.value).format(
                'L LT'
              )}`
            : `Monitoring information not available`}
        </Typography>
        <Typography className={classes.text} variant="body1">
          {info.userType && info.userType === 'UserType.INCLINIC'
            ? 'Patient has registered with the Ellipsis Health In-Clinic app'
            : 'Patient has registered with the Ellipsis Health Rising Higher app'}
        </Typography>
        <Typography className={classes.text} variant="body1">
          {info.userType && info.userType === 'UserType.INCLINIC'
            ? `Patient has conducted ${sessions.length} in-clinic sessions`
            : `Patient has conducted ${sessions.length} personal sessions`}
        </Typography>
        <div className={classes.followedBy}>
          <Typography className={classes.text}>
            Followed By: &nbsp;&nbsp;
          </Typography>
          <ClinicianSelect
            patient={info}
            onChange={d => updateUserDetails(d)}
          />
        </div>
      </div>
      {role !== 'viewer' ? (
        <div className={classes.cont_three}>
          <div className={classes.cont_three_content}>
            <Typography
              style={{ color: '#715FA6', fontWeight: 'bold', marginRight: 10 }}
              variant="body1"
            >
              EXPORT:
            </Typography>
            <FormControl variant="outlined" className={classes.formControl}>
              <Select
                native
                value={exportfile} // state.age
                onChange={event => {
                  setExport(event.target.value);
                }} // handleChange('age')
                style={{
                  height: 40,
                  fontSize: 14,
                  width: '100%'
                }}
              >
                <option value="CSV1">All Sessions Summary (CSV)</option>
                <option value="CSV2">All Sessions With Details (CSV)</option>
                <option value="PDF1">All Sessions Summary (PDF)</option>
                <option value="PDF2">Patient Screenshot (PDF)</option>
              </Select>
            </FormControl>

            <ButtonComponent
              style={classes.buttonCSV}
              loading={loading || loadingFile || loadRecordings}
              disabled={loading || loadingFile || loadRecordings}
              onClick={exportFile}
            />
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
}

Top.propTypes = {
  classes: PropTypes.object,
  info: PropTypes.object,
  sessions: PropTypes.array,
  clinician: PropTypes.string
};

ButtonComponent.propTypes = {
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  style: PropTypes.object
};

const enhance = withStyles(styles);

export default enhance(Top);

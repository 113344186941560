// import fetch from 'cross-fetch';
import firebase from '../../../../config/firebase';
import {
  requestLogin,
  receiveUserInfo,
  requestSaveConfiguration,
  saveConfigurationCompleted,
  requestSaveFilters,
  saveFiltersCompleted,
  setError,
  loginError,
  setLoginState,
  logout
} from './actions';

import { homeActions } from '../../../home/redux';

const saveLogs = data => {
  return dispatch => {
    const saveLogsFunction = firebase
      .functions()
      .httpsCallable('dashboardFunctions-saveLogs');
    saveLogsFunction(data)
      .then(function(result) {
        if (result.data === 'success') {
          console.log(`Logs saved correctly. User: ${data.email}`);
        } else {
          console.log(
            `Something wrong happened while saving logs for the user: ${data.email}`
          );
        }
      })
      .catch(error => {
        console.error('Error saving logs: ', error.message);
      });
  };
};

const saveConfiguration = data => {
  return dispatch => {
    dispatch(requestSaveConfiguration());
    // ( { merge: true } --> Existing data in the db are not be deleted )

    const { email } = data;
    delete data.email;

    firebase
      .firestore()
      .collection('admins')
      .doc(email)
      .set(data, { merge: true })
      .then(function() {
        console.log('User info successfully saved!');

        const snackbar = {
          variant: 'success',
          message: `New Configuration Saved. User: ${email}`
        };

        dispatch(saveConfigurationCompleted(data, snackbar));
      })
      .catch(error => {
        console.error('Error writing user info in document: ', error.message);
        const snackbar = {
          variant: 'error',
          message: error.message
        };
        dispatch(setError(snackbar));
      });
  };
};

const logUserOut = () => {
  return dispatch => {
    saveLogs({
      email: JSON.parse(sessionStorage.getItem('currentUser')).email,
      type: 'logout/firebase',
      success: 'Success',
      text: 'Logout'
    });

    firebase
      .auth()
      .signOut()
      .then(() => {
        console.log('Log user out');
        sessionStorage.clear();
        localStorage.clear();

        dispatch(logout());
      })
      .catch(error => {
        console.log(error.message);
        const snackbar = {
          variant: 'error',
          message: error.message
        };
        dispatch(setError(snackbar));
      });
  };
};

const getUserJson = (email, password) => {
  let portalConfig;

  return dispatch => {
    dispatch(requestLogin());
    firebase
      .auth()
      .signInWithEmailAndPassword(email.toLowerCase(), password)
      .then(result => {
        const { user } = result;
        /**
         * Check if user is admin
         * yes -> get user info and partners the user has permission to see
         * no -> Logout user and send error
         */
        firebase
          .firestore()
          .collection('admins')
          .doc(user.email.toLowerCase())
          .get()
          .then(admin => {
            saveLogs({
              email: user.email,
              type: 'login/firebase',
              success: 'Success',
              text: 'Login'
            });

            /**
             * Get the current admin dashboard configuration and set the partner list for the state.
             */
            portalConfig = admin.data();
            portalConfig.userPartnerList = admin.data().partners;
            delete portalConfig.partners;

            /**
             * HOME REDUX ACTIONS
             * All the portal requests for data must initially be filtered by the user's email.
             * This action sets the initial filter in the home reducer.
             */
            dispatch(homeActions.setFollowedby(portalConfig.email));

            /**
             * Save the current date of login
             * date of login is in --> user.metadata.a (milliseconds)
             */
            dispatch(
              saveConfiguration({
                email: user.email.toLowerCase(),
                lastLogin: user.metadata.b
              })
            );

            /**
             * Get OVERALL dashboard configurations from the firestore database.
             * Be sure we get the configurations for the specific admin's partners
             */
            firebase
              .firestore()
              .collection('dashboard_config')
              .get()
              .then(snapshotConfig => {
                /**
                 * TODO:
                 * - If the admin has only one partner, get the configuration of that partner only
                 * - If the admin has more than one partner, provide all the configurations
                 */
                const configurations = {};

                snapshotConfig.forEach(config => {
                  const nextConfig = config.data();
                  const docId = config.id;
                  configurations[docId] = nextConfig;
                });

                portalConfig.configurations = configurations;
                dispatch(receiveUserInfo(user, portalConfig));
              })
              .catch(error => {
                console.log('Error getting dashboard config:', error);

                const snackbar = {
                  variant: 'error',
                  message:
                    'Sorry, we were unable to get the user information. Please try again later.'
                };
                dispatch(loginError(snackbar));
                logUserOut();
              });
          })
          .catch(error => {
            console.log('Error getting admin user:', error);

            const snackbar = {
              variant: 'error',
              message:
                'Sorry, we were unable to get the user information. Please try again later.'
            };

            saveLogs({
              email: user.email,
              type: 'login/firebase',
              success: 'Error',
              text: 'Error during login'
            });

            dispatch(loginError(snackbar));
            logUserOut();
          });
      })
      .catch(error => {
        console.log(error.message);

        const snackbar = {
          variant: 'error',
          message: error.message
        };
        dispatch(loginError(snackbar));
      });
  };
};

const saveFilter = data => {
  return dispatch => {
    dispatch(requestSaveFilters());
    dispatch(saveFiltersCompleted(data));
  };
};

export default {
  getUserJson,
  setLoginState,
  saveFilter,
  saveConfiguration,
  logUserOut,
  saveLogs
};

import React, { useState } from 'react';
import { LinearProgress } from '@material-ui/core';

import firebase from '../../../config/firebase';

const storage = firebase.storage();
// Create a storage reference from our storage service
const storageRef = storage.ref();

function Audio(props) {
  const [url, setURL] = useState(null);
  const [loading, setLoading] = useState(true);
  const { recording, style } = props;

  // The audio link needs to be splitted until the substring 'com'
  if (!url) {
    storageRef
      .child(recording.audioFile.split('com').pop())
      .getDownloadURL()
      .then(URL => {
        setURL(URL);
        setLoading(false);
      })
      .catch(e => {
        /**
         * Some recordings are saved in a different storage bucket (ellipsisapp-prod project).
         * Try to get from there, before returning an empty URL
         */

        if (recording.newAudioFile) {
          setURL(recording.newAudioFile);
          setLoading(false);
        } else {
          const getRec = firebase
            .functions()
            .httpsCallable('dashboardFunctions-getRecordingUrl');
          getRec(
            recording.audioFile
              .split('com')
              .pop()
              .slice(1) // Remove the first slash from the file path.
          )
            .then(result => {
              recording.newAudioFile = result.data;
              setURL(result.data);
              setLoading(false);
            })
            .catch(error => {
              console.log('Error ', error);
              setLoading(false);
            });
        }
      });
  }

  return (
    <React.Fragment>
      {loading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <LinearProgress
            style={{ align: 'center', marginBottom: 15, width: 10 }}
          />
        </div>
      ) : (
        <audio
          style={style}
          src={url}
          controls
          onPlay={props.savePlayLogs}
          onPause={props.savePauseLogs}
        >
          Your browser does not support the audio element
        </audio>
      )}
    </React.Fragment>
  );
}

export default Audio;

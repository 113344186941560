import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import get from 'lodash/get';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { DatePicker } from 'material-ui-pickers';
import { Paper, TextField, Button } from '@material-ui/core';
import { downloadCsv } from 'utils/csv';

const styles = theme => ({
  paper: {
    padding: theme.spacing(2)
  },
  form: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  },
  button: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  }
});

const PRESETS = [
  {
    label: 'Last Week',
    value: {
      startDate: moment()
        .subtract(1, 'week')
        .startOf('week')
        .toDate(),
      endDate: moment()
        .subtract(1, 'week')
        .endOf('week')
        .toDate()
    }
  },
  {
    label: 'This Week',
    value: {
      startDate: moment()
        .startOf('week')
        .toDate(),
      endDate: moment()
        .endOf('week')
        .toDate()
    }
  },
  {
    label: 'Last Month',
    value: {
      startDate: moment()
        .subtract(1, 'month')
        .startOf('month')
        .toDate(),
      endDate: moment()
        .subtract(1, 'month')
        .endOf('month')
        .toDate()
    }
  },
  {
    label: 'This Month',
    value: {
      startDate: moment()
        .startOf('month')
        .toDate(),
      endDate: moment()
        .endOf('month')
        .toDate()
    }
  }
];

function ReportFilter({
  classes,
  value,
  loading,
  result,
  onError,
  onChange,
  userPartnerList,
  onSubmit
}) {
  const handleSubmit = e => {
    e.preventDefault();
    if (!value.partner) {
      return onError(new Error('Please select a partner'));
    }
    if (loading) return;
    return onSubmit(value);
  };

  const handleChange = field => newValue => {
    onChange({
      ...value,
      [field]: newValue
    });
  };

  const handlePreset = p => {
    onChange({
      ...value,
      ...p.value
    });
  };

  const handleCsv = () => {
    const filename = `report_${moment(value.startDate).format(
      'YYYY-MM-DD'
    )}_${moment(value.endDate).format('YYYY-MM-DD')}.csv`;
    const data = [
      `"Unique Sessions",${result.uniqueSessions}`,
      `"Unique Users",${result.uniqueUsers}`,
      `"Unique Late Users",${result.uniqueDelayedUsers}`,
      [
        'User ID',
        'Contact Email',
        'Contact Phone',
        'Name',
        'Last Name',
        'Avg PHQ',
        'Sessions',
        'Completed Sessions',
        'Late Sessions'
      ]
        .map(f => `"${f}"`)
        .join(','),
      ...result.users.map(u =>
        [
          u.userId,
          u.contactEmail,
          u.contactPhone,
          u.name,
          u.lastName,
          u.avgPhq,
          u.sessionCount,
          u.completedSessionCount,
          u.delayedSessionCount
        ].join(',')
      )
    ];
    return downloadCsv(filename, data);
  };

  return (
    <Paper className={classes.paper}>
      <form className={classes.form} onSubmit={handleSubmit}>
        <TextField
          className={classes.textField}
          name="Partner"
          label="Partner"
          select
          value={value.partner}
          SelectProps={{
            native: true
          }}
          InputLabelProps={{
            shrink: true
          }}
          onChange={e => handleChange('partner')(e.target.value)}
        >
          <option value="">Select a partner</option>
          {userPartnerList.map(p => (
            <option key={p} value={p}>
              {p}
            </option>
          ))}
        </TextField>
        <DatePicker
          className={classes.textField}
          value={value.startDate}
          format="MM/DD/YYYY"
          onChange={handleChange('startDate')}
          label="Start Date"
        />
        <DatePicker
          className={classes.textField}
          value={value.endDate}
          format="MM/DD/YYYY"
          onChange={handleChange('endDate')}
          label="End Date"
        />
        {PRESETS.map(p => (
          <Button
            key={p.label}
            type="button"
            color="default"
            variant="contained"
            className={classes.button}
            onClick={() => handlePreset(p)}
          >
            {p.label}
          </Button>
        ))}
        <Button
          className={classes.button}
          type="submit"
          variant="contained"
          color="primary"
          disabled={loading}
        >
          Generate Report
        </Button>
        {result && (
          <Button
            className={classes.button}
            onClick={handleCsv}
            type="button"
            variant="contained"
            color="primary"
            disabled={loading}
          >
            Download CSV
          </Button>
        )}
      </form>
    </Paper>
  );
}

ReportFilter.propTypes = {
  userPartnerList: PropTypes.array,
  result: PropTypes.object,
  value: PropTypes.object,
  loading: PropTypes.bool,
  onError: PropTypes.func,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  classes: PropTypes.object
};

const mapStateToProps = state => ({
  userPartnerList: get(state, 'login.portalConfig.userPartnerList', [])
});

const enhance = compose(connect(mapStateToProps), withStyles(styles));

export default enhance(ReportFilter);

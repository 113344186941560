import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Redirect } from 'react-router-dom';
import pckg from '../../../../package.json';

import {
  Button,
  CssBaseline,
  FormControl,
  Input,
  InputLabel,
  Link,
  Paper,
  Typography,
  LinearProgress
} from '@material-ui/core';
import logo from '../../../assets/logo.svg';
import Snackbar from '../../common/snackbar/snackbar';

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    marginTop: theme.spacing(3)
  },
  progress: {
    margin: theme.spacing(2)
  },
  link: {
    textAlign: 'center',
    margin: theme.spacing(1)
  }
});

class LoginComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      password: '',
      email: ''
    };

    this.loginPage = this.loginPage.bind(this);
  }

  loginUser = async e => {
    e.preventDefault();
    // Login method call
    this.props.getUserData(this.state.email.trim(), this.state.password);
  };

  loginPage = () => {
    const { classes, snackbar } = this.props;

    return (
      <main className={classes.main}>
        <CssBaseline />
        <Paper className={classes.paper}>
          <img alt="" src={logo} width="100" height="100" />
          {/* <Typography component="h1" variant="h5">
            Analytics
          </Typography> */}
          <form className={classes.form} onSubmit={this.loginUser}>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="email">Email Address</InputLabel>
              <Input
                value={this.state.email}
                id="email"
                name="email"
                onChange={e => this.setState({ email: e.target.value })}
                autoComplete="email"
                autoFocus
              />
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="password">Password</InputLabel>
              <Input
                value={this.state.password}
                name="password"
                type="password"
                id="password"
                onChange={e => this.setState({ password: e.target.value })}
                autoComplete="current-password"
              />
            </FormControl>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Sign in
            </Button>

            <Typography
              style={{ marginTop: 10 }}
              align="center"
              variant="body1"
            >
              <Link
                underline="always"
                onClick={() => {
                  this.props.history.push('/__/auth/reset/2factauth');
                }}
                color="secondary"
              >
                Reset password
              </Link>
            </Typography>

            {this.props.showLoginSpinner ? (
              <LinearProgress className={classes.progress} />
            ) : (
              ''
            )}
            {this.props.isLoggedIn ? (
              <Redirect to={{ pathname: '/home' }} />
            ) : (
              ''
            )}
          </form>

          <p
            style={{
              textAlign: 'right',
              marginRight: '3%',
              fontSize: 12
            }}
          >
            Version: {pckg.version}_{process.env.REACT_APP_ENV.slice(0, 1)}
          </p>
        </Paper>

        {snackbar.variant === '' ? (
          ''
        ) : (
          <Snackbar
            open={true}
            variant={snackbar.variant}
            message={snackbar.message}
          />
        )}
      </main>
    );
  };

  render() {
    return this.loginPage();
  }
}

LoginComponent.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(LoginComponent);

/* 
    This file contains string literals for your action types. 
    This provides an easy reference for the actions available. 
    These strings are exported as an object literal which can then be 
    imported into your reducers and action creators instead of hard-coding them. 
    Although maintaining a separate file containing just your action types is 
    optional, it is highly recommended.
*/

const REQUEST_LOGIN = 'REQUEST_LOGIN';
const RECEIVE_USER_INFO = 'RECEIVE_USER_INFO';

const REQUEST_REGISTRATION = 'REQUEST_REGISTRATION';
const RECEIVE_REGISTRATION_RESULT = 'RECEIVE_REGISTRATION_RESULT';

const REQUEST_SAVE_CONFIG = 'REQUEST_SAVE_CONFIG';
const SAVE_CONFIG_COMPLETED = 'SAVE_CONFIG_COMPLETED';

const REQUEST_SAVE_FILTERS = 'REQUEST_SAVE_FILTERS';
const SAVE_FILTERS_COMPLETED = 'SAVE_FILTERS_COMPLETED';

const ERROR = 'ERROR';
const SET_LOGIN_STATE = 'SET_LOGIN_STATE';
const LOGOUT = 'LOGOUT';

export default {
  REQUEST_LOGIN,
  RECEIVE_USER_INFO,
  REQUEST_REGISTRATION,
  RECEIVE_REGISTRATION_RESULT,
  REQUEST_SAVE_CONFIG,
  SAVE_CONFIG_COMPLETED,
  REQUEST_SAVE_FILTERS,
  SAVE_FILTERS_COMPLETED,
  ERROR,
  SET_LOGIN_STATE,
  LOGOUT
};

import firebase from 'config/firebase';
import 'firebase/functions';

export function callFunc(name, params) {
  const func = firebase.functions().httpsCallable(name);

  const currentUser = JSON.parse(sessionStorage.getItem('currentUser')).email;
  return func({
    ...params,
    currentUser
  });
}

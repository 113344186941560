import React, { useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography, CircularProgress } from '@material-ui/core';
import firebase from '../../config/firebase';

import logo from '../../assets/logo.svg';

const styles = () => ({
  // theme
  root: {
    padding: '40px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
});

function Redirect({ classes, props }) {
  const location = useLocation();
  const history = useHistory();
  const queryValues = queryString.parse(location.search);
  const [actionCode] = useState(
    queryValues.oobCode ? queryValues.oobCode : null
  );
  /**
   * The option params contains info used to redirect to different pages.
   * - option === alert --> the user is creating passowrd for the alert dashboard
   */
  const [option] = useState(queryValues.option ? queryValues.option : null);

  const validateCode = () => {
    firebase
      .auth()
      .verifyPasswordResetCode(actionCode)
      .then(async email => {
        const accountEmail = email;
        console.log(
          'The reset token is still valid for the email',
          accountEmail
        );

        history.push(`/__/auth/reset/setpassword${location.search}`);
      })
      .catch(error => {
        // Invalid or expired action code. Ask user to try to reset the password
        // again.
        console.log(
          'Invalid or expired reset token. Ask user to try to reset the password again.',
          error
        );

        if (option) {
          history.push('/__/auth/reset/setpasswordresponse?response=0');
        } else {
          history.push('/__/auth/reset/2factauth');
        }
      });
  };

  if (actionCode === null) {
    return (
      <div className={classes.root}>
        <img alt="" src={logo} width="100" height="100" />
        <Typography
          style={{ marginTop: 10, fontSize: '20px' }}
          component="h2"
          variant="h2"
        >
          You are missing important parameters.
        </Typography>
      </div>
    );
  }

  validateCode();
  return (
    <div className={classes.root}>
      <CircularProgress color="secondary" />
    </div>
  );
}

Redirect.propTypes = {
  classes: PropTypes.object,
  props: PropTypes.object
};

export default withStyles(styles)(Redirect);

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, CircularProgress } from '@material-ui/core';

const styles = theme => ({
  root: {
    width: '100%',
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
});

function LoadingContainer({ loading, children, classes }) {
  if (loading) {
    return (
      <div className={classes.root}>
        <CircularProgress />
      </div>
    );
  }

  if (typeof children === 'function') return children();

  return children;
}

LoadingContainer.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func])
};

export default withStyles(styles)(LoadingContainer);

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  LinearProgress,
  Paper,
  DialogContent,
  DialogContentText,
  Typography
} from '@material-ui/core';
import Dialog from '../dialog/dialog';
import TableRowLoader from './tableSections/tableRowLoader';
import TablePaginationActions from './tableSections/tablePagination';
import TableSorting from './tableSections/tableSorting';

const styles = theme => ({
  root: {
    marginTop: theme.spacing(2)
  },
  table: {
    minWidth: 650
  },
  progress: {
    margin: theme.spacing(2),
    bottom: 10
  },
  tableWrapper: {
    overflowX: 'auto'
  },
  footer: {
    width: '100%'
  }
});

class GeneralTable extends React.Component {
  state = {
    // state variable for the Dialog used for text Cells
    openCellDialog: false
  };

  handleRequestSort = (event, property) => {
    // property is row.id (so the name of the column)
    const {
      sortOrder,
      sort,
      rowsPerPage,
      offset,
      filters,
      loadData
    } = this.props;

    let sortArrayOrder = [];
    let sortArray = {};
    let data = {};

    const orderBy = property; // column's header that has been clicked by the user

    if (orderBy in sort) {
      /*
       * 1st case: column is in sort array, just change sort direction (ASC, DESC)
       */
      sortArrayOrder = sortOrder;
      sortArray = sort;

      if (sortArray[orderBy] === 'desc') {
        sortArray[orderBy] = 'asc';
      } else {
        sortArray[orderBy] = 'desc';
      }

      data = {
        sort: sortArray,
        sortOrder: sortArrayOrder,
        rowsPerPage,
        offset,
        filters
      };
    } else {
      /*
       * 2nd case: There are no columns in the sort array.
       */
      sortArrayOrder.push(orderBy);
      sortArray[orderBy] = 'desc';

      data = {
        sort: sortArray,
        sortOrder: sortArrayOrder,
        rowsPerPage,
        offset,
        filters
      };
    }

    loadData(data);
  };

  handleChangePage = (event, offset) => {
    const { sort, sortOrder, rowsPerPage, filters, loadData } = this.props;
    const data = {
      sort,
      sortOrder,
      rowsPerPage,
      offset,
      filters
    };

    loadData(data);
  };

  handleChangeRowsPerPage = rows => {
    const {
      offset,
      dataCount,
      sort,
      sortOrder,
      filters,
      loadData
    } = this.props;
    const rowsPerPage = parseInt(rows, 10); // base 10, remove if error
    let OFFSET = offset;

    if (OFFSET + rowsPerPage > dataCount && dataCount !== 0) {
      OFFSET = dataCount - rowsPerPage;
      if (OFFSET < 0) {
        OFFSET = 0;
      }
    }

    const data = {
      sort,
      sortOrder,
      rowsPerPage,
      offset: OFFSET,
      filters
    };

    loadData(data);
  };

  /* removeSorting = (value, index) => {
    const {
      // setting,
      sortOrder,
      sort,
      rowsPerPage,
      offset,
      filters,
      loadData
    } = this.props;
    // Remove the sorting from the given value (value)
    const SORTORDER = sortOrder;
    const SORT = sort;
    SORTORDER.splice(index, 1);
    delete SORT[value];

    const data = {
      sort: SORT,
      sortOrder: SORTORDER,
      rowsPerPage,
      offset,
      filters
    };

    loadData(data);
  }; */

  removeSorting = () => {
    const { rowsPerPage, offset, filters, loadData } = this.props;

    const data = {
      sort: {},
      sortOrder: [],
      rowsPerPage,
      offset,
      filters
    };

    loadData(data);
  };

  buildColumns = (showColumns, columnsName) => {
    const columns = showColumns.map(col => ({
      id: col,
      label: columnsName[col]
    }));

    return columns;
  };

  openCellDialog = (val, id) => {
    const { saveLogs } = this.props;

    this.setState({
      openCellDialog: true,
      openCellDialogValue: val[id]
    });

    if (id === 'transcription') {
      saveLogs({
        email: JSON.parse(sessionStorage.getItem('currentUser')).email,
        type: 'open/transcription',
        success: 'Success',
        text: `The user checked the transcription of the session:
          ${val.sessionId}
          and question:
          ${val.question}`
      });
    } else if (id === 'wordsTimestamp') {
      saveLogs({
        email: JSON.parse(sessionStorage.getItem('currentUser')).email,
        type: 'open/wordsTimestamp',
        success: 'Success',
        text: `The user checked the words timestamps of the recording: ${val.uid}`
      });
    }
  };

  handleCloseCellDialog = () => {
    this.setState({
      openCellDialog: false
    });
  };

  render() {
    const {
      classes,
      config,
      data,
      dataCount,
      sort,
      sortOrder,
      rowsPerPage,
      offset,
      filters,
      showColumns,
      // hideColumns,
      columnsName,
      showSpinner
    } = this.props;
    const { openCellDialog, openCellDialogValue } = this.state;

    const columns = this.buildColumns(showColumns, columnsName);

    const sortingDisabled = ['details', 'actions', 'gadScores', 'phqScores'];

    return (
      <Paper className={classes.root}>
        {!showSpinner && data.length === 0 ? (
          <div
            style={{
              height: 50,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Typography version="body1">
              {filters.length > 0
                ? 'There are no results matching that search. Please try again.'
                : 'There are no results available.'}
            </Typography>
          </div>
        ) : (
          <>
            <div className={classes.tableWrapper}>
              <Table className={classes.table}>
                <TableSorting
                  config={config}
                  sort={sort}
                  sortOrder={sortOrder}
                  showSpinner={showSpinner}
                  onRequestSort={this.handleRequestSort}
                  rows={columns}
                  sortingDisabled={sortingDisabled}
                  checkbox={false}
                />
                <TableBody>
                  <TableRowLoader
                    allProps={this.props}
                    columns={columns}
                    checkbox={false}
                    cellClick={(val, id) => this.openCellDialog(val, id)}
                  />
                </TableBody>
              </Table>
            </div>

            <TablePaginationActions
              className={classes.footer}
              count={dataCount}
              rowsPerPage={rowsPerPage}
              offset={offset}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </>
        )}

        {showSpinner ? <LinearProgress className={classes.progress} /> : null}

        {/* The following dialog is used to show table cells values better. */}
        {openCellDialog ? (
          <Dialog close={this.handleCloseCellDialog}>
            <DialogContent>
              <DialogContentText>{openCellDialogValue}</DialogContentText>
            </DialogContent>
          </Dialog>
        ) : (
          ''
        )}
      </Paper>
    );
  }
}

GeneralTable.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(GeneralTable);

import { createMuiTheme } from '@material-ui/core/styles';

const palette = {
  primary: { main: '#8346AD' },
  // secondary: { main: '#CAB4DA', light: '#DFD0EA', extralight: '#F1EBF5' },
  secondary: {
    main: '#6D63A1',
    palepurple: '#CAB1DE',
    light: '#DFD0EA',
    extralight: '#F1EBF5'
  },
  third: {
    main: '#AF46AE'
  },
  button: {
    main: '#6D63A1'
  },
  text: {
    main: '#715FA6'
  }
};

export default createMuiTheme({ palette });

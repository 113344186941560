import React from 'react';
import moment from 'moment';
import getTableField from './getTableField';
import { TableRow } from '@material-ui/core';

const tableRowLoader = props => {
  const { allProps, columns } = props;
  const { data, lastLogin } = allProps;
  /**
   * Session new (not yet seen from the user):
   * HOW: Check if lastLogin is < than session endTimeStamp
   * RESULT: The text is bold or normal
   * - Send a variable with the correct style
   * --> {fontWeight: 500} OR {}
   */
  let newSession = {};
  if (typeof lastLogin !== 'undefined') {
    // 600 is the bold value set for tableCells representing newSessions not yet viewed
    newSession = 600;
  }

  const list = data.map(session => (
    <TableRow key={data.indexOf(session)}>
      {lastLogin &&
      session.startTimeStamp &&
      Number(lastLogin) <=
        Number(moment(session.startTimeStamp.value).format('x'))
        ? getTableField(columns, session, allProps, newSession)
        : getTableField(columns, session, allProps, 0)}
    </TableRow>
  ));

  return list;
};

export default tableRowLoader;

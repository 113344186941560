import React from 'react';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper
} from '@material-ui/core';

// import CircularProgress from '@material-ui/core/CircularProgress';
// import LinearProgress from '@material-ui/core/LinearProgress';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    marginTop: theme.spacing(3)
  },
  tableWrapper: {
    overflowX: 'auto'
  },
  table: {
    minWidth: 650
  }
});

class DefaultTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  render() {
    const { classes, headers, rows } = this.props;

    for (var i = 0; i < rows.length; i++) {
      rows[i].values = rows[i].values.reverse();
    }

    return (
      <Paper className={classes.paper}>
        <div className={classes.tableWrapper}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                {headers.map(header => (
                  <TableCell>{header}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(row => (
                <>
                  <TableRow style={{ backgroundColor: '#b2ebf2' }} key={row.id}>
                    <TableCell>{row.id}</TableCell>
                    <TableCell>{''}</TableCell>
                    <TableCell>{''}</TableCell>
                    <TableCell>{''}</TableCell>
                  </TableRow>

                  {row.values.map(data => (
                    <TableRow key={row.id + '-' + row.values.indexOf(data)}>
                      <TableCell>
                        {data.date !== null
                          ? moment(
                              new Date(parseInt(data.date.seconds) * 1000)
                            ).format('L LT')
                          : ''}
                      </TableCell>
                      <TableCell>{data.type}</TableCell>
                      <TableCell>{data.success.toString()}</TableCell>
                      <TableCell>{data.text}</TableCell>
                    </TableRow>
                  ))}
                </>
              ))}
            </TableBody>
          </Table>
        </div>
      </Paper>
    );
  }
}

export default withStyles(styles)(DefaultTable);

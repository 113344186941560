import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { DataList } from 'components';

const styles = theme => ({
  root: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
});

function ReportSummary({ classes, result }) {
  const items = [
    {
      title: 'Unqiue Sessions',
      value: result.uniqueSessions
    },
    {
      title: 'Unique Users',
      value: result.uniqueUsers
    },
    {
      title: 'Unqiue Late Users',
      value: result.uniqueDelayedUsers
    }
  ];

  return (
    <div className={classes.root}>
      <DataList items={items} />
    </div>
  );
}

ReportSummary.propTypes = {
  result: PropTypes.object,
  classes: PropTypes.object
};

export default withStyles(styles)(ReportSummary);

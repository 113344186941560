import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import queryString from 'query-string';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import SessionTable from '../common/table/table';
import MiddleMainPage from './components/MiddleMainPage';
import TopMainPage from './components/TopMainPage';
import BottomDisclaimerPage from '../common/BottomDisclaimerPage';

import FollowedBySelect from '../common/form/FollowedBySelect';

const styles = theme => ({
  root: {
    padding: '10px 20px 20px 20px',
    [theme.breakpoints.down(1000)]: {
      padding: '5px 10px 10px 10px'
    }
  },
  cont: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down(1000)]: {
      alignItems: 'center'
    }
  },
  control: {
    width: '100%',
    height: '40px',
    backgroundColor: '#D0D1C9',
    display: 'flex'
  }
});

class SessionsComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedIndex:
        queryString.parse(document.location.search).v === 'new' ? 1 : 0
    };
  }

  componentDidMount() {
    const { homeState, portalConfig } = this.props;
    const { filters } = homeState;

    if (!queryString.parse(document.location.search).v) {
      this.saveConfiguration({
        filters: []
      });
      return;
    }

    const page = sessionStorage.getItem('page');
    sessionStorage.removeItem('page');

    if (page !== 'details' && page !== 'dashboard') {
      // IF the previous page was patients details or dashboard --> not reloading the sessions
      const data = {
        sort: homeState.sort,
        sortOrder: homeState.sortOrder,
        rowsPerPage: homeState.rowsPerPage,
        offset: homeState.offset,
        filters: [...filters, ...portalConfig.sessionsFilters]
      };
      this.onloadSessions(data);
      return;
    }

    if (queryString.parse(document.location.search).v === 'new') {
      // history.push('/sessions?page=1');

      // If params of URL has value v=new , load new sessions
      this.saveConfiguration({
        filters: [
          {
            field: 'startTimeStamp',
            operator: '>=',
            value: `${moment
              .utc(portalConfig.lastLogin, 'x')
              .format('YYYY-MM-DDTHH:mm:ss')}`,
            type: 'Date'
          }
        ]
      });
    } else if (
      queryString.parse(document.location.search).v === 'all' &&
      portalConfig.sessionsFilters.length > 0
    ) {
      // history.push('/sessions?page=1');

      this.saveConfiguration({
        filters: []
      });
    }
  }

  onloadSessions = data => {
    const { portalConfig, getSessionData } = this.props;

    const partnerList = portalConfig.userPartnerList;
    const ll = portalConfig.lastLogin || new Date().getTime();
    const lastLogin = `${moment.utc(ll, 'x').format('YYYY-MM-DDTHH:mm:ss')}`;

    getSessionData(
      lastLogin,
      partnerList,
      data.sort,
      data.sortOrder,
      data.rowsPerPage,
      data.offset,
      data.filters
    );
  };

  saveConfiguration = data => {
    const { loginState, saveFilter } = this.props;
    const { homeState } = this.props;
    const { filters } = homeState;

    data.email = loginState.user.email;
    if (data.filters) {
      data.sessionsFilters = data.filters;
      delete data.filters;
    }

    saveFilter(data);
    this.onloadSessions({
      sort: homeState.sort,
      sortOrder: homeState.sortOrder,
      rowsPerPage: homeState.rowsPerPage,
      offset: homeState.offset,
      filters: [...filters, ...data.sessionsFilters] // Merge arrays without changing props
    });
  };

  downloadCsv = () => {
    const {
      homeState,
      // loginState,
      portalConfig,
      downloadCsv
    } = this.props;
    const {
      lastLogin,
      userPartnerList,
      sessionsFilters,
      showSessionsColumns,
      columnsName
    } = portalConfig;

    const data = {};

    const ll = lastLogin || new Date().getTime();
    data.lastLogin = `${moment.utc(ll, 'x').format('YYYY-MM-DDTHH:mm:ss')}`;
    data.partnerList = userPartnerList;
    data.sort = homeState.sort;
    data.sortOrder = homeState.sortOrder;
    data.showColumns = showSessionsColumns;
    data.columnsName = columnsName;
    // In case the user wants all data, use the sessionCount as limit and offset starts from 0
    data.limit = homeState.sessionCount;
    data.offset = 0;
    //-----------------------------------
    data.filters = sessionsFilters;

    // send also the role of the clinician and configurations
    const { configurations, role } = portalConfig;
    const config = { configurations, role };
    data.config = config;
    //-----------------------------------
    downloadCsv(data);
  };

  downloadSpecificCsv = data => {
    const { portalConfig, downloadCsv } = this.props;
    const { columnsName, showSessionsColumns } = portalConfig;

    data.showColumns = showSessionsColumns;
    data.columnsName = columnsName;

    // send also the role of the clinician and configurations
    const { configurations, role } = portalConfig;
    const config = { configurations, role };
    data.config = config;
    //-----------------------------------
    downloadCsv(data);
  };

  changeFollowedby = value => {
    /**
     * CHANGE OF THE FOLLOWEDBY CONTROL
     * When the followedBy field is changed, the followed action are performed here:
     * 1- SetFollowedBy method called to set the new email
     * 2- Load again data based on the new filter
     */
    const {
      getPatientsData,
      getSessionData,
      onboardState,
      homeState,
      portalConfig,
      setFollowedby,
      setTotalPatients
    } = this.props;

    const partnerList = portalConfig.userPartnerList;
    const ll = portalConfig.lastLogin || new Date().getTime();
    const lastLogin = `${moment.utc(ll, 'x').format('YYYY-MM-DDTHH:mm:ss')}`;
    let filters = [];
    if (value) {
      filters = [
        {
          field: 'assignedAdminId',
          operator: '=',
          value: `${value}`,
          type: 'String'
        }
      ];
    }
    setFollowedby(value);
    setTotalPatients();

    getPatientsData(
      partnerList,
      onboardState.sort,
      onboardState.sortOrder,
      onboardState.rowsPerPage,
      onboardState.offset,
      filters
    );
    getSessionData(
      lastLogin,
      partnerList,
      homeState.sort,
      homeState.sortOrder,
      homeState.rowsPerPage,
      homeState.offset,
      [...filters, ...portalConfig.sessionsFilters] // Merge arrays without changing props
      // filters
    );
  };

  render() {
    const {
      classes,
      homeState,
      loginState,
      portalConfig,
      saveLogs,
      history
    } = this.props;
    const {
      sessionsFilters,
      settings,
      showSessionsColumns,
      hideSessionsColumns,
      columnsName,
      configurations,
      role,
      lastLogin
    } = portalConfig;
    const config = { configurations, role };
    const { selectedIndex } = this.state;

    return (
      <>
        <div className={classes.control}>
          <Typography
            style={{
              margin: 'auto',
              marginLeft: 8,
              display: 'flex',
              color: '#000000'
            }}
          >
            Show patients followed by: &nbsp;
            <FollowedBySelect
              partner={portalConfig.userPartnerList[0]}
              user={loginState}
              followedby={
                homeState.filters.length > 0 ? homeState.filters[0].value : null
              }
              onChange={value => this.changeFollowedby(value)}
              loadingData={homeState.showSpinner}
            />
          </Typography>
        </div>
        <div className={classes.root}>
          <div className={classes.cont}>
            <TopMainPage
              lastRefresh={homeState.lastRefresh}
              count={homeState.totalSessions}
              lastSession={homeState.lastSession}
              filters={sessionsFilters}
              newSessionsNumber={homeState.newSessionsNumber}
            />
            {config.role !== 'viewer' ? (
              <MiddleMainPage
                filterValue={selectedIndex}
                lastLogin={lastLogin || new Date().getTime()}
                saveConfiguration={this.saveConfiguration}
                activeFilters={sessionsFilters}
                downloadCsv={this.downloadCsv}
                download={homeState.download}
                showSpinner={homeState.showSpinner}
              />
            ) : (
              ''
            )}
          </div>
          <SessionTable
            config={config}
            lastLogin={lastLogin || new Date().getTime()}
            loadData={this.onloadSessions}
            sort={homeState.sort}
            sortOrder={homeState.sortOrder}
            rowsPerPage={homeState.rowsPerPage}
            offset={homeState.offset}
            filters={sessionsFilters}
            settings={settings}
            data={homeState.sessionData}
            dataCount={homeState.sessionCount}
            showSpinner={homeState.showSpinner}
            showColumns={showSessionsColumns}
            hideColumns={hideSessionsColumns}
            columnsName={columnsName}
            saveConfiguration={this.saveConfiguration}
            saveConfigCompleted={loginState.saveConfigCompleted}
            saveLogs={saveLogs}
            downloadCsv={this.downloadSpecificCsv}
            download={homeState.download}
            history={history}
          />
          <BottomDisclaimerPage />
        </div>
      </>
    );
  }
}

SessionsComponent.propTypes = {
  classes: PropTypes.object,
  homeState: PropTypes.object,
  loginState: PropTypes.object,
  onboardState: PropTypes.object,
  portalConfig: PropTypes.object,
  getSessionData: PropTypes.func,
  getPatientsData: PropTypes.func,
  setFollowedby: PropTypes.func,
  setTotalPatients: PropTypes.func,
  history: PropTypes.object
};

export default withStyles(styles)(SessionsComponent);

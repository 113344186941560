/* 
    This file contains string literals for your action types. 
    This provides an easy reference for the actions available. 
    These strings are exported as an object literal which can then be 
    imported into your reducers and action creators instead of hard-coding them. 
    Although maintaining a separate file containing just your action types is 
    optional, it is highly recommended.
*/

const REQUEST_CREATE_NEW_USER = 'REQUEST_CREATE_NEW_USER';
const RECEIVE_NEW_USER = 'RECEIVE_NEW_USER';

const REQUEST_USER_LIST = 'REQUEST_USER_LIST';
const RECEIVE_USER_LIST = 'RECEIVE_USER_LIST';

const REQUEST_SEND_INVITE = 'REQUEST_SEND_INVITE';
const INVITE_SENT = 'INVITE_SENT';

const REQUEST_EDIT_DISABLE = 'REQUEST_EDIT_DISABLE';
const DISABLE_EDITED = 'DISABLE_EDITED';

const REQUEST_USER_LOGS = 'REQUEST_USER_LOGS';
const RECEIVE_USER_LOGS = 'RECEIVE_USER_LOGS';

export default {
    REQUEST_CREATE_NEW_USER,
    RECEIVE_NEW_USER,
    REQUEST_USER_LIST,
    RECEIVE_USER_LIST,
    REQUEST_SEND_INVITE,
    INVITE_SENT,
    REQUEST_EDIT_DISABLE,
    DISABLE_EDITED,
    REQUEST_USER_LOGS,
    RECEIVE_USER_LOGS
}
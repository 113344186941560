// import fetch from 'cross-fetch';
import moment from 'moment';
import firebase from '../../../config/firebase';
import {
  requestSessionJson,
  receiveSessionJson,
  requestPatientJson,
  receivePatientJson,
  requestDownloadCsv,
  downloadCsvCompleted
} from './actions';

require('firebase/functions');

const getSessionJson = (
  lastLogin,
  partnerList,
  sort,
  sortOrder,
  limit,
  offset,
  filters
) => {
  return dispatch => {
    dispatch(requestSessionJson());

    const currentUser = JSON.parse(sessionStorage.getItem('currentUser')).email;
    // console.log(JSON.parse(sessionStorage.getItem('currentUser')));

    const sessionList = firebase
      .functions()
      .httpsCallable('dashboardFunctions-bq_sessionList');
    sessionList({
      lastLogin,
      partnerList,
      sort,
      sortOrder,
      limit,
      offset,
      filters,
      currentUser
    }).then(function(result) {
      const sessionCount = JSON.parse(result.data).sessionCount.count;
      const sessions = JSON.parse(result.data).sessionList;
      const newSessionsNumber = JSON.parse(result.data).newSessionsCount.count;

      dispatch(
        receiveSessionJson(
          sessionCount,
          sessions,
          newSessionsNumber,
          sort,
          sortOrder,
          limit,
          offset
        )
      );
    });
  };
};

const getPatientJson = partnerList => {
  return dispatch => {
    dispatch(requestPatientJson());

    const patientList = firebase
      .functions()
      .httpsCallable('dashboardFunctions-bq_patientsNumber');
    patientList({
      partnerList
    }).then(result => {
      const patientsCount = JSON.parse(result.data).patientsCount.count;
      // const patients = JSON.parse(result.data).patientList;
      dispatch(receivePatientJson(patientsCount));
    });
  };
};

const downloadCsv = data => {
  return dispatch => {
    dispatch(requestDownloadCsv());

    const fileName = data.fileName || null;

    if (data.data) {
      createCSV(
        data.config,
        data.data,
        data.showColumns,
        data.columnsName,
        fileName,
        dispatch
      );
      return;
    }

    const {
      lastLogin,
      partnerList,
      sort,
      sortOrder,
      limit,
      offset,
      filters
    } = data;

    const currentUser = JSON.parse(sessionStorage.getItem('currentUser')).email;

    const sessionList = firebase
      .functions()
      .httpsCallable('dashboardFunctions-bq_sessionList');
    sessionList({
      lastLogin,
      partnerList,
      sort,
      sortOrder,
      limit,
      offset,
      filters,
      currentUser
    }).then(function(result) {
      createCSV(
        data.config,
        JSON.parse(result.data).sessionList,
        data.showColumns,
        data.columnsName,
        fileName,
        dispatch
      );
    });
  };
};

const createCSV = (config, data, h, headersName, fileName, dispatch) => {
  const csvRows = [];
  const csvRowsHeadersName = [];
  const dateObject = ['startTimeStamp', 'endTimeStamp', 'createdAt']; // dates object. the date is in the value field
  const dateSecondsObject = ['lastSession']; // dates represented as 'seconds'. Need to be converted
  const headersToRemove = ['details', 'actions'];
  const { configurations, role } = config;
  /* Create headers object not affected by reference */
  const headers = h.slice();
  // get the temp headers (not user friendly, these are the columns name of the db)
  csvRows.push(headers.join(','));

  /**
   * HEADERS
   * - Remove columns into headersToRemove array
   * - Remove the restricted columns if role of the user is viewer
   */
  for (let r = 0; r < headersToRemove.length; r += 1) {
    if (headers.indexOf(headersToRemove[r]) > -1) {
      headers.splice(headers.indexOf(headersToRemove[r]), 1);
    }
  }
  if (role === 'viewer') {
    for (let i = headers.length - 1; i >= 0; i -= 1) {
      if (
        configurations.user_restriction.restricted_fields_viewer.indexOf(
          headers[i]
        ) > -1
      ) {
        headers.splice(i, 1);
      }
    }
  }
  //

  // loop over the rows
  // eslint-disable-next-line no-restricted-syntax
  for (const row of data) {
    const values = headers.map(header => {
      let escaped;

      // We don't want to see null or undefined values in the csv
      /* if (row[header] === null || row[header] === undefined) {
        escaped = ''.replace(/"/g, '\\"');

        return `"${escaped}"`;
      } */

      // (''+row[header]) converts everything into a string and allows us to use replace() also with no string values
      // Moreover, with this escaped solution all commas inside values are going to be safe and not create problems.
      escaped = `${row[header]}`.replace(/"/g, '\\"');
      if (dateObject.indexOf(header) > -1) {
        try {
          escaped = `${moment(row[header].value).format(
            'YYYY-MM-DD_HH:mm:ss'
          )}`.replace(/"/g, '\\"');
        } catch (err) {
          console.error(
            `The value "${header}" of the user ${row.userId} has an error: ${err}`
          );
          escaped = ' '.replace(/"/g, '\\"');
        }
      } else if (dateSecondsObject.indexOf(header) > -1) {
        try {
          const d = moment(new Date(parseInt(row[header]) * 1000)).format(
            'L LT'
          );
          escaped = `${d}`.replace(/"/g, '\\"');
        } catch (err) {
          console.error(
            `The value "${row[header]}" of the user ${row.userId} has an error: ${err}`
          );
          escaped = ' '.replace(/"/g, '\\"');
        }
      }

      // PHQ_R GAD_R ELLIPSIS_SCORE ANXIETY_SCORE:
      // If the header is phq_r or gad_r we need to change the representation
      // If the header is ellipsisScore or anxietyScore we need to change the representation
      let value = '';
      if (header === 'phq_r') {
        value = row[header] ? row[header].toFixed(0) : 'Incomplete';
        escaped = `${value}`.replace(/"/g, '\\"');
      } else if (header === 'gad_r') {
        value = row[header] ? row[header].toFixed(0) : 'Incomplete';
        escaped = `${value}`.replace(/"/g, '\\"');
      } /* else if (header === 'ellipsisScore') {
        value =
          row[header] !== null && typeof row[header] !== 'undefined'
            ? row[header].toFixed(2)
            : 'not available'; 

        escaped = `${value}`.replace(/"/g, '\\"');
      } else if (header === 'anxietyScore') {
        value =
          row[header] !== null && typeof row[header] !== 'undefined'
            ? row[header].toFixed(2)
            : 'not available';

        escaped = `${value}`.replace(/"/g, '\\"');
      } */
      //----------------------------------------------

      return `"${escaped}"`;
    });

    // form escaped comma separated values
    csvRows.push(values.join(','));
  }

  // Change the columns name with user friendly ones
  for (const header of headers) {
    csvRowsHeadersName.push(headersName[header]);
  }
  csvRows[0] = csvRowsHeadersName.join(',');
  //-----------------------------------------------

  // Get the current timestamp that will be used as filename
  const date = new Date();

  const filename =
    fileName ||
    `Ellipsis_AllRecords_${moment(date).format('YYYY-MM-DD_HH:MM:SS')}_.csv`;

  // Create a blob that is needed to download data
  const blob = new Blob([csvRows.join('\n')], { type: 'text/csv' }); // csvRows.join('\n') go to next line with every row
  // Send the blob to the browser
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.setAttribute('hidden', '');
  a.setAttribute('href', url);
  a.setAttribute('download', filename);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);

  dispatch(downloadCsvCompleted());

  saveLogs({
    email: JSON.parse(sessionStorage.getItem('currentUser')).email,
    type: 'download/csv',
    success: `Success: ${data.length}`,
    text: `User downloaded a csv with these info: ${headers.toString()}`
  });
};

export default {
  getSessionJson,
  getPatientJson,
  downloadCsv
};

const saveLogs = data => {
  const saveLogsFunction = firebase
    .functions()
    .httpsCallable('dashboardFunctions-saveLogs');
  saveLogsFunction(data)
    .then(function(result) {
      if (result.data === 'success') {
        console.log(`Logs saved correctly. User: ${data.email}`);
      } else {
        console.log(
          `Something wrong happened while saving logs for the user: ${data.email}`
        );
      }
    })
    .catch(function(error) {
      console.error('Error saving logs: ', error.message);
    });
};

import React from 'react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import {
  CssBaseline,
  Toolbar,
  Typography,
  AppBar,
  IconButton,
  Link,
  List,
  Drawer,
  ListItem,
  ListItemText,
  ListItemIcon,
  Fab
} from '@material-ui/core';

import ArrowLefticon from '@material-ui/icons/ArrowBack';
import ArrowRighticon from '@material-ui/icons/ArrowForward';

import { loginOperations } from '../auth/login/redux';
import sessionImage from '../../assets/Sessions_black.svg';
import dashboardImage from '../../assets/Dashboard_black.svg';
import clinicianImage from '../../assets/Clinician_black.svg';
import patientImage from '../../assets/Patients_black.svg';
import sessionImageWhite from '../../assets/Sessions_white.svg';
import dashboardImageWhite from '../../assets/Dashboard_white.svg';
import clinicianImageWhite from '../../assets/Clinician_white.svg';
import patientImageWhite from '../../assets/Patients_white.svg';
// This component sets the auto logout timer in case the user is not active
import AutoLogout from './utils/autoLogout/autoLogout';
// ------------------------------------------------------------------------

const drawerWidth = 200;

const styles = theme => ({
  toolbarTitle: {
    flex: 1,
    marginLeft: 16,
    [theme.breakpoints.down(600)]: {
      width: 50,
      overflow: 'auto',
      overflowY: 'hidden'
    }
  },
  root: {
    display: 'flex'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: drawerWidth,
    width: `calc(100% - ${theme.spacing(7) + 1}px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${theme.spacing(9) + 1}px)`
    }
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36
  },
  hide: {
    display: 'none'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  drawerOpen: {
    backgroundColor: theme.palette.secondary.palepurple,
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    backgroundColor: theme.palette.secondary.palepurple,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1
    }
  },
  toolbarArrow: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  toolbar: {
    ...theme.mixins.toolbar
  },
  contentDrawerOpen: {
    transition: 'all .3s ease-in-out',
    [theme.breakpoints.up('sm')]: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`
    }
  },
  contentDrawerClosed: {
    transition: 'all .3s ease-in-out',
    width: `calc(100% - ${theme.spacing(9) + 1}px)`,
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(9) + 1
    }
  }
});

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true
    };
  }

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  redirectTo = destination => {
    const { history, logout } = this.props;
    switch (destination) {
      case 'dashboard':
        history.push('/home?page=0');
        break;
      case 'sessions':
        history.push('/sessions?page=1');
        break;
      case 'clinicians':
        history.push('/clinicians?page=2');
        break;
      case 'onboard':
        history.push('/onboard?page=3');
        break;
      case 'patients':
        history.push('/patients');
        break;
      case 'reports':
        history.push('/reports');
        break;
      case 'logout':
        logout();
        break;
      default:
      // code block
    }
  };

  render() {
    const {
      classes,
      theme,
      children,
      role,
      userPartnerList,
      firstName,
      partnerDisplayName,
      homeState
    } = this.props;
    const { open } = this.state;

    const selectedIndex =
      parseInt(queryString.parse(document.location.search).page, 10) || 0;
    const env = process.env.REACT_APP_ENV;

    return (
      <div>
        <AutoLogout />
        <CssBaseline />
        <AppBar
          position="fixed"
          className={classNames(classes.appBar, {
            [classes.appBarShift]: open
          })}
        >
          <Toolbar disableGutters={!open}>
            {env !== 'kittreskin' ? (
              <Fab
                disabled
                style={{ backgroundColor: '#FF0000', color: '#FFFFFF' }}
                variant="extended"
              >
                {env === 'demo'
                  ? 'demo'
                  : env === 'kittreskintest'
                  ? 'dev'
                  : 'qa'}
              </Fab>
            ) : (
              ''
            )}

            <div className={classes.toolbarTitle}>
              <Typography variant="h6" color="inherit">
                Ellipsis Health Portal:{' '}
                {userPartnerList.map(
                  partner => `${partnerDisplayName[partner]} `
                )}
              </Typography>
            </div>
            <Typography
              style={{ fontSize: 20 }}
              variant="subtitle1"
              color="inherit"
            >
              {`Hello, ${firstName}`}
            </Typography>
            <Link
              style={{
                marginLeft: 20,
                marginRight: 20,
                color: '#ffffff',
                cursor: 'pointer'
              }}
              underline="always"
              onClick={() => {
                sessionStorage.removeItem('page');
                this.redirectTo('logout');
              }}
            >
              <Typography variant="subtitle1" color="inherit">
                Logout
              </Typography>
            </Link>
          </Toolbar>
        </AppBar>

        <Drawer
          variant="permanent"
          className={classNames(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open
          })}
          classes={{
            paper: classNames({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open
            })
          }}
          open={open}
        >
          <div className={classes.toolbarArrow}>
            {open === true ? (
              <Typography
                style={{ marginLeft: 10 }}
                variant="h6"
                color="inherit"
              >
                MAIN MENU
              </Typography>
            ) : null}
            <div style={{ flex: 1 }} />
            {open === true ? (
              <IconButton onClick={this.handleDrawerClose}>
                <ArrowLefticon style={{ color: '#000000' }} />
              </IconButton>
            ) : (
              <IconButton onClick={this.handleDrawerOpen}>
                <ArrowRighticon style={{ color: '#000000' }} />
              </IconButton>
            )}
          </div>
          <List>
            <ListItem
              button
              selected={selectedIndex === 0}
              style={
                selectedIndex === 0
                  ? {
                      backgroundColor: theme.palette.primary.main,
                      color: '#ffffff'
                    }
                  : {}
              }
              disabled={homeState.showSpinner || homeState.showPSpinner}
              onClick={() => {
                this.setState({ selectedIndex: 0 });
                sessionStorage.removeItem('page');
                this.redirectTo('dashboard');
              }}
              key="Dashboard"
            >
              <ListItemIcon>
                <img
                  alt=""
                  src={
                    selectedIndex === 0 ? dashboardImageWhite : dashboardImage
                  }
                  width="30"
                  height="30"
                />
              </ListItemIcon>
              <ListItemText
                primary={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Typography variant="h6" style={{ fontSize: 16 }}>
                    DASHBOARD
                  </Typography>
                }
              />
            </ListItem>
            <ListItem
              button
              selected={selectedIndex === 1}
              style={
                selectedIndex === 1
                  ? {
                      backgroundColor: theme.palette.primary.main,
                      color: '#ffffff'
                    }
                  : {}
              }
              disabled={homeState.showSpinner || homeState.showPSpinner}
              onClick={() => {
                this.setState({ selectedIndex: 1 });
                // sessionStorage.removeItem('page');
                this.redirectTo('sessions');
              }}
              key="Sessions"
            >
              <ListItemIcon>
                <img
                  alt=""
                  src={selectedIndex === 1 ? sessionImageWhite : sessionImage}
                  width="30"
                  height="30"
                />
              </ListItemIcon>
              <ListItemText
                primary={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Typography variant="h6" style={{ fontSize: 16 }}>
                    SESSIONS
                  </Typography>
                }
              />
            </ListItem>
            {role === 'admin' || role === 'super' ? (
              <ListItem
                button
                selected={selectedIndex === 2}
                style={
                  selectedIndex === 2
                    ? {
                        backgroundColor: theme.palette.primary.main,
                        color: '#ffffff'
                      }
                    : {}
                }
                disabled={homeState.showSpinner || homeState.showPSpinner}
                onClick={() => {
                  this.setState({ selectedIndex: 2 });
                  sessionStorage.removeItem('page');
                  this.redirectTo('clinicians');
                }}
                key="Clinicians"
              >
                <ListItemIcon>
                  <img
                    alt=""
                    src={
                      selectedIndex === 2 ? clinicianImageWhite : clinicianImage
                    }
                    width="30"
                    height="30"
                  />
                </ListItemIcon>
                <ListItemText
                  primary={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <Typography variant="h6" style={{ fontSize: 16 }}>
                      CLINICIANS
                    </Typography>
                  }
                />
              </ListItem>
            ) : (
              ''
            )}
            {role !== 'viewer' ? (
              <ListItem
                button
                selected={selectedIndex === 3}
                style={
                  selectedIndex === 3
                    ? {
                        backgroundColor: theme.palette.primary.main,
                        color: '#ffffff'
                      }
                    : {}
                }
                disabled={homeState.showSpinner || homeState.showPSpinner}
                onClick={() => {
                  this.setState({ selectedIndex: 3 });
                  sessionStorage.removeItem('page');
                  this.redirectTo('onboard');
                }}
                key="Patients"
              >
                <ListItemIcon>
                  <img
                    alt=""
                    src={selectedIndex === 3 ? patientImageWhite : patientImage}
                    width="30"
                    height="30"
                  />
                </ListItemIcon>
                <ListItemText
                  primary={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <Typography variant="h6" style={{ fontSize: 16 }}>
                      PATIENTS
                    </Typography>
                  }
                />
              </ListItem>
            ) : (
              ''
            )}
          </List>

          {/* <Divider />
          <List>
            <ListItem
              button
              onClick={() => {
                sessionStorage.removeItem('page');
                this.redirectTo('logout');
              }}
              key="Logout"
            >
              <ListItemIcon>
                <PowerOffIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
          </List> */}
        </Drawer>
        <main
          className={
            open ? classes.contentDrawerOpen : classes.contentDrawerClosed
          }
        >
          <div className={classes.toolbar} />
          {children}
        </main>
      </div>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const {
    role,
    userPartnerList,
    configurations,
    firstName
  } = state.login.portalConfig;

  const { partnerDisplayName } = configurations.options;

  return {
    homeState: state.home,
    loginState: state.login,
    portalConfig: state.login.portalConfig,
    onboardState: state.onboard,
    role,
    userPartnerList,
    firstName,
    partnerDisplayName
  };
};

Header.propTypes = {
  classes: PropTypes.object,
  homeState: PropTypes.object,
  loginState: PropTypes.object,
  children: PropTypes.object,
  role: PropTypes.string,
  userPartnerList: PropTypes.array,
  firstName: PropTypes.string,
  partnerDisplayName: PropTypes.string,
  history: PropTypes.object,
  logout: PropTypes.func
};

const mapDispatchToProps = dispatch => {
  const logout = () => dispatch(loginOperations.logUserOut());

  return {
    logout
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(withRouter(Header)));

/*

This is where you define your action creators. 
All action creators must be functions that return an object 
with at least the type property. 
We do not define any async logic in this file.

*/

import types from './types.js';

const requestSessionJson = () => ({
  type: types.REQUEST_SESSION_JSON
});

const receiveSessionJson = (
  sessionCount,
  sessionData,
  newSessionsNumber,
  sort,
  sortOrder,
  limit,
  offset
) => ({
  type: types.RECEIVE_SESSION_JSON,
  sessionData,
  sessionCount,
  newSessionsNumber,
  sort,
  sortOrder,
  rowsPerPage: limit,
  offset
});

const requestPatientJson = () => ({
  type: types.REQUEST_PATIENT_JSON
});

const receivePatientJson = patientsCount => ({
  type: types.RECEIVE_PATIENT_JSON,
  patientsCount
});

const requestDownloadCsv = () => ({
  type: types.REQUEST_DOWNLOAD_CSV
});

const downloadCsvCompleted = () => ({
  type: types.DOWNLOAD_CSV_COMPLETED
});

const setFollowedby = value => ({
  type: types.SET_FOLLOWEDBY,
  followedby: value
});

export {
  requestSessionJson,
  receiveSessionJson,
  requestPatientJson,
  receivePatientJson,
  requestDownloadCsv,
  downloadCsvCompleted,
  setFollowedby
};

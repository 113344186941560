import React from 'react';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';
import queryString from 'query-string';
import {
  Paper,
  Typography,
  LinearProgress,
  Button,
  CssBaseline,
  TextField,
  Link
} from '@material-ui/core';
import logo from '../../../assets/logo.svg';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`
  },
  button: {
    width: '80%'
  },
  check: {
    width: '100%',
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  progress: {
    width: '100%',
    marginTop: 10
  },
  link: {
    textAlign: 'center',
    margin: theme.spacing(1)
  }
});

class PswResetComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      emailError: false,
      emailErrorMessage: '',
      showButton: false,
      sendMessageClicked: false,
      code: '',
      codeError: false,
      codeErrorMessage: '',
      showCodeValidationButton: false,

      /**
       * The exit params is sent in case a new account has to be created for the inclinic app.
       * - exit === true --> the user is creating a password for the inclinic app
       */
      inClinicApp_Exit: queryString.parse(window.location.search).exit
        ? queryString.parse(window.location.search).exit
        : null
    };
  }

  handleChangeEmail = event => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(String(event.target.value).toLowerCase())) {
      this.setState({
        email: event.target.value,
        emailError: false,
        emailErrorMessage: ''
        // showButton: false
      });
    } else {
      this.setState({
        email: event.target.value,
        emailError: true,
        emailErrorMessage: 'Check the email format',
        showButton: false
      });
    }

    if (event.target.value.length < 1) {
      this.setState({
        emailError: false,
        emailErrorMessage: '',
        showButton: false
      });
    }
  };

  onClickSendMessage = () => {
    const { checkPhoneNumber } = this.props;
    const { email } = this.state;

    // Call api to send a text message
    // If not, send back an error message
    this.setState({
      sendMessageClicked: true
    });
    checkPhoneNumber({ email: email.trim() });
  };

  onSuccess = value => {
    this.setState({
      showButton: true
    });
  };

  onExpired = () => {
    const { reset, spinner } = this.props;

    if (spinner === false) {
      // Delete the email and reset the reCAPTCHA reefreshing the page to force the user to retake the challenge
      this.setState({
        email: '',
        emailError: false,
        emailErrorMessage: '',
        showButton: false,
        sendMessageClicked: false
      });
      reset();
    }
  };

  resetPage = () => {
    const { classes, spinner, error, text } = this.props;
    const { email, emailError, emailErrorMessage, showButton } = this.state;

    return (
      <main className={classes.main}>
        <CssBaseline />
        <Paper className={classes.paper}>
          <img alt="" src={logo} width="60" height="60" />
          <Typography
            style={{ marginTop: 20, fontSize: '20px' }}
            component="h2"
            variant="h2"
          >
            Reset password
          </Typography>
          <Typography
            style={{ marginTop: 5, fontSize: '12px', width: '80%' }}
            align="center"
            variant="body1"
          >
            Please provide your email address so we can look up your account
          </Typography>

          <TextField
            style={{ marginTop: 30, marginBottom: 5, width: '80%' }}
            disabled={spinner}
            onChange={this.handleChangeEmail}
            value={email}
            error={emailError}
            helperText={emailErrorMessage}
            label="Email address"
            variant="outlined"
          />

          {error ? (
            <Typography
              style={{
                marginTop: 5,
                fontSize: '12px',
                width: '80%',
                color: '#ff0000'
              }}
              align="center"
              variant="body1"
            >
              {text}
            </Typography>
          ) : (
            ''
          )}

          <div className={classes.check}>
            {emailError === false && email.length > 0 ? (
              <>
                <Typography
                  style={{ marginTop: 10 }}
                  align="center"
                  variant="body1"
                >
                  <Link
                    underline="always"
                    onClick={this.handleBack}
                    color="secondary"
                  >
                    Click here if you already have a valid code
                  </Link>
                </Typography>

                <ReCAPTCHA
                  style={{ marginTop: 15 }}
                  sitekey="6Le22u4UAAAAAJw-skZta13Zoo--_BL9zwJ55zi5"
                  onChange={this.onSuccess}
                  onExpired={this.onExpired}
                />
              </>
            ) : (
              ''
            )}

            {showButton ? (
              <Button
                disabled={spinner}
                type="checkEmail"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={this.onClickSendMessage}
              >
                Message me
              </Button>
            ) : (
              ''
            )}
          </div>

          {spinner ? <LinearProgress className={classes.progress} /> : ''}
        </Paper>
      </main>
    );
  };

  handleChangeCode = event => {
    if (event.target.value.length === 8) {
      this.setState({
        code: event.target.value,
        codeError: false,
        codeErrorMessage: '',
        showCodeValidationButton: true
      });
    } else {
      this.setState({
        code: event.target.value,
        codeError: true,
        codeErrorMessage: 'The code has to be 8 characters long',
        showCodeValidationButton: false
      });
    }

    if (event.target.value.length < 1) {
      this.setState({
        codeError: false,
        codeErrorMessage: '',
        showCodeValidationButton: false
      });
    }
  };

  handleBack = () => {
    const { sendMessageClicked } = this.state;
    this.setState({
      showButton: false,
      sendMessageClicked: !sendMessageClicked,
      code: '',
      showCodeValidationButton: false
    });
  };

  onClickCheckCode = () => {
    const { validateConfirmationCode } = this.props;
    const { email, code } = this.state;
    // Call api to check if code is correct

    validateConfirmationCode({
      email: email.trim(),
      code: code.trim()
    });
  };

  insertCodePage = () => {
    const { classes, text, spinnerCode, codeerror, codetext } = this.props;
    const {
      code,
      codeError,
      codeErrorMessage,
      showCodeValidationButton
    } = this.state;

    return (
      <main className={classes.main}>
        <CssBaseline />
        <Paper className={classes.paper}>
          <img alt="" src={logo} width="60" height="60" />
          <Typography
            style={{ marginTop: 20, fontSize: '20px' }}
            component="h2"
            variant="h2"
          >
            Insert code
          </Typography>
          <Typography
            style={{ marginTop: 5, fontSize: '12px', width: '80%' }}
            align="center"
            variant="body1"
          >
            {text}
          </Typography>

          <TextField
            style={{ marginTop: 30, marginBottom: 5, width: '80%' }}
            onChange={this.handleChangeCode}
            value={code}
            error={codeError}
            helperText={codeErrorMessage}
            label="Code"
            variant="outlined"
          />

          {codeerror ? (
            <Typography
              style={{
                marginTop: 10,
                fontSize: '12px',
                width: '80%',
                color: '#ff0000'
              }}
              align="center"
              variant="body1"
            >
              {codetext}
            </Typography>
          ) : (
            ''
          )}

          <div className={classes.check}>
            {showCodeValidationButton ? (
              <Button
                disabled={spinnerCode}
                type="checkCode"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={this.onClickCheckCode}
              >
                Validate code
              </Button>
            ) : (
              ''
            )}

            {spinnerCode === false ? (
              <Typography
                style={{ marginTop: 10 }}
                align="center"
                variant="body1"
              >
                <Link
                  underline="always"
                  onClick={this.handleBack}
                  color="secondary"
                >
                  Back to reset password page
                </Link>
              </Typography>
            ) : (
              ''
            )}
          </div>

          {spinnerCode ? (
            <div className={classes.progress}>
              <LinearProgress color="secondary" />
            </div>
          ) : (
            ''
          )}
        </Paper>
      </main>
    );
  };

  completedPage = () => {
    const { classes, codetext, reset, resetLink } = this.props;
    const { inClinicApp_Exit } = this.state;

    return (
      <main className={classes.main}>
        <CssBaseline />
        <Paper className={classes.paper}>
          <img alt="" src={logo} width="60" height="60" />
          <Typography
            style={{ marginTop: 20, fontSize: '20px' }}
            component="h2"
            variant="h2"
          >
            Ready to set a new password
          </Typography>
          <Typography
            style={{
              marginTop: 10,
              marginBottom: 20,
              fontSize: '12px',
              width: '80%'
            }}
            align="center"
            variant="body1"
          >
            {codetext}
          </Typography>

          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => {
              reset();

              if (inClinicApp_Exit) {
                this.props.history.push(
                  `/__/auth/reset/setpassword?oobCode=${resetLink}&inclinicapp=true`
                );
              } else {
                this.props.history.push(
                  `/__/auth/reset/setpassword?oobCode=${resetLink}`
                );
              }
            }}
          >
            Set new password
          </Button>
        </Paper>
      </main>
    );
  };

  render() {
    const { sendMessageClicked } = this.state;
    const { error, spinner, codeValidated } = this.props;

    if (codeValidated) {
      return this.completedPage();
    }
    if (sendMessageClicked && error === false && spinner !== true) {
      return this.insertCodePage();
    }
    return this.resetPage();
  }
}

PswResetComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  spinner: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  reset: PropTypes.func.isRequired,
  checkPhoneNumber: PropTypes.func.isRequired,
  validateConfirmationCode: PropTypes.func.isRequired,
  spinnerCode: PropTypes.bool.isRequired,
  codeerror: PropTypes.bool.isRequired,
  codetext: PropTypes.string.isRequired
};

export default withStyles(styles)(PswResetComponent);

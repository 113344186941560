import { connect } from 'react-redux';
import LoginComponent from './LoginComponent';
import { loginOperations /* loginActions */ } from './redux';

const mapStateToProps = state => {
  const {
    userPartnerList,
    showLoginSpinner,
    isLoggedIn,
    error,
    snackbar
  } = state.login;

  return {
    userPartnerList,
    showLoginSpinner,
    isLoggedIn,
    error,
    snackbar
  };
};

const mapDispatchToProps = dispatch => {
  const getUserData = (user, password) =>
    dispatch(loginOperations.getUserJson(user, password));
  const registerUser = data => dispatch(loginOperations.registerUser(data));

  return { getUserData, registerUser };
};

const LoginContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginComponent);

export default LoginContainer;

import React from 'react';

import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font
} from '@react-pdf/renderer';

Font.register(`/font/Roboto-Bold.ttf`, {
  family: 'Roboto',
  weight: '800' // or [100, 200, 300]
});

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#E4E4E4'
  },
  sectionOne: {
    margin: 30,
    padding: 30,
    flexGrow: 1
  },
  sectionTwo: {
    marginTop: 40
  },
  sectionThree: {},
  header: {
    fontSize: 10
  },
  value: {
    fontSize: 10,
    marginLeft: 4,
    color: '#0277bd'
  },
  details: {
    flexDirection: 'row'
  },
  line: {
    height: 1,
    backgroundColor: '#404040'
  }
});

// Create Document Component
class AssessmentDoc extends React.Component {
  calculateData() {
    const { data, selected } = this.props;

    const sessions = [];

    for (let i = 0; i < data.length; i += 1) {
      if (selected.indexOf(data[i].uid) > -1) {
        sessions.push(data[i]);
      }
    }

    return sessions;
  }

  render() {
    const sessions = this.calculateData();

    return (
      <Document>
        {sessions.map(session => (
          <Page key={sessions.indexOf(session)} size="A4" style={styles.page}>
            <View style={styles.sectionOne}>
              <Text style={{ marginBottom: 30 }}>
                Depression & Anxiety Assessment
              </Text>

              <View style={styles.details}>
                <Text style={styles.header}>MRN: </Text>
                <Text style={styles.value}>{this.props.user.mrn}</Text>
              </View>
              <View style={styles.details}>
                <Text style={styles.header}>Year of Birth: </Text>
                <Text style={styles.value}>{this.props.user.birthyear}</Text>
              </View>
              <View style={styles.details}>
                <Text style={styles.header}>Patient: </Text>
                <Text
                  style={styles.value}
                >{`${this.props.user.name} ${this.props.user.lastName}`}</Text>
              </View>
              {/* <View style={styles.details}>
                <Text style={styles.header}>Ordered by: </Text>
                <Text style={styles.value}>
                  {this.props.clinicianDisplayName || this.props.clinician}
                </Text>
              </View> */}
              <View style={styles.details}>
                <Text style={styles.header}>Resulted on: </Text>
                <Text style={styles.value}>
                  {session.endTimeStamp !== null
                    ? `${new Date(session.startTimeStamp.value).getMonth() +
                        1}/${new Date(
                        session.startTimeStamp.value
                      ).getDate()}/${new Date(
                        session.startTimeStamp.value
                      ).getFullYear()}`
                    : ''}
                </Text>
              </View>

              <View style={styles.sectionTwo}>
                <Text style={{ fontSize: 10, margin: 8 }}>Results</Text>
                <View style={styles.line} />
              </View>

              <View style={styles.sectionThree}>
                <View
                  style={{
                    height: 30,
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'row'
                  }}
                >
                  <View
                    style={{
                      height: '100%',
                      width: '40%',
                      alignItems: 'flex-start'
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 10,
                        fontFamily: 'Roboto',
                        fontWeight: 800,
                        width: '50%',
                        textAlign: 'left',
                        margin: 8
                      }}
                    >
                      Measurement
                    </Text>
                  </View>
                  <View
                    style={{
                      height: '100%',
                      width: '30%',
                      alignItems: 'flex-end'
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 10,
                        fontFamily: 'Roboto',
                        fontWeight: 800,
                        width: '50%',
                        textAlign: 'right',
                        margin: 8
                      }}
                    >
                      Value
                    </Text>
                  </View>
                  <View
                    style={{
                      height: '100%',
                      width: '30%',
                      alignItems: 'flex-end'
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 10,
                        fontFamily: 'Roboto',
                        fontWeight: 800,
                        width: '50%',
                        textAlign: 'right',
                        margin: 8
                      }}
                    >
                      Range
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    height: 40,
                    flex: 1,
                    backgroundColor: '#D3D3D3',
                    display: 'flex',
                    flexDirection: 'row'
                  }}
                >
                  <View
                    style={{
                      height: '100%',
                      width: '40%',
                      alignItems: 'flex-start'
                    }}
                  >
                    <Text
                      style={{ fontSize: 10, textAlign: 'left', margin: 8 }}
                    >
                      Depression Symptoms Assessment*
                    </Text>
                  </View>
                  <View
                    style={{
                      height: '100%',
                      width: '30%',
                      alignItems: 'flex-end'
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 10,
                        width: '100%',
                        textAlign: 'right',
                        margin: 8,
                        color: '#0277bd'
                      }}
                    >
                      {session.phq_r !== null &&
                      typeof session.phq_r !== 'undefined'
                        ? session.phq_r < 8
                          ? `${session.phq_r.toFixed(0)}`
                          : session.phq_r < 19
                          ? `${session.phq_r.toFixed(0)}`
                          : `${session.phq_r.toFixed(0)}`
                        : 'not available'}
                    </Text>
                  </View>
                  <View
                    style={{
                      height: '100%',
                      width: '30%',
                      alignItems: 'flex-end'
                    }}
                  >
                    <Text style={{ fontSize: 10, margin: 8 }}>0 - 24</Text>
                  </View>
                </View>
                <View style={styles.line} />

                <View
                  style={{
                    height: 40,
                    flex: 1,
                    backgroundColor: '#D3D3D3',
                    display: 'flex',
                    flexDirection: 'row'
                  }}
                >
                  <View
                    style={{
                      height: '100%',
                      width: '40%',
                      alignItems: 'flex-start'
                    }}
                  >
                    <Text
                      style={{ fontSize: 10, textAlign: 'left', margin: 8 }}
                    >
                      Anxiety Symptoms Assessment*
                    </Text>
                  </View>
                  <View
                    style={{
                      height: '100%',
                      width: '30%',
                      alignItems: 'flex-end'
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 10,
                        width: '100%',
                        textAlign: 'right',
                        margin: 8,
                        color: '#0277bd'
                      }}
                    >
                      {session.gad_r !== null &&
                      typeof session.gad_r !== 'undefined'
                        ? session.gad_r < 8
                          ? `${session.gad_r.toFixed(0)}`
                          : session.gad_r < 17
                          ? `${session.gad_r.toFixed(0)}`
                          : `${session.gad_r.toFixed(0)}`
                        : 'not available'}
                    </Text>
                  </View>
                  <View
                    style={{
                      height: '100%',
                      width: '30%',
                      alignItems: 'flex-end'
                    }}
                  >
                    <Text style={{ fontSize: 10, margin: 8 }}>0 - 21</Text>
                  </View>
                </View>
                <View style={styles.line} />

                {session.phqScores && session.phqScores.length > 0 ? (
                  <>
                    <View
                      style={{
                        height: 30,
                        flex: 1,
                        backgroundColor: '#D3D3D3',
                        display: 'flex',
                        flexDirection: 'row'
                      }}
                    >
                      <View
                        style={{
                          height: '100%',
                          width: '40%',
                          alignItems: 'flex-start'
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 10,
                            width: '50%',
                            textAlign: 'left',
                            margin: 8
                          }}
                        >
                          PHQ-8
                        </Text>
                      </View>
                      <View
                        style={{
                          height: '100%',
                          width: '30%',
                          alignItems: 'flex-end'
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 10,
                            width: '100%',
                            textAlign: 'right',
                            margin: 8,
                            color: '#0277bd'
                          }}
                        >
                          {session.phqTotal !== null ||
                          typeof session.phqTotal !== 'undefined'
                            ? session.phqTotal < 8
                              ? `${session.phqTotal}`
                              : session.phqTotal < 19
                              ? `${session.phqTotal}`
                              : `${session.phqTotal}`
                            : 'not available'}
                        </Text>
                      </View>
                      <View
                        style={{
                          height: '100%',
                          width: '30%',
                          alignItems: 'flex-end'
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 10,
                            width: '50%',
                            textAlign: 'right',
                            margin: 8
                          }}
                        >
                          0 - 24
                        </Text>
                      </View>
                    </View>
                    <View style={styles.line} />
                  </>
                ) : (
                  <View />
                )}

                {session.gadScores && session.gadScores.length > 0 ? (
                  <>
                    <View
                      style={{
                        height: 30,
                        flex: 1,
                        backgroundColor: '#D3D3D3',
                        display: 'flex',
                        flexDirection: 'row'
                      }}
                    >
                      <View
                        style={{
                          height: '100%',
                          width: '40%',
                          alignItems: 'flex-start'
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 10,
                            width: '50%',
                            textAlign: 'left',
                            margin: 8
                          }}
                        >
                          GAD-7
                        </Text>
                      </View>
                      <View
                        style={{
                          height: '100%',
                          width: '30%',
                          alignItems: 'flex-end'
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 10,
                            width: '100%',
                            textAlign: 'right',
                            margin: 8,
                            color: '#0277bd'
                          }}
                        >
                          {session.gadTotal !== null ||
                          typeof session.gadTotal !== 'undefined'
                            ? session.gadTotal < 8
                              ? `${session.gadTotal}`
                              : session.gadTotal < 17
                              ? `${session.gadTotal}`
                              : `${session.gadTotal}`
                            : 'not available'}
                        </Text>
                      </View>
                      <View
                        style={{
                          height: '100%',
                          width: '30%',
                          alignItems: 'flex-end'
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 10,
                            width: '50%',
                            textAlign: 'right',
                            margin: 8
                          }}
                        >
                          0 - 21
                        </Text>
                      </View>
                    </View>
                    <View style={styles.line} />
                  </>
                ) : (
                  <View />
                )}

                <View
                  style={{
                    height: 120,
                    backgroundColor: '#D3D3D3',
                    flex: 1,
                    alignItems: 'flex-start'
                  }}
                >
                  <Text
                    style={{
                      fontSize: 10,
                      textAlign: 'left',
                      marginTop: 30,
                      marginLeft: 8,
                      marginRight: 8
                    }}
                  >
                    *The values provided are clinical support tools. Depression
                    and Anxiety Symptom Assessments are a preliminary analysis,
                    and not for diagnostic purposes. For definitive diagnosis, a
                    clinical interview must be performed. Symptom Assessments
                    are analyzed using acoustic and semantic characteristics of
                    voice, which provide insights and are suggestive of
                    depression and anxiety. For additional information, please
                    refer to 1) Multimodal Assessment of Depression from
                    Behavioral Signals (2018).
                    https://doi.org/10.1145/3107990.3108004. 2) A Cross-modal
                    Review of Indicators for Depression Detection Systems
                    (2017). https://doi.org/10.18653/v1/W17-3101. 3) Natural
                    Language Processing in Mental Health Applications Using
                    Non-clinical Texts (2017).
                    https://doi.org/10.1017/S1351324916000383.
                  </Text>
                </View>
              </View>
            </View>
          </Page>
        ))}
      </Document>
    );
  }
}

export default AssessmentDoc;

import { combineReducers } from 'redux';
import homeReducer from './app/home/redux';
import loginReducer from './app/auth/login/redux';
import resetReducer from './app/auth/reset/redux';
import managementReducer from './app/management/redux';
import patientReducer from './app/patient/redux';
import onboardReducer from './app/onboard/redux';

const appReducer = combineReducers({
  home: homeReducer,
  login: loginReducer,
  reset: resetReducer,
  management: managementReducer,
  patient: patientReducer,
  onboard: onboardReducer
});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;

import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    paddingTop: 8,
    width: '60%',
    position: 'relative',
    height: 60,
    [theme.breakpoints.down(1000)]: {
      width: '100%',
      alignItems: 'center'
    }
  },
  cont_one: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down(1000)]: {
      alignItems: 'center'
    }
  },
  cont_two: {
    width: '100%',
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    position: 'absolute',
    bottom: 0,
    left: 8,
    [theme.breakpoints.down(1000)]: {
      flexDirection: 'column',
      alignItems: 'center',
      position: 'relative',
      marginTop: 8
    }
  },
  count: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.up(1000)]: {
      width: 600
    },
    [theme.breakpoints.down(700)]: {
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  button: {
    height: '100%'
  },
  buttonCont: { backgroundColor: theme.palette.button.main, color: '#f4f4f4' },
  text: {
    width: 250,
    [theme.breakpoints.down(1000)]: {
      display: 'none'
    }
  }
});

function TopMainPage({
  classes,
  lastRefresh,
  count,
  lastSession,
  newSessionsNumber
}) {
  return (
    <div className={classes.root}>
      <div className={classes.cont_one}>
        <Typography className={classes.count} color="inherit" variant="body1">
          Total Patient Sessions:
          <div style={{ width: 5 }} />
          <Typography color="inherit" variant="subtitle2">
            {count === -1
              ? 'Loading...'
              : `${count} (${newSessionsNumber} new)`}
          </Typography>
        </Typography>
        <Typography className={classes.count} color="inherit" variant="body1">
          Most recent session:
          <div style={{ width: 5 }} />
          <Typography color="inherit" variant="subtitle2">
            {lastSession
              ? lastSession === -1
                ? `Loading...`
                : lastSession.startTimeStamp
                ? `${moment(lastSession.startTimeStamp.value).format('L LT')}`
                : `n/a`
              : ''}
          </Typography>
          <div style={{ width: 5 }} />
          <Typography color="inherit" variant="body1">
            {`(Last refreshed: ${moment(lastRefresh).format('L LT')})`}
          </Typography>
        </Typography>
      </div>
      {/* <div style={{ marginBottom: 4 }} className={classes.cont}>
        <Button
          variant="contained"
          color="secondary"
          className={classes.button}
          // onClick={() => downloadCsv()}
          target="_blank"
          href="https://airtable.com/embed/shrAOCo5ZsoG1NdWS?backgroundColor=yellowLight"
        >
          Add New Patient
        </Button> 
      </div> */}
    </div>
  );
}

TopMainPage.propTypes = {
  /* userPartnerList: PropTypes.array,
  result: PropTypes.object,
  value: PropTypes.object,
  loading: PropTypes.bool,
  onError: PropTypes.func,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func, */
  classes: PropTypes.object
};

const mapStateToProps = state => ({
  userPartnerList: get(state, 'login.portalConfig.userPartnerList', [])
});

const enhance = compose(connect(mapStateToProps), withStyles(styles));

export default enhance(TopMainPage);

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';

import {
  Menu,
  MenuItem,
  Typography,
  CircularProgress
} from '@material-ui/core';

// import firebase from '../../../config/firebase';
import { getClinicians } from './getClinicians';

const styles = theme => ({
  container: {
    flexDirection: 'row',
    minWidth: '100px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    color: theme.palette.text.main
  },
  text: {
    textDecoration: 'underline'
  }
});

function FollowedBySelect({
  classes,
  partner,
  user,
  followedby,
  onChange,
  loadingData
}) {
  const [anchor, setAnchor] = useState(null);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    let isCancelled = false;

    const loadData = async () => {
      setLoading(true);
      try {
        const resp = await getClinicians(partner);

        if (!isCancelled) {
          setItems(resp);
        }
      } catch (e) {
        // silence error
        console.error(e);
      }
      setLoading(false);
    };

    if (partner) {
      loadData();
    }

    return () => {
      isCancelled = true;
    };
  }, [partner]);

  useEffect(() => {
    let selectedItem = null;

    /**
     * In case the followedby gets changed to "All clinicians", the if is not executed.
     * In this particular case, we want selectedItem to return back to null
     */
    if (followedby) {
      selectedItem = items.find(item => item.id === followedby);
    }

    setSelected(selectedItem);
  }, [items, followedby]);

  if (loading || loadingData) {
    return <CircularProgress size={20} />;
  }

  return (
    <div>
      <div className={classes.container} onClick={e => setAnchor(e.target)}>
        <Typography variant="body1" className={selected && classes.text}>
          {followedby === user.user.email
            ? 'Me'
            : selected
            ? `${selected.firstName || ''} ${selected.lastName || ''}`
            : 'All clinicians'}
        </Typography>
        <ArrowDropDown />
      </div>
      <Menu
        id="clinician-select"
        anchorEl={anchor}
        open={Boolean(anchor)}
        onClose={() => setAnchor(null)}
      >
        <MenuItem
          key="me"
          onClick={() => {
            setAnchor(null);
            onChange(user.user.email);
          }}
          selected={followedby === user.user.email}
        >
          Me
        </MenuItem>
        <MenuItem
          key="all"
          onClick={() => {
            setAnchor(null);
            onChange(null);
          }}
          selected={!followedby}
        >
          All clinicians
        </MenuItem>
        {items
          .filter(i => i.id !== user.user.email)
          .map(item => (
            <MenuItem
              key={item.id}
              onClick={() => {
                setAnchor(null);
                onChange(item.id);
              }}
              selected={followedby === item.id}
            >
              {item.firstName} {item.lastName}
            </MenuItem>
          ))}
      </Menu>
    </div>
  );
}

FollowedBySelect.propTypes = {
  user: PropTypes.object,
  onChange: PropTypes.func
};

export default withStyles(styles)(FollowedBySelect);

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import { IconButton, Typography, Toolbar, FormControl, NativeSelect } from '@material-ui/core';

const styles = theme => ({
  root: {
    width: '100%',
    paddingRight: theme.spacing(1),
    marginBottom: 10,
    [theme.breakpoints.down(500)]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    },
  },
  firstBlock: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  secondBlock: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  text: {
    color: theme.palette.text.secondary,
  },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
    display: 'flex',
    flexDirection: 'row'
  },
  title: {
    flex: '0 0 auto',
  },
  formControl: {
    minWidth: 120,
  },
  selectEmpty: {
    width: 60,
    marginLeft: 20,
    marginRight: 20
  }
});

class TablePaginationActions extends React.Component {

  state = {
    rowsPerPageValue: this.props.rowsPerPage
  }

  handleFirstPageButtonClick = event => {

    const offset = 0;
    this.props.onChangePage(event, offset);

  };

  handleBackButtonClick = event => {

    var offset = this.props.offset - this.props.rowsPerPage;

    if (offset < this.props.rowsPerPage) {
      offset = 0
    }

    this.props.onChangePage(event, offset);
  };

  handleNextButtonClick = event => {

    var offset = this.props.offset + this.props.rowsPerPage;

    if (offset + this.props.rowsPerPage > this.props.dataCount) {
      offset = this.props.dataCount - this.props.rowsPerPage
    }

    this.props.onChangePage(event, offset);
  };

  handleLastPageButtonClick = event => {

    var offset = this.props.count - this.props.rowsPerPage

    this.props.onChangePage(
      event, offset);
  };

  handleChangeRowsPerPage = name => event => {
    this.setState({ [name]: event.target.value });
    this.props.onChangeRowsPerPage(event.target.value)
  };

  render() {
    const { classes, count, offset, rowsPerPage } = this.props;

    const limit = count - offset <= rowsPerPage ? count : offset + rowsPerPage

    return (

      <Toolbar className={classes.root}>
        <div className={classes.firstBlock}>
          <div className={classes.title}>
            <Typography className={classes.text} variant="caption">
              Rows per page:
          </Typography>
          </div>

          <FormControl className={classes.formControl}>
            <NativeSelect
              value={this.state.rowsPerPageValue}
              onChange={this.handleChangeRowsPerPage('rowsPerPageValue')}
              name="rows per page"
              className={classes.selectEmpty}
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </NativeSelect>
          </FormControl>
        </div>

        <div className={classes.secondBlock}>
          <div className={classes.title}>
            <Typography className={classes.text} variant="caption">
              {offset + 1}-{limit} of {count}
            </Typography>
          </div>

          <div className={classes.actions}>
            <IconButton
              onClick={this.handleFirstPageButtonClick}
              disabled={offset === 0}
              aria-label="First Page"
            >
              <FirstPageIcon />
            </IconButton>
            <IconButton
              onClick={this.handleBackButtonClick}
              disabled={offset === 0}
              aria-label="Previous Page"
            >
              <KeyboardArrowLeft />
            </IconButton>
            <IconButton
              onClick={this.handleNextButtonClick}
              disabled={count - offset <= rowsPerPage}
              aria-label="Next Page"
            >
              <KeyboardArrowRight />
            </IconButton>
            <IconButton
              onClick={this.handleLastPageButtonClick}
              disabled={count - offset <= rowsPerPage}
              aria-label="Last Page"
            >
              <LastPageIcon />
            </IconButton>
          </div>
        </div>
      </Toolbar >
    );
  }
}

TablePaginationActions.propTypes = {
  classes: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  offset: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(TablePaginationActions);
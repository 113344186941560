import React from 'react';
import moment from 'moment';
import { Typography, TableCell } from '@material-ui/core';
import View from '@material-ui/icons/InsertChartOutlined';
import Edit from '@material-ui/icons/Create';
import Print from '@material-ui/icons/Print';
import Unarchive from '@material-ui/icons/UnarchiveOutlined';
import { saveAs } from 'file-saver';
import { pdf } from '@react-pdf/renderer';
import AssessmentDoc from '../../reports/pdf/d&a_assessment';
import Audio from '../../audioComponent/audio';

import ClinicianSelect from '../../form/ClinicianSelect';

function tableField(id, value, props, newSession) {
  const { configurations, role } = props.config;

  /**
   * If the user has a viewer role, remove the restricted columns
   */
  if (
    role === 'viewer' &&
    configurations.user_restriction.restricted_fields_viewer.indexOf(id) > -1
  ) {
    return;
  }

  // Boolean values need to be strings
  if (value[id] === true) {
    value[id] = 'true';
  } else if (value[id] === false) {
    value[id] = 'false';
  }

  switch (id) {
    case 'name':
      return (
        <TableCell style={{ fontWeight: newSession }} align="left">
          {`${value[id]}`}
        </TableCell>
      );

    case 'endTimeStamp':
      return (
        <TableCell style={{ fontWeight: newSession }} align="left">
          {value.endTimeStamp !== null
            ? moment(value.endTimeStamp.value).format('L LT')
            : ''}
        </TableCell>
      );

    case 'startTimeStamp':
      return (
        <TableCell style={{ fontWeight: newSession }} align="left">
          {value.startTimeStamp !== null
            ? moment(value.startTimeStamp.value).format('L LT')
            : ''}
        </TableCell>
      );

    case 'createdAt':
      return (
        <TableCell style={{ fontWeight: newSession }} align="left">
          {value.createdAt
            ? moment(value.createdAt.value).format('MMM DD, YYYY')
            : ''}
        </TableCell>
      );

    case 'phqTotal':
      return (
        <TableCell
          style={
            value.phqTotal > 10 && value.phqStatus === 'COMPLETE'
              ? { color: '#be2020', fontWeight: 'bold' }
              : { fontWeight: newSession }
          }
          align="left"
        >
          {value.phqStatus === 'OFF'
            ? 'n/a'
            : value.phqStatus === 'INCOMPLETE'
            ? 'Incomplete'
            : value.phqTotal}
        </TableCell>
      );

    case 'gadTotal':
      return (
        <TableCell
          className={newSession}
          style={
            value.gadTotal > 10 && value.gadStatus === 'COMPLETE'
              ? { color: '#be2020', fontWeight: 'bold' }
              : { fontWeight: newSession }
          }
          align="left"
        >
          {value.gadStatus === 'OFF'
            ? 'n/a'
            : value.gadStatus === 'INCOMPLETE'
            ? 'Incomplete'
            : value.gadTotal}
        </TableCell>
      );

    case 'gadScores':
      return (
        <TableCell style={{ fontWeight: newSession }} align="left">
          {value.gadScores !== null ? value.gadScores.join(',') : ''}
        </TableCell>
      );

    case 'phqScores':
      return (
        <TableCell style={{ fontWeight: newSession }} align="left">
          {value.phqScores !== null ? value.phqScores.join(',') : ''}
        </TableCell>
      );

    case 'topicIds':
      return (
        <TableCell style={{ fontWeight: newSession }} align="left">
          {value.topicIds !== null ? value.topicIds.join(',') : ''}
        </TableCell>
      );

    case 'questionIds':
      return (
        <TableCell style={{ fontWeight: newSession }} align="left">
          {value.questionIds !== null ? value.questionIds.join(', ') : ''}
        </TableCell>
      );

    case 'questionDurations':
      return (
        <TableCell style={{ fontWeight: newSession }} align="left">
          {value.questionDurations !== null
            ? value.questionDurations.join(',')
            : ''}
        </TableCell>
      );

    case 'recordings':
      return (
        <TableCell style={{ fontWeight: newSession }} align="left">
          {value.recordings !== null ? value.recordings.join(', ') : ''}
        </TableCell>
      );

    case 'nextNotificationDate':
      return (
        <TableCell style={{ fontWeight: newSession }} align="left">
          {typeof value.nextNotificationDate === 'undefined' ||
          value.nextNotificationDate === null
            ? ''
            : value.nextNotificationDate}
        </TableCell>
      );

    case 'nextValidDate':
      return (
        <TableCell style={{ fontWeight: newSession }} align="left">
          {typeof value.nextValidDate === 'undefined' ||
          value.nextValidDate === null
            ? ''
            : value.nextValidDate}
        </TableCell>
      );

    case 'lastSession':
      const date = new Date(parseInt(value.lastSession) * 1000);
      return (
        <TableCell style={{ fontWeight: newSession }} align="left">
          {value.lastSession !== null ? moment(date).format('L LT') : ''}
        </TableCell>
      );

    case 'wordCounts':
      return (
        <TableCell style={{ fontWeight: newSession }} align="left">
          {value.wordCounts !== null ? value.wordCounts.join(',') : ''}
        </TableCell>
      );

    case 'ellipsisScore':
      return (
        <TableCell style={{ fontWeight: newSession }} align="left">
          {value.ellipsisScore
            ? value.ellipsisScore.toFixed(2)
            : 'not available'}
        </TableCell>
      );

    case 'followedBy':
      return (
        <TableCell align="left">
          <ClinicianSelect
            patient={value}
            onChange={d => {
              value.assignedAdminId = d.assignedAdminId;
            }}
          />
        </TableCell>
      );

    case 'anxietyScore':
      return (
        <TableCell style={{ fontWeight: newSession }} align="left">
          {value.anxietyScore ? value.anxietyScore.toFixed(2) : 'not available'}
        </TableCell>
      );

    case 'phq_r':
      return (
        <TableCell style={{ fontWeight: newSession }} align="left">
          {value.phq_r
            ? value.phq_r < 8
              ? `${value.phq_r.toFixed(0)}`
              : value.phq_r < 19
              ? `${value.phq_r.toFixed(0)}`
              : `${value.phq_r.toFixed(0)}`
            : 'Incomplete'}
        </TableCell>
      );

    case 'gad_r':
      return (
        <TableCell style={{ fontWeight: newSession }} align="left">
          {value.gad_r
            ? value.gad_r < 8
              ? `${value.gad_r.toFixed(0)}`
              : value.gad_r < 17
              ? `${value.gad_r.toFixed(0)}`
              : `${value.gad_r.toFixed(0)}`
            : 'Incomplete'}
        </TableCell>
      );

    case 'details' || 'actions':
      // In case of patients, userId exists but it is called uid
      // eslint-disable-next-line no-case-declarations
      let path = value.uid;
      if (value.userId) {
        // In case of recordings and sessions, userId exists
        path = value.userId;
      }
      return (
        <TableCell style={{ fontWeight: newSession }} align="left">
          <div style={{ width: '100%', display: 'flex' }}>
            <View
              style={{ marginRight: 20, cursor: 'pointer' }}
              title={`value: ${value.uid}`}
              onClick={() =>
                props.history.push({
                  pathname: `/patient/${path}`,
                  search: '?page=1',
                  state: {
                    from: props.history.location.pathname,
                    search: props.history.location.search
                  }
                })
              }
            />
            <Print
              style={{ marginRight: 20, cursor: 'pointer' }}
              title="print session"
              onClick={async () => {
                const blob = await pdf(
                  <AssessmentDoc
                    data={[value]}
                    selected={[value].map(n => n.uid)}
                    user={value}
                  />
                ).toBlob();
                saveAs(
                  blob,
                  `Ellipsis_${value.name}${value.lastName}_${moment(
                    value.startTimeStamp.value
                  ).format('YYYY-MM-DD')}.pdf`
                );
              }}
            />

            <Unarchive
              disabled={props.showSpinner}
              style={{ cursor: 'pointer' }}
              title="export session"
              onClick={() =>
                props.downloadCsv({
                  data: [value],
                  fileName: `Ellipsis_${value.name}${value.lastName}_${moment(
                    value.startTimeStamp.value
                  ).format('YYYY-MM-DD')}.csv`
                })
              }
            />
          </div>
        </TableCell>
      );

    case 'patientsActions':
      // In case of patients, userId exists but it is called uid
      // eslint-disable-next-line no-case-declarations
      const pathPatient = value.uid;
      if (value.userId) {
        // In case of recordings and sessions, userId exists
        path = value.userId;
      }
      return (
        <TableCell style={{ fontWeight: newSession }} align="left">
          <div style={{ width: '100%', display: 'flex' }}>
            <Edit
              style={{ marginRight: 20, cursor: 'pointer' }}
              title={`edit: ${value.uid}`}
              onClick={() => props.editPatient(value)}
            />
            {/* <Delete
              style={{ marginRight: 20, cursor: 'pointer' }}
              title={`delete: ${value.uid}`}
              onClick={() => props.deletePatient(value)}
            /> */}
            <View
              style={{ marginRight: 20, cursor: 'pointer' }}
              title={`value: ${value.uid}`}
              onClick={() =>
                props.history.push({
                  pathname: `/patient/${pathPatient}`,
                  search: '?page=3',
                  state: {
                    from: props.history.location.pathname,
                    search: props.history.location.search
                  }
                })
              }
            />
          </div>
        </TableCell>
      );

    case 'audioFile':
      return (
        <TableCell align="left">
          {value.audioFile !== null &&
          typeof value.audioFile !== 'undefined' ? (
            <Audio
              style={{ width: '50' }}
              recording={value}
              savePlayLogs={() =>
                props.saveLogs({
                  email: JSON.parse(sessionStorage.getItem('currentUser'))
                    .email,
                  type: 'play/recording',
                  success: 'Success',
                  text: `User played audio for session: ${value.sessionId} and question: ${value.question}`
                })
              }
              savePauseLogs={() =>
                props.saveLogs({
                  email: JSON.parse(sessionStorage.getItem('currentUser'))
                    .email,
                  type: 'pause/recording',
                  success: 'Success',
                  text: `User paused audio for session: ${value.sessionId} and question: ${value.question}`
                })
              }
            />
          ) : (
            ''
          )}
        </TableCell>
      );

    case 'wordsTimestamp':
      /**
       * If the field is longer than 50 characters, we add a Link to open a dialog
       */
      return (
        <TableCell style={{ fontWeight: newSession }} align="left">
          {value[id].length > 50
            ? value[id].slice(0, 50).concat(' ...')
            : value[id]}
          {value[id].length > 50 ? (
            <div onClick={() => props.cellClick(value, id)}>
              <Typography color="primary" variant="caption">
                more
              </Typography>
            </div>
          ) : (
            ''
          )}
        </TableCell>
      );

    case 'transcription':
      // If the transcription is undefined (error server side), let's set to empty string.
      if (
        typeof value.transcription === 'undefined' ||
        value.transcription === null
      ) {
        value[id] = '';
      }
      /**
       * If the transcription is longer than 50 characters, we add a Link to open a dialog
       */
      return (
        <TableCell style={{ fontWeight: newSession }} align="left">
          {value[id].length > 50
            ? value[id].slice(0, 50).concat(' ...')
            : value[id]}
          {value[id].length > 50 ? (
            <div onClick={() => props.cellClick(value, id)}>
              <Typography color={'primary'} variant={'caption'}>
                more
              </Typography>
            </div>
          ) : (
            ''
          )}
        </TableCell>
      );

    default:
      return (
        <TableCell style={{ fontWeight: newSession }} align="left">
          {value[id]}
        </TableCell>
      );
  }
}

export default function getTableField(columns, session, props, newSession) {
  const columnsOrder = [];
  for (let i = 0; i < columns.length; i += 1) {
    columnsOrder.push(tableField(columns[i].id, session, props, newSession));
  }

  return columnsOrder;
}

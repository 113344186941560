import firebase from '../../../config/firebase';
import {
  requestCreateNewUser,
  receiveNewUser,
  requestUserList,
  receiveUserList,
  requestSendInvite,
  inviteSent,
  requestEditDisable,
  DisableEdited,
  requestUserLogs,
  receiveUserLogs
} from './actions';

require('firebase/functions');

const createNewUser = data => {
  return dispatch => {
    dispatch(requestCreateNewUser());

    data.currentUser = JSON.parse(sessionStorage.getItem('currentUser')).email;
    console.log(data);

    const newUser = firebase
      .functions()
      .httpsCallable('dashboardFunctions-createNewDashboardUser');
    newUser(data)
      .then(result => {
        console.log(result);
        const message = JSON.parse(result.data).message;
        const variant = JSON.parse(result.data).success ? 'success' : 'error';

        const snackbar = {
          variant,
          message
        };

        /**
         * If the user has been created successfully, send the dashboard invite email
         */
        if (JSON.parse(result.data).success) {
          // dispatch(getUserList({ userPartnerList: data.partners }))
          // eslint-disable-next-line no-use-before-define
          dispatch(sendInviteEmail(data.email, 'createNewUser'));
        } else {
          dispatch(receiveNewUser(snackbar));
        }
      })
      .catch(error => {
        console.error(
          'Error calling the createNewDashboardUser from frontend: ',
          error.message
        );
        const snackbar = {
          variant: 'error',
          message: 'Sorry, something went wrong. Try again later.'
        };
        dispatch(receiveNewUser(snackbar));
      });
  };
};

const sendInviteEmail = (email, from) => {
  return dispatch => {
    if (from !== 'createNewUser') {
      /**
       * The function can be called in two ways.
       * 1) Directly from the UI
       * 2) After that the createNewUser has been called
       * In the first case, we have to dispatch different actions
       */
      dispatch(requestSendInvite());
    }

    // Get the email of the user who is sending the invite
    const inviteemail = firebase.auth().currentUser.email;
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser')).email;

    const sendInvite = firebase
      .functions()
      .httpsCallable('dashboardFunctions-sendKittDashboardInvite');
    sendInvite({ inviteemail, email, currentUser })
      .then(result => {
        const snackbar = {
          message:
            'An email has been sent to the User with instructions on how to access the Ellipsis Health dashboard.',
          variant: 'success'
        };

        if (from === 'createNewUser') {
          dispatch(receiveNewUser(snackbar));
        } else {
          dispatch(inviteSent(snackbar));
        }
      })
      .catch(error => {
        console.log('Error sending the dashboard invite email:', error);

        const snackbar = {
          variant: 'error',
          message: 'Sorry, something went wrong. Try again later.'
        };

        if (from === 'createNewUser') {
          dispatch(receiveNewUser(snackbar));
        } else {
          dispatch(inviteSent(snackbar));
        }
      });
  };
};

const getUserList = data => {
  return dispatch => {
    dispatch(requestUserList());

    data.currentUser = JSON.parse(sessionStorage.getItem('currentUser')).email;

    const list = firebase
      .functions()
      .httpsCallable('dashboardFunctions-dashboardUsers');
    list(data)
      .then(result => {
        const userList = JSON.parse(result.data).users;
        const variant = JSON.parse(result.data).error ? 'error' : '';
        const message = JSON.parse(result.data).message;

        const snackbar = {
          variant,
          message
        };

        dispatch(receiveUserList(userList, snackbar));
      })
      .catch(error => {
        console.error(
          'Error calling the dashboardUsers function from frontend: ',
          error.message
        );
        const snackbar = {
          variant: 'error',
          message:
            'Sorry, something went wrong loading dashboard users. Please try again later.'
        };
        dispatch(receiveUserList([], snackbar));
      });
  };
};

const editDisable = data => {
  return dispatch => {
    dispatch(requestEditDisable());

    data.currentUser = JSON.parse(sessionStorage.getItem('currentUser')).email;

    const changeUserStatus = firebase
      .functions()
      .httpsCallable('dashboardFunctions-changeUserStatus');
    changeUserStatus(data)
      .then(result => {
        const snackbar = {
          variant: JSON.parse(result.data).variant,
          message: JSON.parse(result.data).message
        };

        dispatch(DisableEdited(snackbar));
      })
      .catch(error => {
        console.error(
          'Error calling the dashboardUsers function from frontend: ',
          error.message
        );

        const snackbar = {
          variant: 'error',
          message:
            'Sorry, something went wrong processing your request. Please try again later.'
        };
        dispatch(DisableEdited(snackbar));
      });
  };
};

const deleteDashboardUser = data => {
  return dispatch => {
    dispatch(requestEditDisable());

    data.currentUser = JSON.parse(sessionStorage.getItem('currentUser')).email;

    firebase
      .firestore()
      .collection('admins')
      .doc(data.email)
      .delete()
      .then(() => {
        saveLogs({
          email: data.currentUser,
          type: 'delete/firestore',
          success: 'Success',
          text: `Deleted user ${data.email}`
        });

        const changeUserStatus = firebase
          .functions()
          .httpsCallable('dashboardFunctions-changeUserStatus');
        changeUserStatus(data)
          .then(result => {
            let snackbar = {
              variant: JSON.parse(result.data).variant,
              message: `The user ${data.email} has been correctly deleted.`
            };
            if (JSON.parse(result.data).variant === 'error') {
              snackbar = {
                variant: JSON.parse(result.data).variant,
                message:
                  'The user has been deleted correctly. However, we encountered a problem disabling the user. Please, proceed directly from the database.'
              };
            }

            dispatch(DisableEdited(snackbar));
          })
          .catch(error => {
            console.error(
              'Error calling the changeUserStatus function from frontend: ',
              error.message
            );

            const snackbar = {
              variant: 'error',
              message:
                'Sorry, something went wrong processing your request. Please try again later.'
            };
            dispatch(DisableEdited(snackbar));
          });
      })
      .catch(error => {
        console.error('Error deleting the user: ', error.message);

        saveLogs({
          email: data.currentUser,
          type: 'delete/firestore',
          success: 'Error',
          text: `Error deleting user ${data.email}`
        });

        const snackbar = {
          variant: 'error',
          message:
            'Sorry, something went wrong deleting the user. Please try again later.'
        };

        dispatch(DisableEdited(snackbar));
      });
  };
};

const getUserLogs = email => {
  return dispatch => {
    dispatch(requestUserLogs());

    firebase
      .firestore()
      .collection('admins')
      .doc(email)
      .collection('dashboardLogs')
      .get()
      .then(snapshotLogs => {
        const logs = [];
        snapshotLogs.forEach(doc => {
          const log = doc.data();
          log.id = doc.id;
          logs.push(log);
        });

        let message = '';
        if (logs.length < 1) {
          message = `No logs available for the selected user: ${email}`;
        }

        // Revert the order of the array
        logs.reverse();

        dispatch(receiveUserLogs(logs, message));
      })
      .catch(error => {
        console.log("Error getting user's logs:", error);

        dispatch(
          receiveUserLogs(
            [],
            'An error occurred while retrieving the logs. Please, try again later.'
          )
        );
      });
  };
};

export default {
  createNewUser,
  sendInviteEmail,
  getUserList,
  editDisable,
  deleteDashboardUser,
  getUserLogs
};

const saveLogs = data => {
  const saveLogsFunction = firebase
    .functions()
    .httpsCallable('dashboardFunctions-saveLogs');
  saveLogsFunction(data)
    .then(result => {
      if (result.data === 'success') {
        console.log(`Logs saved correctly. User: ${data.email}`);
      } else {
        console.log(
          `Something wrong happened while saving logs for the user: 
            ${data.email}`
        );
      }
    })
    .catch(error => {
      console.error('Error saving logs: ', error.message);
    });
};

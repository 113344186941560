import React from 'react';
import PropTypes from 'prop-types';
import DataCard from './DataCard';
import { Grid } from '@material-ui/core';

function DataList({ items }) {
  return (
    <Grid container spacing={8}>
      {items.map(item => (
        <Grid item xs={6} sm={4} key={item.title}>
          <DataCard title={item.title} value={item.value} />
        </Grid>
      ))}
    </Grid>
  );
}

DataList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      value: PropTypes.node
    })
  )
};

export default DataList;

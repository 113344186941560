// import fetch from 'cross-fetch';
import firebase from '../../../config/firebase';
import {
  requestUserDetails,
  receiveUserDetails,
  requestRecordings,
  receiveRecordings,
  requestSaveSessionInfo,
  savedSessionInfo
} from './actions';

// require("firebase/functions");

const getUserDetails = (
  userId,
  sort,
  sortOrder,
  filters,
  sortRecordings,
  sortOrderRecordings,
  recordingFilters
) => {
  return dispatch => {
    dispatch(requestUserDetails());

    const currentUser = JSON.parse(sessionStorage.getItem('currentUser')).email;

    const userDetails = firebase
      .functions()
      .httpsCallable('dashboardFunctions-bq_userData');
    userDetails({ userId, sort, sortOrder, filters, currentUser })
      .then(function(result) {
        const patientSessions = JSON.parse(result.data).sessions.map(
          session => {
            return {
              ...session,
              phq_r: session.phq_r,
              gad_r: session.gad_r
              // phq_r: session.audioDuration < 120 ? null : session.phq_r,
              // gad_r: session.audioDuration < 120 ? null : session.gad_r
            };
          }
        );
        const patient = JSON.parse(result.data).user;

        dispatch(receiveUserDetails(patient, patientSessions, sort, sortOrder));

        // Call the next function to get all the recordings
        // First, prepare a new array with all the sessionIds
        const sessionIds = [];
        for (const s in patientSessions) {
          sessionIds.push(patientSessions[s].uid);
        }
        dispatch(
          getSessionRecordings({
            sessionIds,
            sortRecordings,
            sortOrderRecordings,
            recordingFilters
          })
        );
      })
      .catch(function(error) {
        console.log('Error getting user details:', error);
        // dispatch() --> TODO dispatch error method that will give feedback to the user
      });
  };
};

const getSessionRecordings = ({
  sessionIds,
  sortRecordings,
  sortOrderRecordings,
  recordingFilters
}) => {
  return dispatch => {
    dispatch(requestRecordings());

    const currentUser = JSON.parse(sessionStorage.getItem('currentUser')).email;

    const sessionRecordings = firebase
      .functions()
      .httpsCallable('dashboardFunctions-bq_sessionRecordings');
    sessionRecordings({
      sessionIds,
      sortRecordings,
      sortOrderRecordings,
      recordingFilters,
      currentUser
    })
      .then(function(result) {
        const { recordings } = JSON.parse(result.data);

        dispatch(
          receiveRecordings(recordings, sortRecordings, sortOrderRecordings)
        );
      })
      .catch(function(error) {
        console.log('Error getting user details:', error);
        // dispatch() --> TODO dispatch error method that will give feedback to the user
      });
  };
};

const saveSessionInfo = data => {
  return dispatch => {
    dispatch(requestSaveSessionInfo());

    const sessionId = data.session.uid;
    const { sessions } = data;

    firebase
      .firestore()
      .collection('sessionStore')
      .doc(sessionId)
      .set({ comment: data.comment }, { merge: true })
      .then(function() {
        console.log('Session info successfully saved!');

        for (let i = 0; i < sessions.length; i += 1) {
          if (sessions[i].uid === data.session.uid) {
            sessions[i].comment = data.comment;
            break;
          }
        }

        dispatch(savedSessionInfo(sessions, 'Saved!'));
      })
      .catch(function(error) {
        console.error(
          'Error writing session info in document: ',
          error.message
        );

        dispatch(savedSessionInfo(sessions, 'Not saved!'));
      });
  };
};

export default {
  getUserDetails,
  getSessionRecordings,
  saveSessionInfo
};

import types from './types';

const INITIAL_STATE = {
  showSpinner: false,
  patient: {},
  patientSessions: [],
  sortUserSessions: {},
  sortOrderUserSessions: [],

  loadRecordings: false,
  recordings: [],
  sortRecordings: {},
  sortOrderRecordings: [],

  savingSessionInfo: false,
  savingText: ''
};
const detailsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.REQUEST_USER_DETAILS: {
      return {
        ...state,
        showSpinner: true,
        patientSessions: [],
        recordings: [],
        savingText: ''
      };
    }

    case types.UPDATE_USER_DETAILS: {
      return {
        ...state,
        patient: {
          ...state.patient,
          ...action.data
        }
      };
    }

    case types.RECEIVE_USER_DETAILS: {
      const { patient, patientSessions, sort, sortOrder } = action;
      return {
        ...state,
        showSpinner: false,
        patient,
        patientSessions,
        sortUserSessions: sort,
        sortOrderUserSessions: sortOrder
      };
    }

    case types.REQUEST_SESSION_RECORDINGS: {
      return {
        ...state,
        loadRecordings: true,
        recordings: []
      };
    }

    case types.RECEIVE_SESSION_RECORDINGS: {
      const { recordings, sortRecordings, sortOrderRecordings } = action;
      return {
        ...state,
        loadRecordings: false,
        recordings,
        sortRecordings,
        sortOrderRecordings
      };
    }

    case types.REQUEST_SAVE_SESSION_INFO: {
      return {
        ...state,
        savingSessionInfo: true,
        savingText: 'Saving ...'
      };
    }

    case types.SAVED_SESSION_INFO: {
      const { sessions, message } = action;

      return {
        ...state,
        savingSessionInfo: false,
        patientSessions: sessions,
        savingText: message
      };
    }

    default:
      return state;
  }
};

export default detailsReducer;

import firebase from 'firebase';

// Initialize Firebase
let config = {};
if (process.env.REACT_APP_ENV === 'production') {
  // in case of production project
  config = {
    apiKey: 'AIzaSyAwayyXuRWkeUgC8D57oPQ1OkKK5eIhZgA',
    authDomain: 'ellipsisapp-prod.firebaseapp.com',
    databaseURL: 'https://ellipsisapp-prod.firebaseio.com',
    projectId: 'ellipsisapp-prod',
    storageBucket: 'ellipsisapp-prod.appspot.com',
    messagingSenderId: '441992481208',
    appId: '1:441992481208:web:4d7d67c33e594fe6'
  };
} else if (process.env.REACT_APP_ENV === 'test') {
  // In case of test project
  config = {
    apiKey: 'AIzaSyCszDcIOsK7-xdMCgkDKq6HUkCN_TJPhgo',
    authDomain: 'ellipsisapp-test.firebaseapp.com',
    databaseURL: 'https://ellipsisapp-test.firebaseio.com',
    projectId: 'ellipsisapp-test',
    storageBucket: 'ellipsisapp-test.appspot.com',
    messagingSenderId: '296970955147'
  };
} else if (process.env.REACT_APP_ENV === 'kittreskin') {
  // In case of kittreskin project
  config = {
    apiKey: 'AIzaSyDJkiNNKVU5OG1UUMgn1oRptUtAqTHp_Dc',
    authDomain: 'ellipsis-app-kittreskin.firebaseapp.com',
    databaseURL: 'https://ellipsis-app-kittreskin.firebaseio.com',
    projectId: 'ellipsis-app-kittreskin',
    storageBucket: 'ellipsis-app-kittreskin.appspot.com',
    messagingSenderId: '951538236459'
  };
} else if (process.env.REACT_APP_ENV === 'demo') {
  // In case of kittreskin project
  config = {
    apiKey: 'AIzaSyCYvmfvp7ctWS_U9K99Tnczw7efHHXEeFQ',
    authDomain: 'rising-higher-demo.firebaseapp.com',
    databaseURL: 'https://rising-higher-demo.firebaseio.com',
    projectId: 'rising-higher-demo',
    storageBucket: 'rising-higher-demo.appspot.com',
    messagingSenderId: '986521346920',
    appId: '1:986521346920:web:02179cdc0b7c4982faecdd',
    measurementId: 'G-L14NPGY8J9'
  };
} else if (process.env.REACT_APP_ENV === 'qa') {
  // In case of kittreskin project
  config = {
    apiKey: 'AIzaSyDRMiM1_i_QhlwoR08Zez0b-cnCyr-EoP0',
    authDomain: 'rising-higher-qa.firebaseapp.com',
    databaseURL: 'https://rising-higher-qa.firebaseio.com',
    projectId: 'rising-higher-qa',
    storageBucket: 'rising-higher-qa.appspot.com',
    messagingSenderId: '922833795723',
    appId: '1:922833795723:web:ba9a98627e686665a8328e',
    measurementId: 'G-D04MGSW2H0'
  };
} else if (process.env.REACT_APP_ENV === 'kittreskintest') {
  config = {
    // In case of dev environment
    apiKey: 'AIzaSyBU5pw8Tl2WEAKyT_tG-4ZEamHYKyJ3bm4',
    authDomain: 'rising-higher-dev.firebaseapp.com',
    databaseURL: 'https://rising-higher-dev.firebaseio.com',
    projectId: 'rising-higher-dev',
    storageBucket: 'rising-higher-dev.appspot.com',
    messagingSenderId: '889370886587',
    appId: '1:889370886587:web:c80ca782e0699034e2c49e',
    measurementId: 'G-5CJSE47Z6T'
  };
}

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

firebase.auth().onAuthStateChanged(user => {
  // When a new user is logged in, save it in the sessionStorage.
  if (user) {
    sessionStorage.setItem('currentUser', JSON.stringify(user));
  }
});

// export const auth = firebase.auth();
export default firebase;
export { config };

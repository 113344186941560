import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import {
  DialogTitle,
  Button,
  DialogActions,
  DialogContent,
  CircularProgress,
  Typography
} from '@material-ui/core';
import LogsTable from './logsTable';

const styles = theme => ({
  title: {
    textAlign: 'center'
  },
  actions: {
    backgroundColor: '#ffffff',
    height: 50,
    position: 'sticky',
    width: '100%',
    bottom: 0,
    overflow: 'hidden'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  }
});

class LogsDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.props.getUserLogs(this.props.email);
  }

  render() {
    const { classes, spinner, logsMessage, logs } = this.props;
    const headers = ['Date', 'Type', 'Success', 'Text'];

    return (
      <div>
        <DialogTitle className={classes.title}>Clinician's logs</DialogTitle>
        <DialogContent>
          <div className={classes.content}>
            <Typography variant="body1">{logsMessage}</Typography>
          </div>

          {spinner ? (
            <div className={classes.content}>
              <CircularProgress />
            </div>
          ) : logs.length < 1 ? (
            ''
          ) : (
            <LogsTable headers={headers} rows={logs} />
          )}
        </DialogContent>

        <div className={classes.actions}>
          <DialogActions>
            <Button onClick={this.props.close} color="primary">
              Close
            </Button>
          </DialogActions>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(LogsDialog);

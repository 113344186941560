import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import some from 'lodash/some';
import AssessmentChart from '../../../common/chart/AssessmentChart';
import Captions from '../../../common/chart/Captions';
import { Typography } from '@material-ui/core';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: 8,
    marginTop: 20,
    marginBottom: 20,
    marginRight: 40,
    marginLeft: 40,
    borderRadius: 2,
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: '#cbcbcb',
    backgroundColor: '#f9f5fb',
    alignItems: 'center',
    [theme.breakpoints.down(850)]: {
      paddingLeft: 10,
      paddingRight: 10
    }
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%'
  },
  leftTop: {
    width: '10%',
    paddingTop: 60,
    minWidth: 100
  },
  left: {
    width: '10%',
    paddingTop: 40,
    minWidth: 100
  },
  right: {
    flex: 1,
    overflowX: 'auto'
  },
  title: {
    color: '#715FA6',
    fontWeight: 'bold'
  },
  rightTop: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 40,
    paddingRight: 40,
    maxWidth: 900
  },
  caption: {
    color: '#715FA6'
  }
});

function Middle({ classes, sessions }) {
  const [phqShow, setPhqShow] = useState(true);
  const [gadShow, setGadShow] = useState(true);
  const hasPhq = some(sessions, s => s.phqScores && s.phqScores.length);
  const hasGad = some(sessions, s => s.gadScores && s.gadScores.length);
  const phqData = sessions
    .slice(0)
    .reverse()
    .map(s => ({
      id: s.uid,
      value: s.phq_r,
      // expected: s.phq_r ? random(20) : null,
      // confidence: random(2, 5)
      expected:
        s.phqStatus === 'COMPLETE' &&
        s.phqScores &&
        s.phqScores.length &&
        phqShow
          ? s.phqTotal
          : null,
      confidence: s.ellipsisScore * 2 || 0
    }));
  const gadData = sessions
    .slice(0)
    .reverse()
    .map(s => ({
      id: s.uid,
      value: s.gad_r,
      // expected: s.gad_r ? random(20) : null,
      // confidence: random(2, 5)
      expected:
        s.gadStatus === 'COMPLETE' &&
        s.gadScores &&
        s.gadScores.length &&
        gadShow
          ? s.gadTotal
          : null,
      confidence: s.anxietyScore * 2 || 0
    }));

  return (
    <div id="middleContOne" className={classes.root}>
      <div className={classes.row}>
        <div className={classes.leftTop}>
          <Typography className={classes.title} variant="subtitle1">
            Depression Score
          </Typography>
        </div>
        <div className={classes.right}>
          <div className={classes.rightTop}>
            <Typography className={classes.title} variant="subtitle1">
              Session #
            </Typography>
            <Captions
              show={phqShow}
              onShow={setPhqShow}
              expectedCaption={hasPhq ? 'PHQ-8 Score' : null}
            />
          </div>
          <AssessmentChart
            showSessions
            showRight
            showPHQ={phqShow}
            data={phqData}
            expand24
            stops={[0, 9, 18, 24]}
          />
        </div>
      </div>

      <div className={classes.row}>
        <div className={classes.left}>
          <Typography className={classes.title} variant="subtitle1">
            Anxiety Score
          </Typography>
        </div>
        <div className={classes.right}>
          <div className={classes.rightTop}>
            <div />
            <Captions
              show={gadShow}
              onShow={setGadShow}
              expectedCaption={hasGad ? 'GAD-7 Score' : null}
            />
          </div>
          <AssessmentChart
            showPHQ={gadShow}
            showRight
            data={gadData}
            expand24
            stops={[0, 9, 15, 21]}
          />
        </div>
      </div>
    </div>
  );
}

Middle.propTypes = {
  sessions: PropTypes.array,
  classes: PropTypes.object
};

const enhance = withStyles(styles);

export default enhance(Middle);

import { connect } from 'react-redux';
import PatientComponent from './PatientComponent';
import { patientOperations, patientActions } from './redux';
import { homeOperations, homeActions } from '../home/redux';
// import { loginOperations } from '../auth/login/redux';
import { onboardOperations, onboardActions } from '../onboard/redux';

const mapStateToProps = state => {
  const {
    user,
    portalConfig
    // saveConfigCompleted,
    // snackbar
  } = state.login;
  const { download } = state.home;
  const {
    savingSessionInfo,
    loadRecordings,
    recordings,
    savingText
  } = state.patient;

  return {
    patientState: state.patient,
    user,
    portalConfig,
    /* saveConfigCompleted,
    snackbar */
    download,
    savingSessionInfo,
    savingText,
    loadRecordings,
    recordings,

    homeState: state.home,
    onboardState: state.onboard,
    loginState: state.login
  };
};

const mapDispatchToProps = dispatch => {
  const getUserDetails = (
    userId,
    sort,
    sortOrder,
    filters,
    sortRecordings,
    sortOrderRecordings,
    recordingFilters
  ) =>
    dispatch(
      patientOperations.getUserDetails(
        userId,
        sort,
        sortOrder,
        filters,
        sortRecordings,
        sortOrderRecordings,
        recordingFilters
      )
    );

  const getSessionData = (
    lastLogin,
    partnerList,
    sort,
    sortOrder,
    limit,
    offset,
    filters
  ) =>
    dispatch(
      homeOperations.getSessionJson(
        lastLogin,
        partnerList,
        sort,
        sortOrder,
        limit,
        offset,
        filters
      )
    );

  const getPatientsData = (
    partnerList,
    sort,
    sortOrder,
    limit,
    offset,
    filters
  ) =>
    dispatch(
      onboardOperations.getPatientsJson(
        partnerList,
        sort,
        sortOrder,
        limit,
        offset,
        filters
      )
    );
  /* const getSessionRecordings = data =>
  dispatch(patientOperations.getSessionRecordings(data));

  const saveConfiguration = data =>
    dispatch(loginOperations.saveConfiguration(data));
  const saveLogs = data => dispatch(loginOperations.saveLogs(data)); */
  const downloadCsv = data => dispatch(homeOperations.downloadCsv(data));
  const saveSessionInfo = data =>
    dispatch(patientOperations.saveSessionInfo(data));
  const updateUserDetails = data =>
    dispatch(patientActions.updateUserDetail(data));

  const setFollowedby = value => dispatch(homeActions.setFollowedby(value));
  const setTotalPatients = () => dispatch(onboardActions.setTotalPatients());

  return {
    getUserDetails,
    getSessionData,
    getPatientsData,
    setFollowedby,
    setTotalPatients,
    // getSessionRecordings
    /* saveConfiguration,
    saveLogs, */
    downloadCsv,
    updateUserDetails,
    saveSessionInfo
  };
};

const PatientContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PatientComponent);

export default PatientContainer;
